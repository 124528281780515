import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import ButtonField from '../../../Common/FormFields/ButtonField';
import CheckBox from '../../../Common/FormFields/CheckBox';
import DropDownField from '../../../Common/FormFields/DropDownField';
import InputField from '../../../Common/FormFields/InputField';
import UploadMultipleImage from '../../../Common/Layout/UploadMultipleImage/UploadMultipleImage';
import UploadButton from '../../../Common/UiComponents/UploadButton';
import text from '../../../Common/Languages/en_US.json'
import MultipleSelect from '../../../Common/FormFields/MultipleSelect';
import MuiDatePicker from '../../../Common/FormFields/MuiDatePicker';
import MuiTimePicker from '../../../Common/FormFields/MuiTimePicker';
import fileUploadIcon from "../../../Images/SubAdmin/fileUploadIcon.svg"
import locationImage from "../../../Images/Setting/locationImg.svg"
import texts from '../../../Common/Languages/en_US.json'
import CloseIcon from '@mui/icons-material/Close';
import pdficon from '../../../Images/CommonImages/pdficon.svg'
import { ForUploadMediaHooks } from '../../../Containers/UploadMedia/Hooks';
import { setUploadMultipleImg, setUploadedData, setUploadedLogo } from '../../../Containers/UploadMedia/reducer/UploadMediaReducer'
import { Autocomplete, GoogleMap, MarkerF, useJsApiLoader } from '@react-google-maps/api'
import { ForSignup } from '../../../Containers/Authentication/Signup/Hook';
import {governorateArray} from '../../../Utils/governorateArray'

const CreateBusinessAddressByAdmin = (props) => {

    const { cleanupMedia,
        multipleUpload,
        // documentUpload, 
        // logoUpload 
    } = ForUploadMediaHooks()
    const { getLocationData, mapLocationTxt } = ForSignup()

    const loading = useSelector((state) => state.upload.loading)

    const multipleUploadState = [...multipleUpload]

    const dispatch = useDispatch()

    const [check, setCheck] = useState(false) // state for signup checkbox
    // const [location, setLocation] = useState('')
    const [uploadDocuments, setUploadDocuments] = useState([])
    const [uploadLogoProfile, setUploadLogoProfile] = useState([])
    const [addressdata, setAddressdata] = useState({
        name: "",
        governorate: null,
        location: "",
        displayName: "",
        block: "",
        fullAddress: "",
        street: "",
        buildingNo: "",
        floor: "",
        officeNo: "",
        places: null,
        date: null,
        startTime: null,
        endTime: null,
        shiftTime: null
    })
    const [addLocation, setAddLocation] = useState()
    const [input, setInput] = useState();

    const [openMap, setOpenMap] = useState(false)
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_API_KEY}`,
        libraries: ["places"]
    })
    const center = React.useMemo(() => ({
        lat: parseFloat(sessionStorage.getItem('latitude')),
        lng: parseFloat(sessionStorage.getItem('longitude'))
    }), [])
    const [marker, setMarker] = useState({})

    const onMapClick = React.useCallback((event) => {
        getLocationData(event.latLng.lat(), event.latLng.lng())
        setMarker({
            lat: event.latLng.lat(),
            lng: event.latLng.lng(),
            address: mapLocationTxt,
        });
    }, []);

    const [map, setMap] = useState(null);
    const [autocomplete, setAutocomplete] = useState(null);

    const onLoad = (map) => {
        setMap(map);
    };

    const onAutocompleteLoad = (autocomplete) => {
        setAutocomplete(autocomplete);
    };

    const onPlaceChanged = () => {
        if (autocomplete !== null) {
            const place = autocomplete.getPlace();
            if (place.geometry) {
                map.panTo(place.geometry.location);
                map.setZoom(10);
                setMarker({
                    lat: place.geometry.location?.lat(),
                    lng: place.geometry.location?.lng(),
                    address: place?.formatted_address,
                });
            } else {
                alert('Place not found');
            }
        } else {
            alert('Autocomplete not loaded yet');
        }
    };

    const { handleSubmit, reset, control, formState: { errors } } = useForm();

    const handleError = (errors) => { console.log(errors, 'errors') };
    const onSubmit = (data) => {
        setAddressdata(data)
        props.editVendorFormData?.business && props.editVendorFormData?.business?.length > 0 && props.editVendorFormData?.business[0]
            ?
            props.editbusinessAddressDetails(data)
            :
            props.businessAddAddressByAdmin(data)

        // console.log(props.editVendorFormData.business[0]
        //     , "addressedfgvbh")
        reset(addressdata)
        cleanupMedia()
    }

    //this function is use for check the terms and condition

    const handleChecking = (e) => {
        setCheck(e.target.checked)
    }
    // const handleDropdown = (e) => {
    //     setLocation(e.target.value)
    //     setAddressdata(location)
    // }



    const noLocationFound = [
    ]

    const removeParticularThumbnail = (idx) => {
        if (idx > -1) {
            let arr;
            arr = [...multipleUpload]
            arr.splice(idx, 1);
            dispatch(setUploadMultipleImg(arr))
        }
    }


    return (
        <>

            {/* middleside start*/}


            <div className={`flex w-full px-[5%] py-[4.5%] flex-col items-center md:items-stretch`}>
                <form onSubmit={handleSubmit(onSubmit, handleError, reset)} >
                    <div className={`w-full flex justify-between`}>
                        <div className={`w-2/4`}>
                            <p className={`font-[Roboto-Medium] text-base text-left text-Grey-900`}>Almost There! </p>
                            <div className={`flex flex-col items-start justify-evenly  `}>
                                {/* Field for bussiness Name */}

                                <div className={`w-full my-3 `}>
                                    <Controller
                                        name={"name"}
                                        control={control}
                                        rules={{
                                            required: props.editVendorFormData?.business && props.editVendorFormData?.business?.length > 0 && props.editVendorFormData?.business[0] ? false : true,
                                            pattern: /^[a-zA-Z][a-zA-Z ]*/i

                                        }}
                                        render={({ field: { onChange, value } }) => (
                                            <InputField
                                                extracls={`h-11 text-sm `}
                                                placeholder={texts.enterBusinessName}
                                                textinputname="name"
                                                variant="outlined"
                                                type="text"
                                                id="name"
                                                defaultValue={(props.editVendorFormData?.business && props.editVendorFormData?.business?.length > 0 && props.editVendorFormData?.business[0]?.name ? props.editVendorFormData?.business[0]?.name : value
                                                )}
                                                onChange={onChange}
                                                value={value}
                                            />
                                        )}
                                    />
                                    <small className={`text-red-500 `}>
                                        {errors?.name && errors?.name?.type === "required" && (<p>This field is required</p>)}
                                        {errors?.name && errors?.name?.type === "pattern" && (
                                            <p>Please enter a valid Business Name</p>
                                        )}
                                    </small>
                                </div>

                                {/* section for governorate */}

                                <div className={`w-full my-3  flex flex-col`}>
                                    <Controller
                                        name={"governorate"}
                                        control={control}
                                        rules={{
                                            required: props.editVendorFormData?.business && props.editVendorFormData?.business?.length > 0 && props.editVendorFormData?.business[0] ? false : true,
                                        }}
                                        render={({ field: { onChange, value } }) => (
                                            <DropDownField
                                                name='governorate'
                                                id='governorate'
                                                dropDownRootCls={`governorateCls`}
                                                size="small"
                                                selectOption={governorateArray}
                                                placeholder={texts.enterGovernorate}
                                                defaultValue={(props.editVendorFormData?.business && props.editVendorFormData?.business?.length > 0 && props.editVendorFormData?.business[0]?.governorate ? props.editVendorFormData.business[0]?.governorate : value ? value : addLocation)}
                                                // option={value}
                                                // handleChange={onChange}
                                                option={value ? value : addLocation}
                                                handleChange={(e) => { setAddLocation(e.target.value); onChange(e.target.value) }}
                                            />
                                        )}
                                    />
                                    <small className={`text-red-500`}>
                                        {errors?.governorate && errors?.governorate?.type === "required" && (<p>This field is required</p>)}

                                    </small>

                                </div>

                                {/* section for selecting multiple places */}
                                <div className={`w-full  my-3 flex flex-col`}>
                                    <Controller
                                        name={"places"}
                                        control={control}
                                        rules={{
                                            required: props.editVendorFormData?.business && props.editVendorFormData?.business?.length > 0 && props.editVendorFormData?.business[0] ? false : true,
                                        }}
                                        render={({ field: { onChange, value } }) => (
                                            <MultipleSelect
                                                // size="small"
                                                multiple={false}
                                                name="places"
                                                id='places'
                                                defaultValue={(props.editVendorFormData?.business && props.editVendorFormData?.business?.length > 0 && props.editVendorFormData?.business[0]?.places ? props.editVendorFormData.business[0]?.places : value ? value : input)}
                                                value={value ? value : input}
                                                onChange={(e, v) => { setInput(v.name); onChange(v.name) }}
                                                label={texts.selectPlace}
                                                isOptionEqualToValue={(option, value) => { return option?.name === value?.name; }}
                                                placeholder={texts.selectPlace}
                                                autocompleteExtraCls={`selectLocationCls`}
                                                autocompleteArray={governorateArray.find((item) => item?.name === addLocation)?.location}

                                            />
                                        )}
                                    />
                                    <small className={`text-red-500`}>
                                        {errors?.places && errors?.places?.type === "required" && (<p>This field is required</p>)}

                                    </small>

                                </div>

                                {/* Field for location */}
                                <div className={`w-full my-3 `}>
                                    <Controller
                                        name={"location"}
                                        control={control}
                                        rules={{
                                            required: (props.editVendorFormData?.business && props.editVendorFormData?.business?.length > 0 && props.editVendorFormData?.business[0]) || (mapLocationTxt && mapLocationTxt?.length > 0) ? false : true,
                                            pattern: /^[ A-Za-z0-9_@./,#&+-]*$/
                                        }}
                                        render={({ field: { onChange, value } }) => (
                                            <InputField
                                                extracls={`h-11 text-sm `}
                                                placeholder={texts.enterlocation}
                                                textinputname="name"
                                                variant="outlined"
                                                type="text"
                                                id="location"
                                                defaultValue={(props.editVendorFormData?.business && props.editVendorFormData?.business?.length > 0 && props.editVendorFormData?.business[0]?.location ? props.editVendorFormData.business[0]?.location : value)}
                                                onChange={onChange}
                                                value={mapLocationTxt ? mapLocationTxt : value}
                                                endAdornment={true}
                                                inputEndAdornment={
                                                    !value && <img src={locationImage} alt="locationImage" className='cursor-pointer' onClick={() => setOpenMap(true)}/>
                                                }

                                            />
                                        )}
                                    />
                                    <small className={`text-red-500 `}>
                                        {errors?.location && errors?.location?.type === "required" && (<p>This field is required</p>)}
                                    </small>
                                </div>
                                {openMap &&
                                    <>
                                        {isLoaded &&
                                            <GoogleMap
                                                mapContainerStyle={{
                                                    height: '400px',
                                                    width: '100%',
                                                }}
                                                zoom={10}
                                                center={center}
                                                onClick={onMapClick}
                                                onLoad={onLoad}
                                            >
                                                <MarkerF
                                                    position={{ lat: marker.lat, lng: marker.lng }}
                                                />
                                                <Autocomplete
                                                    onLoad={onAutocompleteLoad}
                                                    onPlaceChanged={onPlaceChanged}
                                                >
                                                    <input
                                                        type="text"
                                                        placeholder="Enter any place to search"
                                                        style={{
                                                            boxSizing: `border-box`,
                                                            border: `1px solid transparent`,
                                                            width: `250px`,
                                                            height: `50px`,
                                                            padding: `0 12px`,
                                                            borderRadius: `3px`,
                                                            boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                                                            fontSize: `14px`,
                                                            outline: `none`,
                                                            textOverflow: `ellipses`,
                                                            position: 'absolute',
                                                            left: '50%',
                                                            top: '15%',
                                                            marginLeft: '-120px',
                                                        }}
                                                    />
                                                </Autocomplete>
                                            </GoogleMap>
                                        }
                                    </>
                                }

                                {/* Field for Full address */}
                                <div className={`w-full my-3 `}>
                                    <Controller
                                        name={"fullAddress"}
                                        control={control}
                                        rules={{
                                            required: props.editVendorFormData?.business && props.editVendorFormData?.business?.length > 0 && props.editVendorFormData?.business[0] ? false : true,
                                            pattern: /^[ A-Za-z0-9_@./,#&+-]*$/
                                        }}
                                        render={({ field: { onChange, value } }) => (
                                            <InputField
                                                extracls={`h-11 text-sm `}
                                                placeholder="*Enter Full Address"
                                                textinputname="fullAddress"
                                                variant="outlined"
                                                type="text"
                                                id="fullAddress"
                                                defaultValue={(props.editVendorFormData?.business && props.editVendorFormData?.business?.length > 0 && props.editVendorFormData?.business[0]?.address ? props.editVendorFormData.business[0]?.address : value)}
                                                onChange={onChange}
                                                value={value}
                                            />
                                        )}
                                    />
                                    <small className={`text-red-500`}>
                                        {errors?.fullAddress && errors?.fullAddress?.type === "required" && (<p>This field is required</p>)}
                                        {errors?.fullAddress && errors?.fullAddress?.type === "pattern" && (
                                            <p>Please enter a valid name</p>
                                        )}
                                    </small>
                                </div>

                                {/* Field for Street */}
                                <div className={`w-full my-3 `}>
                                    <Controller
                                        name={"street"}
                                        control={control}
                                        rules={{
                                            required: props.editVendorFormData?.business && props.editVendorFormData?.business?.length > 0 && props.editVendorFormData?.business[0] ? false : true,
                                            pattern: /^[ A-Za-z0-9_@./,#&+-]*$/
                                        }}
                                        render={({ field: { onChange, value } }) => (
                                            <InputField
                                                extracls={`h-11 text-sm `}
                                                placeholder="*Enter Street"
                                                textinputname="street"
                                                variant="outlined"
                                                type="text"
                                                id="street"
                                                defaultValue={(props.editVendorFormData?.business && props.editVendorFormData?.business?.length > 0 && props.editVendorFormData?.business[0]?.street ? props.editVendorFormData.business[0]?.street : value)}
                                                onChange={onChange}
                                                value={value}
                                            />
                                        )}
                                    />
                                    <small className={`text-red-500`}>
                                        {errors?.street && errors?.street?.type === "required" && (<p>This field is required</p>)}
                                        {errors?.street && errors?.street?.type === "pattern" && (
                                            <p>Please enter a valid name</p>
                                        )}
                                    </small>
                                </div>

                                {/* Fields for entering block and building no. */}
                                <div className={`flex w-full gap-4`}>
                                    {/* Field for entering block */}
                                    <div className={`w-full my-3`}>
                                        <Controller
                                            name={"block"}
                                            control={control}
                                            rules={{
                                                required: props.editVendorFormData?.business && props.editVendorFormData?.business?.length > 0 && props.editVendorFormData?.business[0] ? false : true,
                                                pattern: /^[ A-Za-z0-9_@./,#&+-]*$/
                                            }}
                                            render={({ field: { onChange, value } }) => (
                                                <InputField
                                                    extracls={`h-11 text-sm `}
                                                    placeholder="*Enter Block"
                                                    textinputname="block"
                                                    variant="outlined"
                                                    type="text"
                                                    id="block"
                                                    defaultValue={(props.editVendorFormData?.business && props.editVendorFormData?.business?.length > 0 && props.editVendorFormData?.business[0]?.block ? props.editVendorFormData.business[0]?.block : value)}
                                                    onChange={onChange}
                                                    value={value}
                                                />
                                            )}
                                        />
                                        <small className={`text-red-500`}>
                                            {errors?.block && errors?.block?.type === "required" && (<p>This field is required</p>)}
                                            {errors?.block && errors?.block?.type === "pattern" && (
                                                <p>Please enter a valid name</p>
                                            )}
                                        </small>
                                    </div>
                                    {/* Field for building no. */}
                                    {props?.typecheck === "business" ?
                                        <div className={`w-full my-3`}>
                                            <Controller
                                                name={"buildingNo"}
                                                control={control}
                                                rules={{
                                                    required: false,
                                                    pattern: /^[ A-Za-z0-9_@./,#&+-]*$/
                                                }}
                                                render={({ field: { onChange, value } }) => (
                                                    <InputField
                                                        extracls={`h-11 text-sm `}
                                                        placeholder="Enter Building no."
                                                        textinputname="buildingNo"
                                                        variant="outlined"
                                                        type="text"
                                                        id="buildingNo"
                                                        defaultValue={(props.editVendorFormData?.business && props.editVendorFormData?.business?.length > 0 && props.editVendorFormData?.business[0]?.buildingNo ? props.editVendorFormData.business[0]?.buildingNo : value)}
                                                        onChange={onChange}
                                                        value={value}
                                                    />
                                                )}
                                            />
                                            <small className={`text-red-500`}>
                                                {errors?.buildingNo && errors?.buildingNo?.type === "required" && (<p>This field is required</p>)}
                                                {errors?.buildingNo && errors?.buildingNo?.type === "pattern" && (
                                                    <p>Please enter a valid name</p>
                                                )}
                                            </small>
                                        </div> : null}
                                </div>

                                {/* Fields for entering floor and office no.  */}
                                {props?.typecheck === "business" ?
                                    <div className={`flex w-full gap-4`}>
                                        {/* Field for Floor */}
                                        <div className={`w-full my-3 `}>
                                            <Controller
                                                name={"floor"}
                                                control={control}
                                                rules={{
                                                    required: false,
                                                    pattern: /^[ A-Za-z0-9_@./,#&+-]*$/
                                                }}
                                                render={({ field: { onChange, value } }) => (
                                                    <InputField
                                                        extracls={`h-11 text-sm `}
                                                        placeholder="Enter Floor"
                                                        textinputname="floor"
                                                        variant="outlined"
                                                        type="text"
                                                        id="floor"
                                                        defaultValue={(props.editVendorFormData?.business && props.editVendorFormData?.business?.length > 0 && props.editVendorFormData?.business[0]?.floor ? props.editVendorFormData.business[0]?.floor : value)}
                                                        onChange={onChange}
                                                        value={value}
                                                    />
                                                )}
                                            />
                                            <small className={`text-red-500`}>
                                                {errors?.floor && errors?.floor?.type === "required" && (<p>This field is required</p>)}
                                                {errors?.floor && errors?.floor?.type === "pattern" && (
                                                    <p>Please enter a valid name</p>
                                                )}
                                            </small>
                                        </div>
                                        {/* Field for Office Number */}
                                        <div className={`w-full my-3 `}>
                                            <Controller
                                                name={"officeNo"}
                                                control={control}
                                                rules={{
                                                    required: false,
                                                    pattern: /^[ A-Za-z0-9_@./,#&+-]*$/
                                                }}
                                                render={({ field: { onChange, value } }) => (
                                                    <InputField
                                                        extracls={`h-11 text-sm `}
                                                        placeholder="Enter Office Number"
                                                        textinputname="officeNo"
                                                        variant="outlined"
                                                        type="text"
                                                        id="officeNo"
                                                        defaultValue={(props.editVendorFormData?.business && props.editVendorFormData?.business?.length > 0 && props.editVendorFormData?.business[0]?.officeNo ? props.editVendorFormData.business[0]?.officeNo : value)}
                                                        onChange={onChange}
                                                        value={value}
                                                    />
                                                )}
                                            />
                                            <small className={`text-red-500`}>
                                                {/* {errors?.officeNo && errors?.officeNo?.type === "required" && (<p>This field is required</p>)} */}
                                                {errors?.officeNo && errors?.officeNo?.type === "pattern" && (
                                                    <p>Please enter a valid name</p>
                                                )}
                                            </small>
                                        </div>
                                    </div> : null}
                                <div className={`flex items-center gap-2 my-3 w-full`}>
                                    {/* For date picker  */}
                                    <div className={`w-1/2`}>
                                        <Controller name={"date"}
                                            control={control}
                                            defaultValue={null}
                                            render={({ field: { onChange, value }, formState: { error } }) => {
                                                return (
                                                    (
                                                        <MuiDatePicker
                                                            label='Year of Establishment'
                                                            defaultCalendarMonth={(props.editVendorFormData?.business && props.editVendorFormData?.business?.length > 0 && props.editVendorFormData?.business[0]?.establishmentYear ? props.editVendorFormData.business[0]?.establishmentYear : value)}
                                                            value={value}
                                                            views={['year']}
                                                            format={'yyyy'}
                                                            onChange={(newDate) => {
                                                                onChange(newDate)
                                                            }}
                                                        />
                                                    )
                                                )
                                            }}
                                            rules={{ required: props?.typecheck === "business" ? true : false }}
                                        />
                                        {errors.date && errors.date.type === "required" && (
                                            <span className="error-message text-red-400 text-xs">{text.requiredField}</span>
                                        )}
                                    </div>

                                    <div className={`w-1/2`}>
                                        <Controller name={"shiftTime"}
                                            control={control}
                                            defaultValue={null}
                                            render={({ field: { onChange, value }, formState: { error } }) => {
                                                return (
                                                    (
                                                        <MuiTimePicker
                                                            defaultValue={(props.editVendorFormData?.business && props.editVendorFormData?.business?.length > 0 && props.editVendorFormData?.business[0]?.shiftTime ? props.editVendorFormData.business[0]?.shiftTime : value)}
                                                            label={"Business Shift Time"}
                                                            onChange={onChange}
                                                            value={value}
                                                        />
                                                    )
                                                )
                                            }}
                                            rules={{ required: props.editVendorFormData?.business && props.editVendorFormData?.business?.length > 0 && props.editVendorFormData?.business[0] ? false : true, }}
                                        />
                                        {errors?.shiftTime && errors.shiftTime?.type === "required" && (
                                            <span className="error-message text-red-400 text-xs">{text.requiredField}</span>
                                        )}
                                    </div>
                                </div>

                                {/* For time picker  */}
                                {/* {props?.typecheck === "business" ? */}
                                <div className={`flex w-full gap-4`}>
                                    <div className={`w-full my-3`}>
                                        <Controller name={"startTime"}
                                            control={control}
                                            defaultValue={null}
                                            render={({ field: { onChange, value }, formState: { error } }) => {
                                                return (
                                                    (
                                                        <MuiTimePicker
                                                            defaultValue={(props.editVendorFormData?.business && props.editVendorFormData?.business?.length > 0 && props.editVendorFormData?.business[0]?.startTime ? props.editVendorFormData.business[0]?.startTime : value)}
                                                            label={"Business Open"}
                                                            onChange={onChange}
                                                            value={value}
                                                        />
                                                    )
                                                )
                                            }}
                                            rules={{ required: props.editVendorFormData?.business && props.editVendorFormData?.business?.length > 0 && props.editVendorFormData?.business[0] ? false : true, }}
                                        />
                                        {errors.startTime && errors.startTime.type === "required" && (
                                            <span className="error-message text-red-400 text-xs">{text.requiredField}</span>
                                        )}
                                    </div>

                                    <div className={`w-full my-3`}>
                                        <Controller name={"endTime"}
                                            control={control}
                                            defaultValue={null}
                                            render={({ field: { onChange, value }, formState: { error } }) => {
                                                return (
                                                    (
                                                        <MuiTimePicker
                                                            label={"Business Close Time"}
                                                            defaultValue={(props.editVendorFormData?.business && props.editVendorFormData?.business?.length > 0 && props.editVendorFormData?.business[0]?.endTime ? props.editVendorFormData.business[0]?.endTime : value)}
                                                            onChange={onChange}
                                                            value={value}
                                                        />
                                                    )
                                                )
                                            }}
                                            rules={{ required: props.editVendorFormData?.business && props.editVendorFormData?.business?.length > 0 && props.editVendorFormData?.business[0] ? false : true, }}
                                        />
                                        {errors.endTime && errors.endTime.type === "required" && (
                                            <span className="error-message text-red-400 text-xs">{text.requiredField}</span>
                                        )}
                                    </div>
                                </div>
                                {/* : null} */}

                                {/* Field for Displaying Name */}
                                {/* <div className={`w-full my-3 `}>
                                    <Controller
                                        name={"displayName"}
                                        control={control}
                                        rules={{
                                            required: props.editVendorFormData?.business && props.editVendorFormData?.business?.length > 0 && props.editVendorFormData?.business[0] ? false : true,
                                        }}
                                        render={({ field: { onChange, value } }) => (
                                            <InputField
                                                extracls={`h-11 text-sm `}
                                                placeholder="*Enter your display name"
                                                textinputname="displayName"
                                                variant="outlined"
                                                type="text"
                                                id="displayName"
                                                defaultValue={(props.editVendorFormData?.business && props.editVendorFormData?.business?.length > 0 && props.editVendorFormData?.business[0]?.displayName ? props.editVendorFormData.business[0]?.displayName : value)}
                                                onChange={onChange}
                                                value={value}
                                            />
                                        )}
                                    />
                                    <small className={`text-red-500`}>
                                        {errors?.displayName && errors?.displayName?.type === "required" && (<p>This field is required</p>)}
                                        {errors?.displayName && errors?.displayName?.type === "pattern" && (
                                            <p>Please enter a valid name</p>
                                        )}
                                    </small>
                                </div> */}
                            </div>
                            <div className={`flex flex-col items-start justify-evenly h-[22em] `}>

                                {/* Upload file section */}
                                <div className={`flex w-full justify-between `}>
                                    <div>
                                        <p className={`text-Grey-900 font-[Roboto-Medium] font-normal text-[13px] `}>*Click here to Upload Civil ID to get verified badge</p>
                                        <p className={`text-Grey-900 font-[Roboto-Regular] font-normal text-[12px] `}>Upload license and civil ID <br />as PDF or JPG</p>
                                    </div>
                                    {
                                        <div className={`relative`}>
                                            {uploadDocuments && uploadDocuments?.length > 0 && (uploadDocuments[0]?.type === 'image/jpeg' || uploadDocuments[0]?.type === 'application/pdf') &&
                                                <div className={`absolute -top-3 left-20 -right-4 cursor-pointer`}>
                                                    <CloseIcon
                                                        sx={{ fontSize: "12px", border: '1px solid red', borderRadius: '9999px' }}
                                                        onClick={() => {setUploadDocuments([]); dispatch(setUploadedData([]))}}
                                                    />
                                                </div>
                                            }
                                            <UploadButton
                                                sendData={(data) => {
                                                    props.doUploadedMedia(data)
                                                    setUploadDocuments(data);
                                                }}
                                                extracls='flex justify-center items-center bg-white items-center rounded-md'
                                                extratextcls='text-xs w-16'
                                                type='button'
                                                fileUploadIcon={
                                                    uploadDocuments && uploadDocuments?.length > 0 && uploadDocuments[0]?.type === 'image/jpeg' ?
                                                        window.URL.createObjectURL(uploadDocuments[0]) :
                                                        uploadDocuments[0]?.type === 'application/pdf' ?
                                                            pdficon :
                                                            fileUploadIcon
                                                }
                                                accept={'image/jpeg, application/pdf'}
                                                multiple={false}
                                                disabled={uploadDocuments && uploadDocuments?.length === 1 && (uploadDocuments[0]?.type === 'image/jpeg' || uploadDocuments[0]?.type === 'application/pdf') ? true : false}
                                                fileName={uploadDocuments[0]?.type === 'application/pdf' ? uploadDocuments[0]?.name : null}
                                                fileSize={uploadDocuments[0]?.type === 'application/pdf' ? uploadDocuments[0]?.size : null}
                                            />
                                        </div>
                                    }
                                </div>

                                {/* For Logo  */}
                                <div className={`flex w-full justify-between`}>
                                    <div>
                                        <p className={`text-Grey-900 font-[Roboto-Medium] font-normal text-[13px] `}>*Upload Logo</p>
                                        <p className={`text-Grey-900 font-[Roboto-Regular] font-normal text-[12px] `}>Drag and drop or <span className={`text-skyblue-6 underline`}>browse</span> <br />your files(png, jpg)</p>
                                    </div>
                                    {
                                        <div className={`relative`}>
                                            {uploadLogoProfile && uploadLogoProfile?.length > 0 && (uploadLogoProfile[0]?.type === 'image/jpeg' || uploadLogoProfile[0]?.type === 'image/png') &&
                                                <div className={`absolute -top-3 left-20 -right-4 cursor-pointer`}>
                                                    <CloseIcon
                                                        sx={{ fontSize: "12px", border: '1px solid red', borderRadius: '9999px' }}
                                                        onClick={() => {setUploadLogoProfile([]); dispatch(setUploadedLogo([]))}}
                                                    />
                                                </div>
                                            }
                                            <UploadButton
                                                sendData={(data) => {
                                                    props.doUploadedLogo(data)
                                                    setUploadLogoProfile(data)
                                                }}
                                                extracls='flex justify-center items-center bg-white  items-center rounded-md '
                                                type='button'
                                                fileUploadIcon={
                                                    uploadLogoProfile && uploadLogoProfile?.length > 0 &&
                                                        (uploadLogoProfile[0]?.type === 'image/jpeg' || uploadLogoProfile[0]?.type === 'image/png') ?
                                                        window.URL.createObjectURL(uploadLogoProfile[0]) :
                                                        fileUploadIcon
                                                }
                                                accept={'image/jpeg, image/svg, image/png'}
                                                multiple={false}
                                                disabled={uploadLogoProfile && uploadLogoProfile?.length === 1 && (uploadLogoProfile[0]?.type === 'image/jpeg' || uploadLogoProfile[0]?.type === 'image/png') ? true : false}
                                            />
                                        </div>
                                    }
                                </div>

                                {/* Checkbox */}
                                <div className={`flex items-center`}>
                                    <CheckBox checked={check ? true : false} handleBoxChange={(e) => handleChecking(e)}
                                    />
                                    <p onClick={() => setCheck(!check)} className={`text-Grey-900 font-[Roboto-Regular] font-normal text-sm pl-2 cursor-pointer`}> Subscribe Daily Newsletter
                                    </p>
                                </div>

                                {/* continue button */}
                                <div className='flex'>
                                    <ButtonField
                                        buttonextracls={`${(Object.keys(errors) && Object.keys(errors).length > 0) || loading === true ? `bg-gray-400` : `bg-skyblue-6`} border-[1.5px] border-solid border-deepgray-0.5  w-32 h-12 mr-5`}
                                        buttonName="Continue >"
                                        buttonnamecls={`font-[Roboto-Medium] text-white text-sm`}
                                        type="submit"
                                        onClick={handleSubmit(onSubmit)}
                                        disabled={(Object.keys(errors) && Object.keys(errors).length > 0) || loading === true ? true : false}
                                    />
                                    <ButtonField
                                        buttonextracls={`cursor-pointer  border-[1.5px] border-solid border-deepgray-0.5 capitalize w-24 ml-5  h-12`}
                                        buttonName="Cancel"
                                        buttonnamecls={`font-[Roboto-Medium] text-deepgray-0.5 font-medium text-sm`}
                                        type="submit"
                                        onClick={props.cancelAddressclick}
                                    />
                                </div>

                            </div>
                        </div>
                        <div className={`w-[37%] pt-2 flex flex-col gap-6 justify-start items-center`}>
                            <UploadMultipleImage
                                sendData={(data) => {
                                    if (data[0]?.type === 'image/png' || data[0]?.type === 'image/jpeg') {
                                        props.multipleMediaApiCall(data);
                                    }
                                }}
                                disabled={multipleUploadState && multipleUploadState?.length === 4 ? true : false}
                                text1="selectFile"
                                text2="dragAndDropItHereText"
                                text3={loading === true ? 'uploading' : "uploadImg"}
                                multiple={true}
                                fileLimit={3}
                            />
                            <div className={`flex gap-4 pt-2 w-full relative`}>
                                {multipleUploadState && (multipleUploadState?.length > 0 && multipleUploadState?.length <= 4) ?
                                    multipleUploadState?.map((multipleImg, idx) =>
                                        <div key={idx} className={`flex w-fit justify-between`}>
                                            <img
                                                // src={window.URL.createObjectURL(multipleImg)}
                                                crossOrigin="anonymous"
                                                src={multipleImg?.key && `${process.env.REACT_APP_DG_BASE_URL}/uploads/download/file?key=${multipleImg?.key}&height=75&width=100`}
                                                alt='thumbnail'
                                            />
                                            <div className={`cursor-pointer `}>
                                                <CloseIcon
                                                    onClick={() => removeParticularThumbnail(idx)}
                                                    sx={{ fontSize: "12px", position: 'absolute', top: '0px', border: '1px solid red', borderRadius: '9999px' }}
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        <div className={`flex w-full justify-between`}>
                                            <img src={fileUploadIcon} alt='file upload icon' />
                                            <img src={fileUploadIcon} alt='file upload icon' />
                                            <img src={fileUploadIcon} alt='file upload icon' />
                                            <img src={fileUploadIcon} alt='file upload icon' />
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </form>

            </div>

            {/* middleside end*/}

        </>
    )
}

export default CreateBusinessAddressByAdmin