import { Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ButtonField from '../../../Common/FormFields/ButtonField'
import circleicon from '../../../Images/accountcreate/circle.svg'
import bluetickicon from '../../../Images/accountcreate/Bluetick.svg'
import purpletick from '../../../Images/accountcreate/purpletick.svg'
import texts from '../../../Common/Languages/en_US.json'
import { ForSignup } from '../../../Containers/Authentication/Signup/Hook';
import { useSelector } from 'react-redux'
import { useSearchParams, useLocation } from 'react-router-dom'
import MainHeader from '../../../Common/Layout/MainHheader/MainHeader'
import StepperLayout from '../../../Common/Layout/StepperLayout/StepperLayout'
import _ from "lodash";
import SnackBarComponent from '../../../Common/Layout/Snackbar/SnackBar'
const SubscriptionPlan = (props) => {
    let subscriptionInfo = JSON.parse(localStorage.getItem("subscriptionInfo"));
    const [searchParams] = useSearchParams();
    const location = useLocation();
    const { pathname } = location;
    const subscriptionPlanData = useSelector((state) => state.subscription.plans);

    let userName = searchParams.get('name') || subscriptionInfo?.name || localStorage.getItem("name");
    let address = searchParams.get('address') || subscriptionInfo?.address || localStorage.getItem("address");
    let userId = searchParams.get('userId') || subscriptionInfo?.userId || localStorage.getItem("userId");
    let planType = searchParams.get('planType') || ""


    const { getSubscriptionPlans, loading, getSubscriptionToken, notificationSignup, openMessageSignup, messageClose } = ForSignup()
    const [checkedId, setCheckedId] = useState(null);
    const [selectPlan, setSelectedPlan] = useState(null);
    const [show, setShow] = useState("signup")


    useEffect(() => {
        getSubscriptionPlans()
        if (props.page !== undefined) {
            setShow(props.page)
        }
    }, []);

    const handleSelectPlan = (data, idx) => {
        if (checkedId === idx) { //For unchecked same plan
            setSelectedPlan(null)
            setCheckedId(null);
        } else {
            setSelectedPlan(data);
            setCheckedId(idx);
        }
    }

    const handleSubmit = async () => {
        let token = await getSubscriptionToken(userId, 'subscription');

        if (userName && address && userId && selectPlan && token) {
            window.location.href = `${process.env.REACT_APP_PAYMENT_URL}/details.php?name=${userName}&address=${address}&UserId=${userId}&planId=${selectPlan?.id}&type=${selectPlan?.planType}&token=${token}`;
        }
        else {
            openMessageSignup("error", "Error", "Something went wrong", "error");
        }
    }

    return (
        <>
            {props.page === "verdorSubscription" || "verdorSubscriptionAddons" ? null :
                <div className={`sticky top-0 z-10`}>
                    <MainHeader />
                </div>
            }
            <div className={`flex flex-row ${props.page === "verdorSubscription" || "verdorSubscriptionAddons" ? "h-full" : "h-[57rem]"} w-full`}>
                {!pathname?.includes("signup") && props.page === "verdorSubscription" || "verdorSubscriptionAddons" ? null :
                    <StepperLayout
                        doneApproval={true} />}
                <>
                    <Grid container spacing={5}>
                        <Grid item xs={12} md={12}>
                            <p className={`w-full h-[10%] mb-5 flex justify-center items-center text-[2rem] text-Grey-900 font-[Roboto-Bold] leading-[2.375rem]`}>{props.page === "verdorSubscriptionAddons" ? "Subscription AddOns Plans" : texts.subscriptionPlans}</p>
                            <div className={`px-12 pb-10 flex flex-col lg:flex-row md:flex-col justify-center gap-8 items-center h-fit`}>
                                {loading && <div>Loading ....</div>}
                                {subscriptionPlanData && subscriptionPlanData.length > 0 ?
                                    subscriptionPlanData.filter(item => (props.page === "verdorSubscriptionAddons" || planType === "addOns") ? item.planType === "addOns" : item.planType === 'general').sort((a, b) => a.price - b.price).map((item, idx) => {
                                        return (
                                            <div id={idx} className={`border border-Grey-200 rounded-2xl pt-[2%] w-[80%] lg:w-[33%] md:w-[66%]`} onClick={() => handleSelectPlan(item, idx)} >
                                                <div className={`flex justify-between p-[8%]`}
                                                    onClick={() => handleSelectPlan(item, idx)}
                                                // onClick={handleclickforselectBasic}
                                                >
                                                    <p className={`pl-[2%] text-xl font-[Roboto-Medium] text-deepgray-4`}>{item?.name}</p>
                                                    <img src={checkedId === idx ? bluetickicon : circleicon} alt="icon" className={``} />
                                                </div>
                                                <p className={`text-Gray-900 text-2xl font-[Poppins-Medium] px-[8%] pb-[4%]`}>{item?.price}<span>  </span><span className={`text-lg`}>{item?.currency}</span></p>
                                                {item.planType !== "addOns" ?
                                                    <>
                                                        <div className={`flex pb-[4%] px-[8%]`}>
                                                            <img src={purpletick} alt="purpletick" />
                                                            <p className={`text-primerycolor-0.5 font-[Roboto-Regular] text-base pl-[4%] `}>Can upload upto {item?.photoLimit} photos</p>
                                                        </div>
                                                        <div className={`flex pb-[4%] px-[8%]`}>
                                                            <img src={purpletick} alt="purpletick" />
                                                            <p className={`text-primerycolor-0.5 font-[Roboto-Regular] text-base pl-[4%] `}>Can upload upto {item?.videoLimit} videos</p>
                                                        </div>
                                                        <div className={`flex pb-[4%] px-[8%]`}>
                                                            <img src={purpletick} alt="purpletick" />
                                                            <p className={`text-primerycolor-0.5 font-[Roboto-Regular] text-base pl-[4%] `}>Video duration : {item?.videoDuration !== "NA" ? item?.videoDuration + " sec." : " N/A"}</p>
                                                        </div>
                                                    </>
                                                    : null}
                                                <div className={`flex pb-[4%] px-[8%]`}>
                                                    <img src={purpletick} alt="purpletick" />
                                                    <p className={`text-primerycolor-0.5 font-[Roboto-Regular] text-base pl-[4%] `}>Validity : {item.planType === "addOns" ? "Based on subscription plan" : item?.durationInDays} Days</p>
                                                </div>
                                                {item.planType !== "addOns" ?
                                                    <div className={`flex pb-[4%] px-[8%]`}>
                                                        <img src={purpletick} alt="purpletick" />
                                                        <p className={`text-primerycolor-0.5 font-[Roboto-Regular] text-base pl-[4%] `}>Free duration : {item?.freeDuration} Days</p>
                                                    </div>
                                                    : null}
                                                <div className={`flex pb-[4%] px-[8%]`}>
                                                    <img src={purpletick} alt="purpletick" />
                                                    <p className={`text-primerycolor-0.5 font-[Roboto-Regular] text-base pl-[4%] `}>Service post limit : {item?.serviceOffered?.count}</p>
                                                </div>
                                                {item.planType !== "addOns" ?
                                                    <div className={`flex pb-[4%] px-[8%]`}>
                                                        <img src={purpletick} alt="purpletick" />
                                                        <p className={`text-primerycolor-0.5 font-[Roboto-Regular] text-base pl-[4%] `}>Service photo limit : {item?.serviceOffered?.photoLimit} each post.</p>
                                                    </div>
                                                    : null}
                                                <div className={`flex pb-[4%] px-[8%]`}>
                                                    <img src={purpletick} alt="purpletick" />
                                                    <p className={`text-primerycolor-0.5 font-[Roboto-Regular] text-base pl-[4%] `}>Requirement post limit : {item?.monthlyPostRequirements?.count} /Month</p>
                                                </div>
                                                {item.planType !== "addOns" ?
                                                    <div className={`flex pb-[4%] px-[8%]`}>
                                                        <img src={purpletick} alt="purpletick" />
                                                        <p className={`text-primerycolor-0.5 font-[Roboto-Regular] text-base pl-[4%] `}>Requirement photo limit : {item?.monthlyPostRequirements?.photoLimit} each post.</p>
                                                    </div>
                                                    : null}
                                                <div className={`flex pb-[4%] px-[8%]`}>
                                                    <img src={purpletick} alt="purpletick" />
                                                    <p className={`text-primerycolor-0.5 font-[Roboto-Regular] text-base pl-[4%] `}>Offer post limit : {item?.monthlyPostOffers?.count} /Month</p>
                                                </div>
                                                {
                                                    item.planType !== "addOns" ?
                                                        <div className={`flex pb-[4%] px-[8%]`}>
                                                            <img src={purpletick} alt="purpletick" />
                                                            <p className={`text-primerycolor-0.5 font-[Roboto-Regular] text-base pl-[4%] `}>Offer photo limit : {item?.monthlyPostOffers?.photoLimit} each post.</p>
                                                        </div>
                                                        : null
                                                }
                                                < ButtonField
                                                    buttonextracls={` border-[1.5px] border-solid border-deepgray-0.5 capitalize w-full rounded-b-2xl h-14 ${checkedId === idx ? "bg-skyblue-6" : "bg-skyblue-2 "} `}
                                                    buttonName="Proceed"
                                                    buttonnamecls={`font-[Roboto-Bold] text-white font-medium text-base`}
                                                    type="submit"
                                                    disabled={checkedId !== idx && true}
                                                    onClick={handleSubmit}
                                                />
                                            </div>
                                        )
                                    }) :
                                    <div className={`text-center text-lg`}>No subscription plan found</div>
                                }
                            </div>
                        </Grid>

                    </Grid>
                </>
                {/* } */}
                <SnackBarComponent
                    messageOpen={notificationSignup.open}
                    messageClose={messageClose}
                    notificationText={notificationSignup.message}
                    subText={notificationSignup.subText}
                    alertType={notificationSignup.alertType}
                    borderClass={notificationSignup.borderClass}
                />

            </div>

        </>

    )
}

export default SubscriptionPlan