import React, { useEffect, useState } from 'react'
import profileIcon from '../../../Images/Vendor/DefaultProfileicon.svg'
import crown from '../../../Images/profileUserCardImages/crown.png'
import badge from '../../../Images/profileUserCardImages/badge.png'
import location from '../../../Images/profileUserCardImages/location.png'
import RatingField from '../../UiComponents/Rating'
import ButtonField from '../../FormFields/ButtonField'
import Tooltip from '@mui/material/Tooltip';
import text from '../../Languages/en_US.json'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types';
import { ForAdmin } from '../../../Containers/Admin/Hooks'
import { useLocation } from 'react-router-dom'
import SnackBarComponent from '../Snackbar/SnackBar'


const ProfileUserInfoCard = (props) => {
  const { giveBadgetoUser, adminSnackbar, messageClose } = ForAdmin()

  const locatn = useLocation()

  const { t } = useTranslation()
  const [badgeEnabled, setBadgeEnabled] = useState({
    state: false,
    id: ''
  })

  const approveBadge = (userId) => {
    giveBadgetoUser(userId)
    setBadgeEnabled({
      state: true,
      id: userId
    })
  }
  return (
    <div className={` p-5 border !bg-white`}>

      <div className={``}>
        {/* Extreme top  */}
        <div className={`flex justify-between`}>
          {
            props.dataObject?.subscriptionId ?
              <img src={crown} alt="crown" className={`cursor-pointer w-4 h-3.5`} />
              :
              <div></div>
          } 
          {/* {
            props.dataObject?.isOnline ?
              <div className={`text-green-600 border border-green-600 p-1 w-16 rounded-full font-[Roboto-Medium] text-xs text-center`}>{text.online}</div>
              :
              <div className={`text-red-600 border border-red-600 p-1 w-16 rounded-full font-[Roboto-Medium] text-xs text-center`}>{text.offline}</div>
          } */}
        </div>

        {/* Name, Location and Rating  */}
        <div className={`flex flex-col items-center gap-y-4`}>
          {/* profile img  */}
          {props.forUser === 'forUser' ? 
           <img crossOrigin='anonymous' src={ props.dataObject?.picture?.key ? `${process.env.REACT_APP_DG_BASE_URL}/uploads/download/file?key=${props.dataObject?.picture?.key}&height=60&width=60` : profileIcon} alt="profileIcon" className={` w-20 h-20 ${props.dataObject?.picture?.key !== null && 'rounded-full border'}`} /> 
           : 
           <img crossOrigin='anonymous' src={ props.dataObject?.logo?.key ? `${process.env.REACT_APP_DG_BASE_URL}/uploads/download/file?key=${props.dataObject?.logo?.key}&height=60&width=60` : profileIcon} alt="profileIcon" className={` w-20 h-20 ${props.dataObject?.logo?.key !== null && 'rounded-full border'}`} />
          }

          {/* name  */}
          <div className={`flex items-center gap-2`}>
            <p className={`font-[Roboto-Medium] text-deepgray-5`}>{props.dataObject?.name}</p>
            {(props.dataObject?.user?.batch === true || props.dataObject?.batch === true) && <img src={badge} alt="badge" className={``} />}
          </div>

          {/* location  */}
          <div className={`flex items-center gap-2`}>
            <img src={location} alt="location" className={``} />
            <p className={`text-grey-600 font-[Roboto-Regular] text-sm`}>

              {props.dataObject?.location !== null ? props.dataObject?.location : "--" }
              {/* {props.dataObject?.user?.location === null ?
                "--"
                :
                props.dataObject?.user?.location
              } */}
            </p>
          </div>
          <RatingField
            SendRating={true}
            value={props.dataObject?.avgRating}
            size="small"
            readOnly={true}
          />

          {/* For approving badge  */}
          {(locatn.pathname === `/admin/totalVendors/${props.dataObject?.userId}` || locatn.pathname === `/admin/totalUsers/${props.dataObject?.id}`) && (props.dataObject?.user?.batch === false || props.dataObject?.batch === false) &&
            <div
              className={`${(badgeEnabled?.state === true && badgeEnabled?.id === props.dataObject?.userId) ? 'hidden' : 'cursor-pointer text-xs bg-skyblue-5 p-2 text-white rounded'} `}
              onClick={() => { props.dataObject?.type === 'user' ? approveBadge(props.dataObject?.id) : approveBadge(props.dataObject?.userId)}}>
              Approve badge
            </div>
          }

          {/* edit profile  */}
          {(locatn.pathname !== `/admin/totalVendors/${props.dataObject?.userId}` || locatn.pathname !== `/admin/totalUsers/${props.dataObject?.id}`) &&
            <div className={`w-full`}>
              <ButtonField
                buttonextracls={`bg-seconderyColour-5 w-full rounded-full`}
                buttonnamecls={` text-white text-sm py-[0.2rem] font-[Roboto-Medium] capitalize`}
                buttonName="editprofile"
                onClick={() => props.toggleEditProfile()}
              />
            </div>
          }

        </div>

      </div>

      {/* Border  */}
      <div className={` border-b border-[#B9C1CE]/50 w-full my-4`}></div>

      {/* Buttons that will be visible only for the admins  */}
      {/* {locatn.pathname === `/admin/totalVendors/${props.dataObject?.userId}` &&
        <div className={`w-full flex justify-center gap-6`}>
          <ButtonField
            buttonextracls={`w-full text-sm bg-skyblue-5 py-2 px-4 text-white text-center rounded-full capitalize`}
            buttonName="Contact Vendor"
          />
          <ButtonField
            buttonextracls={`w-full text-sm text-skyblue-5 py-2 px-4 border border-skyblue-5 text-center rounded-full capitalize`}
            buttonName="Track"
            variant='outlined'
          />
        </div>
      } */}

      {/* Details  */}
      <div>
        <div className={` flex my-4 gap-6`}>
          <p className={`text-deepgray-4 font-[Roboto-Medium] w-1/2 text-xs`}>{props.dataObject?.type === 'user' ? t("userName") : t("vendorname")}</p>
          <p className={`text-[#5B5B5B] font-[Roboto-Regular] w-1/2 text-xs`}>
            {props.dataObject?.type === 'user' ? props.dataObject?.name : props.dataObject?.user?.name}
          </p>
        </div>
        {props.dataObject?.type !== 'user' && 
        <>
        <div className={` flex my-4 gap-6`}>
          <p className={`text-deepgray-4 font-[Roboto-Medium] w-1/2 text-xs`}>{t("businessname")}</p>
          <p className={`text-[#5B5B5B] font-[Roboto-Regular] w-1/2 text-xs`}>
            {props.dataObject?.name}
          </p>
        </div>
        <div className={` flex my-4 gap-6`}>
          <p className={`text-deepgray-4 font-[Roboto-Medium] w-1/2 text-xs`}>{t("establishmentYr")}</p>
          <p className={`text-[#5B5B5B] font-[Roboto-Regular] w-1/2 text-xs`}>
            {props.dataObject?.establishmentYear}
          </p>
        </div>
        <div className={` flex my-4 gap-6`}>
          <p className={`text-deepgray-4 font-[Roboto-Medium] w-1/2 text-xs`}>{t("businessTimimg")}</p>
          <p className={`text-[#5B5B5B] font-[Roboto-Regular] w-1/2 text-xs`}>
            {props.dataObject?.startTime} to {props.dataObject?.endTime}
          </p>
        </div>
        <div className={` flex my-4 gap-6`}>
          <p className={`text-deepgray-4 font-[Roboto-Medium] w-1/2 text-xs`}>{t("vendorcategory")}</p>
          <p className={`text-[#5B5B5B] font-[Roboto-Regular] w-1/2 text-xs capitalize`}>
            {props.dataObject?.user?.type}
          </p>
        </div>
        <div className={` flex my-4 gap-6`}>
          <p className={`text-deepgray-4 font-[Roboto-Medium] w-1/2 text-xs`}>{t("civilid")}</p>
          <p className={`text-[#5B5B5B] font-[Roboto-Regular] w-1/2 text-xs`}>
            {props.dataObject?.civilDocumentId === null ? "--" : props.dataObject?.civilDocumentId}
          </p>
        </div>
        </>
}
        <div className={` flex my-4 gap-6`}>
          <p className={`text-deepgray-4 font-[Roboto-Medium] w-1/2 text-xs`}>{t("subscription")}</p>
          <p className={`text-[#5B5B5B] font-[Roboto-Regular] w-1/2 text-xs`}>--</p>
        </div>
        <div className={` flex my-4 gap-6`}>
          <p className={`text-deepgray-4 font-[Roboto-Medium] w-1/2 text-xs`}>{t("verification")}</p>
          <p className={`text-[#5B5B5B] font-[Roboto-Regular] w-1/2 text-xs`}>
            {(props.dataObject?.user?.isVerified === 1 || props.dataObject?.isVerified === 1 ) ? "Active" : "Pending"}
          </p>
        </div>
        <div className={` flex my-4 gap-6`}>
          <p className={`text-deepgray-4 font-[Roboto-Medium] w-1/2 text-xs`}>{t("totalViews")} </p>
          <p className={`text-[#5B5B5B] font-[Roboto-Regular] w-1/2 text-xs`}>{props.dataObject?.views ? props.dataObject?.views : '--'}</p>
        </div>
        <div className={` flex my-4 gap-6`}>
          <p className={`text-deepgray-4 font-[Roboto-Medium] w-1/2 text-xs`}>{t("phone")} </p>
          <p className={`text-[#5B5B5B] font-[Roboto-Regular] w-1/2 text-xs`}>{ props.dataObject?.type === 'user' ? props.dataObject?.phoneNo : props.dataObject?.user?.phoneNo}</p>
        </div>
        <div className={` flex my-4 gap-6`}>
          <p className={`text-deepgray-4 font-[Roboto-Medium] w-1/2 text-xs`}>{t("email")}</p>
          <Tooltip title={props.dataObject?.user?.email}>
            <p className={`text-[#5B5B5B] font-[Roboto-Regular] w-1/2 text-xs truncate`}>{ props.dataObject?.type === 'user' ? props.dataObject?.email : props.dataObject?.user?.email}</p>
          </Tooltip>
        </div>
        <div className={` flex my-4 gap-6`}>
          <p className={`text-deepgray-4 font-[Roboto-Medium] w-1/2 text-xs`}>{t("uniqueid")}:</p>
          <p className={`text-[#5B5B5B] font-[Roboto-Regular] w-1/2 text-xs`}>{ props.dataObject?.type === 'user' ? props.dataObject?.uniqueId : props.dataObject?.user?.uniqueId}</p>
        </div>
        {/* <div className={` flex my-4 gap-6`}>
          <p className={`text-deepgray-4 font-[Roboto-Medium] w-1/2 text-xs`}>{t("password")}</p>
          <p className={`text-[#5B5B5B] font-[Roboto-Regular] w-1/2 text-xs`}>--</p>
        </div> */}
        {props.dataObject?.type !== 'user' && 
        <>
        <div className={` flex my-4 gap-6`}>
          <p className={`text-deepgray-4 font-[Roboto-Medium] w-1/2 text-xs`}>{t("governorate")}</p>
          <p className={`text-[#5B5B5B] font-[Roboto-Regular] w-1/2 text-xs`}>
            {props.dataObject?.governorate === null ? "--" : props.dataObject?.governorate}</p>
        </div>
        <div className={` flex my-4 gap-6`}>
          <p className={`text-deepgray-4 font-[Roboto-Medium] w-1/2 text-xs`}>{t("place")}</p>
          <p className={`text-[#5B5B5B] font-[Roboto-Regular] w-1/2 text-xs`}>
            {props.dataObject?.places === null ? "--" : props.dataObject?.places}</p>
        </div>
        {/* <div className={` flex my-4 gap-6`}>
          <p className={`text-deepgray-4 font-[Roboto-Medium] w-1/2 text-xs`}>{t("zipcode")}</p>
          <p className={`text-[#5B5B5B] font-[Roboto-Regular] w-1/2 text-xs`}>
            {props.dataObject?.user?.zipCode === null ? "--" : props.dataObject?.user?.zipCode}</p>
        </div> */}
        <div className={` flex my-4 gap-6`}>
          <p className={`text-deepgray-4 font-[Roboto-Medium] w-1/2 text-xs`}>{t("fulladdress")}</p>
          <Tooltip title={props.dataObject?.address}>
            <p className={`text-[#5B5B5B] font-[Roboto-Regular] w-1/2 text-xs truncate`}>
              {props.dataObject?.address === null ? "--" : props.dataObject?.address}
            </p>
          </Tooltip>
        </div>
        <div className={` flex my-4 gap-6`}>
          <p className={`text-deepgray-4 font-[Roboto-Medium] w-1/2 text-xs`}>{t("location")}</p>
          <p className={`text-[#5B5B5B] font-[Roboto-Regular] w-1/2 text-xs`}>
            {props.dataObject?.location === null ? "--" : props.dataObject?.location}
          </p>
        </div>
        </>
        }
      </div>

      <SnackBarComponent
        messageOpen={adminSnackbar.open}
        messageClose={messageClose}
        notificationText={adminSnackbar.message}
        subText={adminSnackbar.subText}
        alertType={adminSnackbar.alertType}
        borderClass={adminSnackbar.borderClass}
      />
    </div>
  )
}

ProfileUserInfoCard.propTypes = {
  dataObject: PropTypes.object,
}

export default ProfileUserInfoCard