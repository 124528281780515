import React, { useState } from 'react'
import { ClickAwayListener } from '@mui/material'
import shareIcon from '../../Images/Vendor/shareicon.svg'
import {
    FacebookIcon,
    FacebookShareButton,
    WhatsappIcon,
    WhatsappShareButton,
    TwitterIcon,
    TwitterShareButton
} from 'react-share'

const ShareButton = (props) => {
    const shareIconData = [
        {
            id: 1,
            data: <FacebookShareButton url={props.url} quote={props.fbCaption} onClick={(e) => e.stopPropagation()}>
                <FacebookIcon size={30} round={true} />
            </FacebookShareButton>,
            label: `Facebook`
        },
        {
            id: 2,
            data: <TwitterShareButton url={props.url} title={props.twitterCaption} onClick={(e) => e.stopPropagation()}>
                <TwitterIcon size={30} round={true} />
            </TwitterShareButton>,
            label: `Twitter`
        },
        {
            id: 3,
            data: <WhatsappShareButton url={props.url} title={props.whatsappCaption} onClick={(e) => e.stopPropagation()}>
                <WhatsappIcon size={30} round={true} />
            </WhatsappShareButton>,
            label: `WhatsApp`
        },
    ]
    
    const [share, setShare] = useState(false)
    const handleShare = () => {
        setShare(true)
    }

    return (
        <div className="relative flex items-start">
            <img src={shareIcon} alt="Shareicon" height={17} width={17} onClick={(e) => {
                e.stopPropagation()
                handleShare()
            }} className="cursor-pointer" />
            {share && (
                <ClickAwayListener onClickAway={() => setShare(false)}>
                    <div className="bg-white rounded shadow-lg p-1 absolute top-8 right-0 flex gap-5 transition-all animate-pulse">
                        {shareIconData.map((items) => (
                            <div key={items.id} className="flex flex-col justify-center items-center gap-y-2" onClick={(e) => e.stopPropagation()}>
                                <div className="border cursor-pointer border-gray-4 flex items-center rounded-full p-3">
                                    {items.data}
                                </div>
                                <p className="text-xs text-deepgray-4">{items.label}</p>
                            </div>
                        ))}
                    </div>
                </ClickAwayListener>
            )}
        </div>
    )
}

export default ShareButton
