import React from 'react'
import TabComponent from '../../../../Common/UiComponents/TabComponent'
// import RequirementsCard from '../../../../Common/Layout/Card/RequirementsCard';
// import ProfileIcon from '../../.././Images/UserImages/userDummyImage.png'
// import moment from 'moment';
import UserMyReqLiveBid from './UserMyReqLiveBid';
import MyReqFormUser from '../MyReqUser/MyReqFormUser'
import { useEffect } from 'react';
import { ForAdmin } from '../../../../Containers/Admin/Hooks';
import { useSelector } from 'react-redux';
import PendingReqUser from './PendingReqUser';
import ExpiredReqUser from './ExpiredReqUser';
import { ForVendor } from '../../../../Containers/VendorDetailPage/Hooks';
import UserReqSelectedBid from './UserReqSelectedBid';
import UserMyReqLiveDetails from './UserMyReqLiveDetails';
import MyReqActive from './UserMyReqActive';
import DeniedReqUser from './UserMyReqDenied';

const YourRequirements = (props) => {
  const { getCategory } = ForAdmin()
  const { getBidApiCAllByReqId, selectBidsApiCall } = ForVendor()
  const [value, setValue] = React.useState("0");

  useEffect(() => {
    getCategory()
  }, [])

  useEffect(() => {
    if (value === '0') {
      props.getUserRequirementsByUserId('live')
    }
    else if (value === '1') {
      props.getUserRequirementsByUserId('active')
    }
    else if (value === '2') {
      props.getUserRequirementsByUserId('pending')
    }
    else if (value === '3') {
      props.getUserRequirementsByUserId('denied')
    }
    else if (value === '4') {
      props.getUserRequirementsByUserId('expire')
    }
  }, [value])

  const liveMyRequirementsDataUser = useSelector((state) => state.users.myRequirementByUserId)
  // console.log(liveMyRequirementsDataUser, "liveMyRequirementsDataUser ##")

  const yourRequirements = [
    {
      label: "live",
      value: "0",
      content: <UserMyReqLiveBid
        liveMyRequirementsDataUser={liveMyRequirementsDataUser}
        goToUserMyReqrLiveDetails={props.goToUserMyReqrLiveDetails}
        loadingUser={props.loadingUser}
        downloadPdfAttachments={props.downloadPdfAttachments}
      />
    },
    {
      label: "active",
      value: "1",
      content: <MyReqActive
        liveMyRequirementsData={liveMyRequirementsDataUser}
        goToUserMyReqrLiveDetails={props.goToUserMyReqrLiveDetails}
        loadingUser={props.loadingUser}
        downloadPdfAttachments={props.downloadPdfAttachments}
      />
    },
    {
      label: "pending",
      value: "2",
      content: <PendingReqUser
        liveMyRequirementsData={liveMyRequirementsDataUser}
        goToUserMyReqrLiveDetails={props.goToUserMyReqrLiveDetails}
        loadingUser={props.loadingUser}
        downloadPdfAttachments={props.downloadPdfAttachments}
      />
    },
    {
      label: "denied",
      value: "3",
      content: <DeniedReqUser
        liveMyRequirementsData={liveMyRequirementsDataUser}
        goToUserMyReqrLiveDetails={props.goToUserMyReqrLiveDetails}
        loadingUser={props.loadingUser}
        downloadPdfAttachments={props.downloadPdfAttachments}
      />
    },
    {
      label: "expired",
      value: "4",
      content: <ExpiredReqUser
        liveMyRequirementsData={liveMyRequirementsDataUser}
        goToUserMyReqrLiveDetails={props.goToUserMyReqrLiveDetails}
        loadingUser={props.loadingUser}
         downloadPdfAttachments={props.downloadPdfAttachments}
      />
    },
    {
      label: "selectedVendor",
      value: "5",
      backgroundcolor: "bg-[#F7F7F7]",
      content: <UserReqSelectedBid  downloadPdfAttachments={props.downloadPdfAttachments} saveReqData={props.saveReqData} getBidApiCAllByReqId={getBidApiCAllByReqId} />
    }
  ]


  return (
    <div className='bg-white-5'>

      {props.showMyReqrFormUser ?
        (
          <MyReqFormUser
            createVendorMyRequirements={props.createVendorMyRequirements}
            goBackToMyRequirementTab={props.goBackToMyRequirementTab}
            toggleUserMyRequirementForm={props.toggleUserMyRequirementForm}
            showMyReqrForm={props.showMyReqrForm}
            myRequirementCreate={props?.myRequirementCreate}
            getCategoryInVendor={props?.getCategoryInVendor}
            // doUploadedMedia={props.doUploadedMedia}
            multipleMediaApiCall={props.multipleMediaApiCall}
            loading={props.loading}
            reqError={props.reqError}
            myRequirementCreateUser={props.myRequirementCreateUser}
          />
        )
        :
        (props.showUserMyReqrLiveDetails ?
          <UserMyReqLiveDetails
            goBackFromMyReqrLiveDetails={props.goBackFromMyReqrLiveDetails}
            liveMyRequirementsDataUser={liveMyRequirementsDataUser}
            saveReqData={props.saveReqData}
            getBidApiCAllByReqId={getBidApiCAllByReqId}
            selectBidsApiCall={selectBidsApiCall}
          />
          :
          <TabComponent
            mappingname={yourRequirements}
            button={true}
            buttonextracls={`bg-skyblue-6  text-white rounded text-sm mt-2 mr-4 capitalize`}
            buttonname="addNew"
            onClick={props.toggleUserMyRequirementForm}
            value={value}
            setValue={setValue}
          />
        )

      }

    </div>
  )
}

export default YourRequirements