import React, { useEffect } from 'react'
import BidCard from '../../../../Common/Layout/Card/BidCard'
import DefaultProfileicon from '../../../../Images/Vendor/DefaultProfileicon.svg'
import { useSelector } from 'react-redux'

const UserReqSelectedBid = (props) => {
    const biddata = useSelector((state) => state.vendor.bidsByReqId)
    const biddataSelected = biddata?.filter((item) => (item?.selected === true))

    return (
        <div className={`overflow-y-scroll h-[78vh] bg-white-5`}>
            {biddataSelected?.map((item, idx) =>
                <BidCard
                    DefaultProfileicon={DefaultProfileicon}
                    name={item?.user?.name}
                    email={item?.user?.email}
                    phoneNo={item?.user?.phoneNo}
                    question={item?.business?.name}
                    content={item?.business?.description !== null ? item?.business?.description : `No description given by the user`}
                    flex={true}
                    showdate={true}
                    date={item?.business?.establishmentYear}
                    numberofpeoplereplied={item.numberofpeoplereplied}
                    time={item.time}
                    downloadPdfAttachments={props.downloadPdfAttachments}
                />
            )}
        </div>)
}

export default UserReqSelectedBid