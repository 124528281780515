import { Grid, Icon, IconButton } from '@mui/material'
import React, { useEffect, useState } from 'react'
// import { useForm, Controller } from 'react-hook-form'
import ButtonField from '../../../Common/FormFields/ButtonField'
import Businessbuttonicon from "../../../Images/accountcreate/bussinessbuttonicon.svg"
import Businessbuttonblueicon from "../../../Images/accountcreate/bussinessbuttonblueicon.svg"
import Indivisualbuttonicon from "../../../Images/accountcreate/indivisualbuttonicon.svg"
import Indivisualbuttonblueicon from "../../../Images/accountcreate/indivisualbuttonblueicon.svg"
import CheckBox from "../../../Common/FormFields/CheckBox"
import OTPFieldInput from '../../../Common/FormFields/OTPFieldInput'
import InputField from '../../../Common/FormFields/InputField'
import greentick from "../../../Images/accountcreate/greentick.svg"
import CreateAddress from './CreateAddress'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useTranslation } from 'react-i18next'
import PhoneInputField from '../../../Common/FormFields/PhoneInputField'
import ConfirmationModal from '../../../Common/Popups/ConfirmationModal'
import { TroubleshootRounded } from '@mui/icons-material'
// import MuiPopover from '../../../Common/UiComponents/MuiPopover'
// import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const CreateAccount = (props) => {
    const { t, i18n } = useTranslation()
    const [isModalOpen, setIsModalOpen] = useState(false);

    // const { handleSubmit, reset, control, getValues, watch, formState: { errors } } = useForm();
    // const [showPassword, setShowPassword] = useState(false);
    // const [showOTP, setShowOTP] = useState(false) //to show otpField for emailField
    // const [showOTPMobile, setShowOTPMobile] = useState(props.showOTPMobile) //to show otpField for mobileField
    // const [OTPForEmail, setOTPForEmail] = useState(); //for Emailotp value
    // const [formdata, setFormData] = useState({ name: "", email: "", phoneNo: "", password: "", typecheck: "", uniqueid: "", cpassword: '' })
    // const [signupErrors, setSignupErrors] = useState("")
    // const [checkEmail, setCheckEmail] = useState()
    // const [checkPhoneNo, setCheckPhoneNo] = useState()
    // const [passErrorMsg, setPassErrorMsg] = useState("")
    const [showPassword, setShowPassword] = useState(true)
    const [showCPassword, setShowCPassword] = useState(true)
    // const [otpShow, setOtpShow] = useState(props.showOTP)

    const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const regexPhone = /^\+?\d{10,14}$/;

    // const handleError = (errors) => {
    //     setSignupErrors(errors)
    // };
    // const passRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,10}$/
    // const passRegex = /^(?=.*\d)(?=.*[a-z]).{4,8}$/
    // const onSubmit = (data) => {
    //     let obj = { ...formdata, data, typecheck, uniqueid }
    //     // console.log(obj, "#")
    //     if (obj?.password === obj?.cpassword) {
    //         setPassErrorMsg('')
    //         // if (passRegex.test(obj?.password) && passRegex.test(obj?.cpassword)) {
    //         setPassErrorMsg('')
    //         setFormData({ ...formdata, data })
    //         props.CreateAccountVendor(obj)
    //         // reset()
    //         // }
    //         // else {
    //         //     setPassErrorMsg('Password must be at least 1 lowercase,minimum 2 digit and should be between 4 to 8 characters.')
    //         // }
    //     }
    //     else {
    //         setPassErrorMsg('Password must match')
    //     }
    // }


    // useEffect(() => {
    //     watch((value, { name, type }) => setFormData({ ...formdata, ...value }))
    // }, [watch])



    // const handelEmailChange = (event) => {
    //     setCheckEmail(event.target.value)
    // }


    // const handelPhoneNoChange = (event) => {
    //     setCheckPhoneNo(event.target.value)
    // }

    // useEffect(() => {
    //     console.log(setOTPForMobile, "setOTPForMobile---->");
    // }, [setOTPForMobile])

    useEffect(() => {
        const handleBeforeUnload = (event) => {
          event.preventDefault();
          event.returnValue = '';
          return 'Are you sure you want to leave? Your vendor sign-up process may not be complete.';
        };
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
          window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);


    // For showing or hiding the otp button in email input field 
    useEffect(() => {
        if (regexEmail?.test(props.createAccountForm?.values?.email)) {
            props.setShowEmailOtpButtonVendor(true)
        } else {
            props.setShowEmailOtpButtonVendor(false) 
        }
    }, [props.createAccountForm?.values?.email])

     // For showing or hiding the otp button in phone input field 
     useEffect(() => {
        if (regexPhone?.test(props.phoneNo)) {
            props.setShowPhoneOtpButtonVendor(true)
        } else {
            props.setShowPhoneOtpButtonVendor(false) 
        }
    }, [props.phoneNo])

    const handleGotoAddressSection = () =>  {
        props?.setShowAddAddress(true)
        setIsModalOpen(false)
    }
    
    useEffect(()=> {
        if(props.emailErrorTexts?.error && props.emailErrorTexts?.code === 'BUSINESS_CREATION_PENDING'){
            setIsModalOpen(true)
        }
    },[props.emailErrorTexts])

    return (
        <>
         <ConfirmationModal
                isOpen={isModalOpen}
                onConfirm={handleGotoAddressSection}
                onCancel={() =>  setIsModalOpen(false)}
                message="Your Business Information is pending, got to business information form"
            />
            {!props.showAddAddress ?
                <div className={`w-full flex lg:flex-row flex-col justify-between p-[5%]`}>

                    {/* middleside start*/}
                    <div className={` w-[50%]`}>
                        <form onSubmit={props.createAccountForm?.handleSubmit}
                            className={`flex flex-col items-start justify-between h-[48em] `}>

                            {/* Name section */}
                            <div className={`flex flex-col w-full`}>
                                <div className={`flex space-between rounded w-full  cursor-pointer items-center`} >
                                    <InputField
                                        extracls={`w-full text-sm !p-0 `}
                                        placeholder="enterName"
                                        textinputname="name"
                                        variant="outlined"
                                        type="text"
                                        id="name"
                                        onChange={props.createAccountForm?.handleChange}
                                        value={props.createAccountForm?.values?.name}
                                        endAdornment={true}
                                        floatingLabel={'enterName'}
                                        error={
                                            props.createAccountForm?.touched?.name &&
                                            Boolean(props.createAccountForm?.errors?.name)
                                        }
                                        helperText={
                                            props.createAccountForm?.touched?.name &&
                                            props.createAccountForm?.errors?.name
                                        }
                                    />
                                </div>
                            </div>

                            {/* Email section */}
                            <div className={`flex flex-col w-full`}>
                                <div className={`flex space-between rounded w-full  cursor-pointer items-center`} >

                                    <InputField
                                        extracls={`w-full text-sm !p-0`}
                                        placeholder="enterEmail"
                                        textinputname="email"
                                        variant="outlined"
                                        type="email"
                                        id="email"
                                        onChange={props.createAccountForm?.handleChange}
                                        value={props.createAccountForm?.values?.email}
                                        clickEnter={props.handleClickForOTP}
                                        endAdornment={true}
                                        floatingLabel={'enterEmail'}
                                        inputEndAdornment={
                                            props.emailVerified === true ?
                                                <div className={`m-2`}>
                                                    <img src={greentick} alt="greentick" />
                                                </div>
                                                :
                                                props.showEmailOtpButtonVendor &&
                                                <div className={`m-1`}>
                                                    <ButtonField
                                                        buttonextracls={` font-medium font-[Roboto-Medium] capitalize w-20 h-8 text-xs 
                                                        ${props.createAccountForm?.values?.email ? "bg-skyblue-6 text-white"
                                                                : "bg-[#000000]/[.12] text-[#000000]/[.26] cursor-not-allowed"}`}
                                                        // buttonextracls={` font-medium font-[Roboto-Medium] capitalize w-20 h-8 text-xs bg-skyblue-6 text-white`}
                                                        buttonName="send OTP"
                                                        onClick={props.handleClickForOTP}
                                                        // disabled={props.showOTP ? true : false}
                                                    />
                                                </div>}
                                        error={
                                            props.createAccountForm?.touched?.email &&
                                            Boolean(props.createAccountForm?.errors?.email)
                                        }
                                        helperText={
                                            props.createAccountForm?.touched?.email &&
                                            props.createAccountForm?.errors?.email
                                        }
                                    />

                                </div>
                                {props.emailErrorTexts?.error === true && <p className={`text-sm font-[Roboto-Regular] text-red-500 italic`}>{props.emailErrorTexts?.message}</p>}

                                {props.showOTP ?
                                    <OTPFieldInput
                                        title="*Enter OTP sent to your email id"
                                        // value={props.autoFillOtp}
                                        value={props.OTPForEmail}
                                        onChange={props.setOTPForEmail}
                                        placeholder={[0, 0, 0, 0, 0, 0]}
                                        OTPLength={6}
                                        otpType="number"
                                        onResendClick={() => props.EmailOTP(props.createAccountForm?.values)}
                                    />
                                    :
                                    null
                                }
                            </div>

                            {/* Phone Number section */}
                            <div className={`flex flex-col w-full`}>
                                <div className={`flex space-between rounded w-full  cursor-pointer items-center relative`} >
                                    <div className={`flex flex-col w-full`}>
                                        
                                    <PhoneInputField
                                        phoneName={'phoneNo'}
                                        defaultCountry={'us'}
                                        placeholder={'*Enter your Phone number'}
                                        label={'Phone Number'}
                                        inputCls={`w-full h-full cursor-default`}
                                        onChange={props.phoneChangeHandler}
                                        value={props.phoneNo}
                                        clickEnter={props.handleClickForOTPMobile}
                                        enableSearch={true}
                                        disabled={props.emailVerified ? false : true}
                                        />
                                    {props.phoneNoError && <p className={`text-red-600 text-[13px] pl-3`}>
                                        {`Please enter a valid Phone Number`}
                                    </p>}
                                        </div>
                                    <div className={`absolute right-3 ${props.phoneNoError ? 'top-2' : ''}`}>
                                        {
                                            props.phoneNoVerified === true ?
                                                <div className={`m-2`}>
                                                    <img src={greentick} alt="greentick" width={14}/>
                                                </div>
                                                :
                                                // props.showPhoneOtpButtonVendor && 
                                                <div className={`m-1`}>
                                                    <ButtonField
                                                        buttonextracls={`font-medium font-[Roboto-Medium] capitalize w-20 h-8 text-xs 
                                                        ${props.phoneNo.length > 4 ? "bg-skyblue-6 text-white"
                                                                : "bg-[#000000]/[.12] text-[#000000]/[.26] cursor-not-allowed"}`}
                                                        buttonName="Send OTP"
                                                        onClick={props.handleClickForOTPMobile}
                                                        // disabled={props.showOTPMobile ? true : false}
                                                    />
                                                </div>
                                        }
                                    </div>
                                </div>
                                {props.showOTPMobile ?
                                    <OTPFieldInput
                                        title="*Enter OTP sent to your mobile number"
                                        onChange={props.setOTPForMobile}
                                        value={props.OTPForMobile}
                                        placeholder={[0, 0, 0, 0, 0, 0]}
                                        OTPLength={6}
                                        otpType="number"
                                        onResendClick={() => props.PhoneOTP(props.createAccountForm?.values, props.phoneNo)}
                                    />
                                    :
                                    null
                                }
                            </div>


                            {/* Choose Vendor Type */}
                            <div>
                                <p className={`font-[Roboto-Regular] text-base text-[Grey-900] mb-3`}>{t("chooseVendorType")}</p>
                                <div className={`flex ${i18n.dir() === 'rtl' && 'gap-6'}`}>

                                    <ButtonField
                                        buttonextracls={`bg-gray-0.5 border-[1.5px] border-solid border-deepgray-0.5 font-medium font-[Roboto-Medium] capitalize w-40 h-16 text-xs mr-6 
                                        ${i18n.dir() === 'rtl' && 'mr-0'} ${props.colorForBussiness ? " bg-[#DCF2FA]/80 border-[#0099CC] "
                                                : "bg-gray-0.5 border-deepgray-0.5"} `}
                                        buttonName="Business"
                                        imgtext={`justify-between w-28`}
                                        buttonnamecls={`font-[Roboto-Regular]  font-normal text-sm 
                                        ${props.colorForBussiness ? "text-[#0099CC]" : "text-deepgrey-2"}`}
                                        img={
                                            props.colorForBussiness ? Businessbuttonblueicon
                                                : Businessbuttonicon
                                        }
                                        onClick={props.handleClickForChooseBussiness}
                                        disabled={props.verifyotp ? false : true}

                                    />

                                    <ButtonField
                                        buttonextracls={` border-[1.5px] border-solid  font-medium font-[Roboto-Medium] capitalize w-40 h-16 text-xs 
                                        ${props.colorForIndivisual ? " bg-[#DCF2FA]/80 border-[#0099CC] " : "bg-gray-0.5 border-deepgray-0.5"} `}
                                        imgtext={`justify-between w-28`}
                                        buttonName="Individual"
                                        buttonnamecls={`font-[Roboto-Regular]  font-normal text-sm
                                         ${props.colorForIndivisual ? "text-[#0099CC]" : "text-deepgrey-2"}`}
                                        img={
                                            props.colorForIndivisual ? Indivisualbuttonblueicon
                                                : Indivisualbuttonicon
                                        }
                                        onClick={props.handleClickForChooseIndivisual}
                                        disabled={props.verifyotp ? false : true}

                                    />
                                </div>
                            </div>

                            {/* Uniqueid */}

                            <div>
                                <p className={`text-text-60 font-[Roboto-Regular] text-sm font-normal mb-1`}>{t("uniqueId")}</p>
                                <p className={`text-skyblue-6 font-[Roboto-Regular] text-sm font-normal`}>
                                    {props.uniqueid ? props.uniqueid : `--`}
                                </p>
                            </div>


                            {/* password */}
                            <div className={`flex flex-col w-full`}>
                       
                                <div className={`flex space-between rounded w-full  cursor-pointer items-center`} >

                                    <InputField
                                        extracls={`w-full text-sm `}
                                        placeholder="enterPass"
                                        textinputname="password"
                                        variant="outlined"
                                        // type="password"
                                        typePassword={showPassword}
                                        id="password"
                                        onChange={props.createAccountForm?.handleChange}
                                        value={props.createAccountForm?.values?.password}
                                        disabled={props.uniqueid ? false : true}
                                        endAdornment={true}
                                        floatingLabel={'enterPass'}
                                        inputEndAdornment={
                                            <IconButton
                                                onClick={() => setShowPassword(!showPassword)}
                                                tabIndex={-1}
                                            >
                                                <Icon className="text-20" color="action" tabIndex={-1}>
                                                    {showPassword ?
                                                        <VisibilityOffIcon sx={{ color: '#747774' }} />
                                                        :
                                                        <RemoveRedEyeIcon sx={{ color: '#747774' }} />}
                                                </Icon>
                                            </IconButton>
                                        }
                                        error={
                                            props.createAccountForm?.touched?.password &&
                                            Boolean(props.createAccountForm?.errors?.password)
                                        }
                                        helperText={
                                            props.createAccountForm?.touched?.password &&
                                            props.createAccountForm?.errors?.password
                                        }
                                    />
                                    {/* <div onClick={() => setShowPassword(!showPassword)}>
                                <p style={{ paddingLeft: "10px", fontSize: "15px", color: "gray" }}>{showPassword ? "Hide" : "Show"}
                                </p>
                            </div> */}
                                </div>
                            </div>


                            {/* confirmpassword */}

                            <div className={`flex flex-col w-full`}>
                               
                                <div className={`flex space-between rounded w-full cursor-pointer items-center`} >
                                    <InputField
                                        extracls={`w-full text-sm `}
                                        placeholder="reTypePass"
                                        textinputname="cpassword"
                                        variant="outlined"
                                        // type="password"
                                        id="cpassword"
                                        onChange={props.createAccountForm?.handleChange}
                                        value={props.createAccountForm?.values?.cpassword}
                                        disabled={props.uniqueid ? false : true}
                                        endAdornment={true}
                                        clickEnter={props.createAccountForm?.handleSubmit}
                                        typePassword={showCPassword}
                                        floatingLabel={'reTypePass'}
                                        inputEndAdornment={
                                            <IconButton
                                                onClick={() => setShowCPassword(!showCPassword)}
                                                tabIndex={-1}
                                            >
                                                <Icon className="text-20" color="action" tabIndex={-1}>
                                                    {showCPassword ?
                                                        <VisibilityOffIcon sx={{ color: '#747774' }} />
                                                        :
                                                        <RemoveRedEyeIcon sx={{ color: '#747774' }} />}
                                                </Icon>
                                            </IconButton>
                                        }
                                        error={
                                            props.createAccountForm?.touched?.cpassword &&
                                            Boolean(props.createAccountForm?.errors?.cpassword)
                                        }
                                        helperText={
                                            props.createAccountForm?.touched?.cpassword &&
                                            props.createAccountForm?.errors?.cpassword
                                        }
                                    />
                                    {/* <div onClick={() => setShowPassword(!showPassword)}>
                                <p style={{ paddingLeft: "10px", fontSize: "15px", color: "gray" }}>{showPassword ? "Hide" : "Show"}
                                </p>
                            </div> */}
                                </div>
                            </div>

                            {/* Terms&condition */}
                            <div className={`flex items-center`}>
                                <CheckBox
                                    checked={props.check}
                                    handleBoxChange={(e) => props.handleChecking(e)}
                                // disabled={props.verifyotp ? false : true}
                                />
                                <p onClick={props.verifyotp === true ? props.toggleChange : null}
                                    className={`cursor-pointer text-Grey-900 font-[Roboto-Regular] font-normal text-sm pl-2`}> Subscribe Daily
                                    <span className={`text-Active-100 pl-1 font-bold`}>
                                        Newsletter
                                    </span>
                                </p>
                            </div>

                            {/* Register&ContinueButton */}
                            <div className={`flex`}>
                                <ButtonField
                                    buttonextracls={`bg-skyblue-6 cursor-pointer border-[1.5px] border-solid border-deepgray-0.5 capitalize w-56 h-12`}
                                    imgtext={`justify-between w-40`}
                                    buttonName="registAndCont"
                                    buttonnamecls={`font-[Roboto-Regular] text-white font-medium text-sm`}
                                    type="submit"
                                    loading={props.loading}
                                // disabled={Object.keys(errors) && Object.keys(errors).length > 0 ? true : false}
                                />
                                {props.cancelbutton ?
                                    <ButtonField
                                        buttonextracls={`cursor-pointer  border-[1.5px] border-solid border-deepgray-0.5 capitalize w-24 ml-5  h-12`}
                                        buttonName="Cancel"
                                        buttonnamecls={`font-[Roboto-Medium] text-deepgray-0.5 font-medium text-sm`}
                                        type="submit"
                                        onClick={props.cancelclick}
                                    />
                                    : null
                                }
                            </div>

                        </form>
                    </div>


                    {/* middleside end*/}



                </div>
                 :
                <CreateAddress businessAddAddress={props.businessAddAddress}
                    doUploadedMedia={props.doUploadedMedia}
                    doUploadedLogo={props.doUploadedLogo}
                    multipleMediaApiCall={props.multipleMediaApiCall}
                    typecheck={props.typecheck}
                    mapLocationTxt={props.mapLocationTxt}
                    setMapLocationTxt={props.setMapLocationTxt}
                    mapLocation={props.mapLocation}
                    handleClickLocation={props.handleClickLocation}
                    showAddAddress={props.showAddAddress}
                />
            }
        </>
    )
}

export default CreateAccount