import { useState } from 'react';
import PropTypes from 'prop-types';
import Carousel from 'react-material-ui-carousel';
import { Paper } from '@mui/material';
import ImagePreviewer from './ImagePreviewer';

const MuiCarousel = (props) => {
    const [isModalOpen, setModalOpen] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);

    const handleOpenModal = (index) => {
        setCurrentIndex(index);
        setModalOpen(true);
    };
    

    return (
        <>
            <Carousel
                sx={{ height: props.height || 'auto', ...props.sx }}
                duration={props.duration}
                navButtonsProps={props.navButtonsProps}
                autoPlay={props.autoPlay}
                swipe={props.swipe}
                animation={props.animation}
                strictIndexing={props.strictIndexing}
                indicators={props.indicators}
                stopAutoPlayOnHover={props.stopAutoPlayOnHover}
                interval={props.interval}
                fullHeightHover={props.fullHeightHover}
                navButtonsAlwaysVisible={props.navButtonsAlwaysVisible}
                navButtonsAlwaysInvisible={props.navButtonsAlwaysInvisible}
                cycleNavigation={props.cycleNavigation}
                onChange={props.handleChange}
                navButtonsWrapperProps={props.navButtonsWrapperProps}
            >
                {props.itemContents &&
                    props.itemContents.length > 0 &&
                    props.itemContents.map((item, id) => (
                        <div key={id} className="flex justify-center items-center">
                            <Paper
                                elevation={0}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    padding: 0,
                                    cursor: 'pointer',
                                }}
                                onClick={() => handleOpenModal(id)} // Open modal on click
                            >
                                {item?.key && (
                                    <div style={{ width: '100%', height:"100px" }}>
                                        <img
                                            crossOrigin="anonymous"
                                            src={`${process.env.REACT_APP_DG_BASE_URL}/uploads/download/file?key=${item?.key}`}
                                            alt="carousel-img"
                                            style={{
                                                display: 'block',
                                                width: '100%',
                                                height: 'auto',
                                                objectFit: 'contain',
                                            }}
                                        />
                                    </div>
                                )}
                            </Paper>
                        </div>
                    ))}
            </Carousel>
            <ImagePreviewer
                isModalOpen={isModalOpen}
                setModalOpen={setModalOpen}
                currentIndex={currentIndex}
                setCurrentIndex={setCurrentIndex}
                images={props.itemContents}
            />
        </>
    );
};

MuiCarousel.propTypes = {
    sx: PropTypes.object,
    duration: PropTypes.number,
    height: PropTypes.string,
    navButtonsProps: PropTypes.object,
    navButtonsWrapperProps: PropTypes.object,
    autoPlay: PropTypes.bool,
    swipe: PropTypes.bool,
    animation: PropTypes.oneOf(['fade', 'slide']),
    strictIndexing: PropTypes.bool,
    indicators: PropTypes.bool,
    stopAutoPlayOnHover: PropTypes.bool,
    interval: PropTypes.number,
    fullHeightHover: PropTypes.bool,
    navButtonsAlwaysVisible: PropTypes.bool,
    navButtonsAlwaysInvisible: PropTypes.bool,
    cycleNavigation: PropTypes.bool,
    onChange: PropTypes.func,
    itemContents: PropTypes.arrayOf(PropTypes.object),
};

export default MuiCarousel;
