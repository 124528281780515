import { useState } from 'react';
import ButtonField from '../../../Common/FormFields/ButtonField'
import deleteicon from "../../../Images/profileDashboardIcons/delete.svg"
import { useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next'
import moment from 'moment/moment'
import { ForAdmin } from '../../../Containers/Admin/Hooks'
import MuiCarousel from '../../../Common/UiComponents/Carousel'
import SnackBarComponent from '../../../Common/Layout/Snackbar/SnackBar'
import DeclinePopup from '../../../Common/FormFields/DeclinePopup'
import { priceAfterDiscount } from '../../../Utils/CommonFunc'
import { CircularProgress } from '@mui/material'
import NoContentPage from '../../../Common/Layout/NoContentPage/NoContentPage'

const VendorServiceOfferPending = (props) => {

    const { approveVendorService, adminSnackbar, messageClose, declineVendorService } = ForAdmin();

    const { t } = useTranslation()
    const locatn = useLocation()
    const [serviceActive, setServiceActive] = useState({
        state: false,
        id: ''
    })

    const approveServices = (serviceId, busId) => {
        approveVendorService(serviceId, busId)
        setServiceActive({
            state: true,
            id: serviceId
        })
    }

    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [serviceItem, setServiceItem] = useState()
 
    const handleclickForDeleteService = (data) => {
        setOpenDeleteModal(!openDeleteModal)
        setServiceItem(data)
    }
    const deleteServiceSubmit = () => {
        props.deletePendingServices(serviceItem)
        setOpenDeleteModal(!openDeleteModal)
    }
    const [isDecline, setIsDecline] = useState(false)
    const [declineItemId, setDeclineItemId] = useState(null)
    
    const handleOpenDeclineModal = (itemId, busId) =>  {
        setDeclineItemId({itemId, busId});
        setIsDecline(true)
        setOpenDeleteModal(true)
    }

    const handleCloseDeclineModal = (itemId, busId) =>  {
        setDeclineItemId(null);
        setIsDecline(true)
        setOpenDeleteModal(false);
    }

    const handleDeclineService = (reason) =>  {
        if(declineItemId){
            declineVendorService(declineItemId?.itemId, declineItemId?.busId, reason)
            setIsDecline(false)
            setOpenDeleteModal(false)
            setDeclineItemId(null)
        }
       
    }



    return (
        <div className={`relative overflow-y-scroll h-[78vh] ${props.vendorServicePendingTableRowCls}`}>
            {props.loading === true ?
                <div className='flex h-full justify-center items-center'>
                    <CircularProgress size={20} color='success' />
                    <p className='pl-3'>Loading...</p>
                </div>
                :
                <div>
                    {props.userData && props.userData?.length > 0 ?
                        <table class="table-auto w-full">
                            <thead>
                                <tr className={`w-full h-12 sticky top-0 z-[10] bg-white`}>
                                    <th className={`w-[22%] font-[Roboto-Regular] text-base text-text-60 font-normal text-left pl-5`}>{t("preview")}</th>
                                    <th className={`w-[28%] font-[Roboto-Regular] text-base text-text-60 font-normal text-left`}>{t("service")}</th>
                                    <th className={`w-[13%] font-[Roboto-Regular] text-base text-text-60 font-normal text-left`}>{t("date")}</th>
                                    {locatn.pathname === '/vendor/services' &&
                                        <th className={`w-[16%] font-[Roboto-Regular] text-base text-text-60 font-normal text-left`}>{t("addOffer")}</th>
                                    }
                                    <th className={`w-[16%] font-[Roboto-Regular] text-base text-text-60 font-normal text-left`}>{t("adminApproval")}</th>
                                    {locatn.pathname === '/vendor/services' &&
                                        <th className={`w-[20%] font-[Roboto-Regular] text-base text-text-60 font-normal text-left pr-5`}>{t("action")}</th>
                                    }
                                </tr>
                            </thead>

                            <tbody>
                                {props.userData?.map((item, idx) =>
                                    <tr key={idx} className={`border border-b-grey py-5 `}>
                                        {item?.status === 'pending' &&
                                            <>
                                                <td className={`pl-4 py-5`}>
                                                    {/* <img src={defaultserviceIcon} alt="defaultserviceIcon" /> */}
                                                    <MuiCarousel
                                                        itemContents={item?.thumbnail} // carousel img reffers to show the images
                                                        height="100px"
                                                        autoPlay={false}
                                                        navButtonsAlwaysVisible={true}
                                                        stopAutoPlayOnHover={false}
                                                        animation="slide"
                                                        indicators={false}
                                                        navButtonsProps={{
                                                            style: {
                                                                borderRadius: 50,
                                                                margin: 2,
                                                                backgroundColor: '#333',
                                                                fontSize: 20
                                                            }
                                                        }} navButtonsWrapperProps={{
                                                            style: {
                                                                bottom: '0',
                                                                top: 'unset'
                                                            }
                                                        }}
                                                    />
                                                </td>
                                                <td>
                                                    <div className={`flex pb-3`} >
                                                        <p className={`font-[Roboto-Regular] text-sm text-gray-60 pr-3`}>{t("serviceName")}:</p>
                                                        <p className={`font-[Roboto-Regular] text-sm text-[#1F1F1F]`}>{item?.name}</p>
                                                    </div>
                                                    <div className={`flex pb-3`} >
                                                        <p className={`font-[Roboto-Regular] text-sm text-gray-60 pr-3`}>{t("description")}:</p>
                                                        <p className={`font-[Roboto-Regular] text-sm text-[#1F1F1F]`}>{item?.description}</p>
                                                    </div>
                                                    {/* <div className={`flex pb-3`}>
                                                        <p className={`font-[Roboto-Regular] text-sm text-gray-60 pr-3`}>{t("deliveryTime")}:</p>
                                                        <p className={`font-[Roboto-Regular] text-sm text-[#1F1F1F]`}>{item?.timeFrame}&nbsp;days</p>
                                                        </div> */}
                                                    <div className={`flex items-center gap-2`}>
                                                        <p className={`font-[Roboto-Regular] text-sm text-gray-60 pr-1 pt-[6px]`}>{t("serviceAmount")}:</p>
                                                        <p className={`text-lg font-[Roboto-Medium] text-[#1F1F1F] ${item?.offer && item?.offer?.length > 0 && 'line-through'}`}>{item?.amount}&nbsp;{t(`KD`)}</p>
                                                        {(item?.offer && item?.offer[0]?.percentage) ?
                                                            <p className={`text-lg font-[Roboto-Medium] text-[#1F1F1F]`}>{priceAfterDiscount(item?.amount, null, item?.offer[0]?.percentage)}&nbsp;{t(`KD`)}</p>
                                                            :
                                                            (item?.offer && item?.offer[0]?.price) &&
                                                            <p className={`text-lg font-[Roboto-Medium] text-[#1F1F1F]`}>{priceAfterDiscount(item?.amount, item?.offer[0]?.price, null)}&nbsp;{t(`KD`)}</p>
                                                        }
                                                    </div>
                                                    {(item?.offer && item?.offer[0]?.percentage) ?
                                                        <div className={`flex items-center`}>
                                                            <p className={`font-[Roboto-Regular] text-sm text-gray-60 pr-1 pt-[6px]`}>{`Percentage`}:</p>
                                                            <p className={`text-lg font-[Roboto-Medium] text-[#1F1F1F]`}>{item?.offer[0]?.percentage}&nbsp;%</p>
                                                        </div>
                                                        :
                                                        (item?.offer && item?.offer[0]?.price) &&
                                                        <div className={`flex items-center`}>
                                                            <p className={`font-[Roboto-Regular] text-sm text-gray-60 pr-1 pt-[6px]`}>{`Amount`}:</p>
                                                            <p className={`text-lg font-[Roboto-Medium] text-[#1F1F1F]`}>{item?.offer[0]?.price}&nbsp;{t(`KD`)}</p>
                                                        </div>
                                                    }
                                                </td>
                                                <td className={``}>
                                                    <p className={`font-[Roboto-Medium] text-[#1F1F1F] text-sm`}>{moment(item?.createdAt).format('LL')}</p>
                                                </td>
                                                {locatn.pathname === '/vendor/services' &&
                                                    <td>
                                                        <ButtonField
                                                            buttonextracls={`${item?.offer?.length === 1 ? 'bg-deepgray-4' : 'bg-skyblue-6'} text-white capitalize`}
                                                            buttonName='addOffer'
                                                            onClick={() => props.toggleAddOffer(item)}
                                                            disabled={item?.offer?.length === 1 ? props.subscriptionValid : false}
                                                        />
                                                    </td>
                                                }
                                                <td>
                                                    {item?.status === "pending"?
                                                        <p className={`text-seconderycolour-4 font-[Roboto-Medium] text-[15px]`}>{t("pending")}</p>
                                                        :
                                                        <p className={`text-green-primary-100 font-[Roboto-Medium] text-[15px]`}>{t("active")}</p>
                                                    }
                                                    <div className={`flex items-center gap-3`}>

                                                        {/* checking so that only admin can see the approve button */}
                                                        {locatn.pathname === `/admin/totalVendors/${item?.createdBy}` &&
                                                            <ButtonField
                                                                buttonextracls={`${(serviceActive?.state === true && serviceActive?.id === item?.id) ? 'hidden' : 'cursor-pointer text-xs bg-skyblue-5 p-2 text-white rounded w-fit'} `}
                                                                onClick={() => approveServices(item?.id, item?.businessId)}
                                                                // loading={serviceActive?.id === item?.id && props.buttonLoading}
                                                                buttonName={`Approve`}
                                                            />
                                                        }
                                                        {/* Service decline */}
                                                        {locatn.pathname === `/admin/totalVendors/${item?.createdBy}` &&
                                                            <ButtonField
                                                                buttonextracls={`${(serviceActive?.state === true && serviceActive?.id === item?.id) ? 'hidden' : 'cursor-pointer text-xs bg-red-5 p-2 text-white rounded w-fit'} `}
                                                                onClick={() => handleOpenDeclineModal(item?.id, item?.businessId)}
                                                                // loading={serviceActive?.id === item?.id && props.buttonLoading}
                                                                buttonName={`Decline`}
                                                            />
                                                        }
                                                    </div>
                                                </td>
                                                {locatn.pathname === '/vendor/services' &&
                                                    <td>
                                                        <div className='flex w-20 pr-8 justify-between'>
                                                            {/* <img src={edit} alt="edit" height={15} width={15} className={`cursor-pointer`} onClick={() => props.editServiceForm(item)} /> */}
                                                            <img src={deleteicon} alt="delete" height={15} width={15} className={`cursor-pointer`} onClick={() => handleclickForDeleteService(item)} />
                                                        </div>
                                                    </td>
                                                }
                                            </>
                                        }
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        :
                        <NoContentPage
                            NoContentMainDiv={`h-[78vh]`}
                            text2={`There is no data`}
                        />
                    }
                </div>
            }
            {openDeleteModal &&
                <DeclinePopup
                    dialogTitlecls={`!text-red-500`}
                    submitBtnCls={`!bg-red-600`}
                    open={openDeleteModal}
                    title={isDecline ? `Decline` : "Delete"}
                    handleClose={isDecline ? handleCloseDeclineModal : handleclickForDeleteService}
                    DilogContenttext={"Price"}
                    DialogContent={
                        <>
                            <p>{isDecline ? "" : t('deleteServiceText')}</p>
                        </>
                    }
                    needDeclineReason={isDecline}
                    DeleteBtn={isDecline ? "Decline" : "Delete"}
                    onClick={(reason) =>  isDecline ? handleDeclineService(reason) : deleteServiceSubmit()}
                />
            }
            <SnackBarComponent
                messageOpen={adminSnackbar.open}
                messageClose={messageClose}
                notificationText={adminSnackbar.message}
                subText={adminSnackbar.subText}
                alertType={adminSnackbar.alertType}
                borderClass={adminSnackbar.borderClass}
            />
        </div>
    )
}

export default VendorServiceOfferPending