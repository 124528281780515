import React, { useEffect } from 'react'
import TabComponent from '../../../Common/UiComponents/TabComponent'
import MyReqLiveBid from '../../VendorDetailsPage/MyRequirement/MyReqLiveBid'
import MyReqPending from '../../VendorDetailsPage/MyRequirement/MyReqPending'
import PendingReqUser from '../../User/UserPages/MyReqUser/PendingReqUser'
import { useSelector } from 'react-redux'
import MyReqActive from '../../VendorDetailsPage/MyRequirement/MyReqActive'

const SingleUserRequirementTab = (props) => {
    const [value, setValue] = React.useState("0");

    const userRequirements = useSelector((state) => state.totalVendorsInAdmin.requirements)

    const categories = [
        {
            label: "approved",
            value: "0",
            backgroundcolor: "bg-[#F7F7F7]",
            content: <MyReqActive downloadPdfAttachments={props?.downloadPdfAttachments} liveMyRequirementsData={userRequirements} />
        },
        {
            label: "pending",
            value: "1",
            backgroundcolor: "bg-white",
            content: <PendingReqUser downloadPdfAttachments={props?.downloadPdfAttachments} liveMyRequirementsData={userRequirements} />
        },
    ]

    useEffect(() => {
        if (value === '0') {
          props.getVendorReqByVendorIdAdmin(props.userId?.id, 'active')
        } else if (value === '1') {
          props.getVendorReqByVendorIdAdmin(props.userId?.id, 'pending')
        }
      }, [props.userId?.id, value])

    return (
        <div>
            <TabComponent
                mappingname={categories}
                button={false}
                value={value}
                setValue={setValue}
            />
        </div>
    )
}

export default SingleUserRequirementTab