import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import Table from '../../../src/Components/PayHistory/Table';
import { ForAdmin } from './Hooks';


function TransactionIndex() {
    const { getTranstactionData, loading } = ForAdmin();
    useEffect(() => {
        getTranstactionData()
    }, [])
    const transactionList = useSelector(
        (store) => store.transactionsData.transactionList
    );
    // console.log(transactionList, '***p')
    return (

        <Table paymentHistory={transactionList} />

    )
}

export default TransactionIndex