import React, { useEffect, useState } from 'react'
import ButtonField from '../../../Common/FormFields/ButtonField'
import CheckBox from '../../../Common/FormFields/CheckBox'
import ScrollTab from '../../../Common/Layout/ScrollTab/ScrollTab'
import CategoryTree from './categoryTree'
import texts from '../../../Common/Languages/en_US.json'
import TextFieldInput from '../../../Common/FormFields/TextFieldInput'
import Modal from '../../../Common/UiComponents/Modal'
import UploadButton from '../../../Common/UiComponents/UploadButton'
import fileUploadIcon from "../../../Images/SubAdmin/fileUploadIcon.svg"
import { setUploadedLogo } from '../../../Containers/UploadMedia/reducer/UploadMediaReducer'
import { useDispatch, useSelector } from 'react-redux'
import CloseIcon from '@mui/icons-material/Close';
import { ForUploadMediaHooks } from '../../../Containers/UploadMedia/Hooks'

const CategoryPreview = (props) => {

    const { logoUpload, cleanupMedia } = ForUploadMediaHooks()

    const loading = useSelector((state) => state.upload.loading)

    const dispatch = useDispatch()

    const logoObj = props.dataValue?.picture

    const logoVar = logoUpload


    const [checked, setChecked] = useState(false)
    // const [uploadLogoProfile, setUploadLogoProfile] = useState([])

    useEffect(() => {
        if (props.dataValue) {
            dispatch(setUploadedLogo(props.dataValue?.picture))
        }
    }, [props.dataValue])
    // console.log(logoVar, "logo")
    // console.log(props.dataValue, "logo 2")

    return (
        <>
            <div>
                <div className={`flex items-center gap-4 w-full h-20 border-b border-solid border-gray-1.1 pl-[2%] sticky top-0 z-40 bg-white`}>
                    <p className={`text-xl text-deepgray-4 font-[Roboto-Medium]`}>{texts.categories}</p>
                    <ButtonField
                        buttonName={'Add new'}
                        buttonextracls={`!px-3 !py-1.5 !text-white bg-skyblue-6 font-[Roboto-Medium]`}
                        onClick={() => props.OpenModal(true)}
                    />
                    {/* under develop */}
                    {/* <div className={`flex items-center gap-2`}>
                            <CheckBox
                                checked={checked}
                                handleCheck={() => setChecked(!checked)}
                            />
                            <p onClick={() => setChecked(!checked)} className={`text-[SegoeSegoe UI] font-normal text-sm text-neutralPrimaryAlt`}>Add new nodes at start</p>
                        </div> */}
                </div>
                <CategoryTree
                    deleteCategory={props.deleteCategory}
                    categoryData={props.categoryData}
                    editCategory={props.editCategory}
                    newCategoryName={props.newCategoryName}
                    addNewCategoryName={props.addNewCategoryName}
                    openEditName={props.openEditName}
                    categoryId={props.categoryId}
                    createCategory={props.createCategory}
                    CategoryName={props.CategoryName}
                    CategoryDescription={props.CategoryDescription}
                    subCategoryModal={props.subCategoryModal}
                    openSubCategoryModal={props.openSubCategoryModal}
                    subCategoryModalClose={props.subCategoryModalClose}
                    subCategoryId={props.subCategoryId}
                    addNewSubCategoryName={props.addNewSubCategoryName}
                    openEditSubCategoryName={props.openEditSubCategoryName}
                    newSubCategoryName={props.newSubCategoryName}
                    editSubCategory={props.editSubCategory}
                    addCategoryName={props.addCategoryName}
                    handelChange={props.handelChange}
                    treeUploadLogoProfile={props.treeUploadLogoProfile}
                    setTreeUploadLogoProfile={props.setTreeUploadLogoProfile}
                    saveId={props.saveId}
                    CategoryForm={props.CategoryForm}
                    doUploadedLogo={props.doUploadedLogo}
                    subCategoryForm={props.subCategoryForm}
                    editSubCategoryData={props.editSubCategoryData}
                    deleteSubCategoryApiCall={props.deleteSubCategoryApiCall}
                />

            </div>

            <Modal
                open={props.openModal}
                HeaderTxt={props.dataValue ? 'Update Category' : 'Add new category'}
                // ConfirmBtn={props.dataValue ? 'update' : 'add'}
                // type='submit'
                // canceltype="reset"
                // CancelBtn={`cancel`}
                // CancelBtncls={`capitalize font-normal !text-black border border-black border-solid px-3 py-1.5`}
                // disabled={loading === true ? true : false}
                // handleClose={() => { props.cancleModal(false); cleanupMedia() }}
                // saveButtonExtraCls={` capitalize font-normal bg-skyblue-6  border-solid border px-3 py-1.5 text-white rounded`}
                // errorMsg={(props.addCategoryName === '' && props.dataValue === null) && '*You can not add an empty name field'}
                DilogContent={
                    <form onSubmit={props.CategoryForm?.handleSubmit}>
                        <>
                            <TextFieldInput
                                floatingLabel={"Enter name"}
                                onlyValue
                                textnewclass={`w-full text-sm  textFieldHeightforCategory`}
                                placeholder='*Enter name'
                                id={"name"}
                                textinputname={"name"}
                                // onChange={(e) => props.CategoryName(e)}
                                onChange={props.CategoryForm?.handleChange}
                                onBlur={props.CategoryForm?.handleBlur}
                                value={props.CategoryForm?.values.name}
                                error={
                                    props.CategoryForm?.touched.name &&
                                    Boolean(props.CategoryForm?.errors.name)
                                }
                                errorText={
                                    props.CategoryForm?.touched.name &&
                                    props.CategoryForm?.errors.name
                                }
                                clickEnter={props.CategoryForm?.handleSubmit}
                            />
                            <TextFieldInput
                                floatingLabel={"Write Description"}
                                onlyValue
                                textnewclass={`w-full text-sm  textFieldHeightforCategory`}
                                placeholder='*Write Description'
                                id={"description"}
                                textinputname={"description"}
                                // onChange={(e) => props.CategoryDescription(e)}
                                onChange={props.CategoryForm?.handleChange}
                                onBlur={props.CategoryForm?.handleBlur}
                                value={props.CategoryForm?.values.description}
                                error={
                                    props.CategoryForm?.touched.description &&
                                    Boolean(props.CategoryForm?.errors.description)
                                }
                                errorText={
                                    props.CategoryForm?.touched.description &&
                                    props.CategoryForm?.errors.description
                                }
                                clickEnter={props.CategoryForm?.handleSubmit}
                            />
                            <div>
                                {
                                    <div className={`relative pt-4`}>
                                        <UploadButton
                                            dropzoneGetRootPropsCls={`w-[5em]`}
                                            sendData={(data) => {
                                                // props.doUploadedLogo(data)
                                                // props.setUploadLogoProfile(data)
                                                if (data[0]?.type === 'image/jpeg' || data[0]?.type === 'image/png') {
                                                    props.doUploadedLogo(data)
                                                }
                                            }}

                                            extracls='flex justify-center items-center bg-white  items-center rounded-md '
                                            type='button'
                                            fileUploadIcon={
                                                // props.uploadLogoProfile && props.uploadLogoProfile?.length > 0 &&
                                                //     (props.uploadLogoProfile[0]?.type === 'image/jpeg' || props.uploadLogoProfile[0]?.type === 'image/png') ?
                                                //     window.URL.createObjectURL(props.uploadLogoProfile[0]) :
                                                //     fileUploadIcon

                                                logoUpload && (logoUpload?.mimetype === 'image/jpeg' || logoUpload?.mimetype === 'image/png') ?
                                                    `${process.env.REACT_APP_DG_BASE_URL}/uploads/download/file?key=${logoUpload?.key}&height=75&width=100` :
                                                    fileUploadIcon
                                            }

                                            accept={'image/jpeg, image/svg, image/png'}
                                            multiple={false}
                                            disabled={logoUpload && (logoUpload.mimetype === 'image/jpeg' || logoUpload?.mimetype === 'image/png') ? true : false}

                                        />
                                        {logoUpload && (logoUpload?.mimetype === 'image/jpeg' || logoUpload?.mimetype === 'image/png') &&
                                            <div className={`absolute -top-0 left-[4.5rem] -right-4 cursor-pointer`}>
                                                <CloseIcon
                                                    sx={{ fontSize: "12px", border: '1px solid red', borderRadius: '9999px' }}
                                                    onClick={() => dispatch(setUploadedLogo({}))}
                                                />
                                            </div>
                                        }
                                    </div>
                                }
                                <p className='text-xs'>
                                    Upload your picture here
                                </p>
                            </div>
                            <div className={`px-1.5 py-3 flex justify-end`}>
                                <ButtonField
                                    loading={props.loading}
                                    buttonName={props.dataValue ? 'update' : 'add'}
                                    type='submit'
                                    buttonextracls={`${loading === true ? `bg-gray-400` : ` bg-skyblue-6`}  capitalize font-normal border-solid border px-3 py-1.5 mr-2 text-white rounded`}
                                    disabled={loading === true ? true : false}
                                />

                                <ButtonField
                                    buttonName={`cancel`}
                                    buttonextracls={`capitalize font-normal !text-black border border-black border-solid px-3 py-1.5`}
                                    onClick={() => { props.cancleModal(false); cleanupMedia() }}
                                    type='reset'

                                />
                            </div>
                        </>
                    </form>
                }
            />
        </>
    )
}

export default CategoryPreview