import React, { useState } from 'react'
import TextEditor from '../../../Common/UiComponents/TextEditor/TextEditor'
import ButtonField from '../../../Common/FormFields/ButtonField'
import text from '../../../Common/Languages/en_US.json'

const PrivacyPolicyAndFaqForm = (props) => {
  const [headingVal, setHeadingVal] = useState("");
  const [contentVal, setContentVal] = useState("");

  const handleHeadingChange = (value) => {
    const cleanedHeadValue = value.toString().replace(/(<([^>]+)>)/ig, ''); // Removes empty html tags
    if (props.value === '0') {
      setContentVal(value);
    }
    else {
      setHeadingVal(cleanedHeadValue);

    }
  };

  const handleContentChange = (value) => {
    const cleanedContentValue = value.toString().replace(/(<([^>]+)>)/ig, ''); // Removes empty html tags
    if (props.value === '0') {
      setContentVal(value);
    }
    else {
      setContentVal(cleanedContentValue);

    }
  };

  return (
    <div className='flex flex-col gap-6 '>
      <div className='headingWrapper flex flex-col gap-3'>
        {props.value === '0' ? <p>{`Heading`}</p> : props.value === '1' && <p>{`Questions`}</p>}
        <TextEditor
          createFaqApiCall={props.createFaqApiCall}
          handleTextEditor={props.handleTextEditor}
          onChange={handleHeadingChange}
        />
      </div>

      <div className='contentWrapper flex flex-col gap-3'>
        <p>Answers</p>
        <TextEditor
          createFaqApiCall={props.createFaqApiCall}
          handleTextEditor={props.handleTextEditor}
          onChange={handleContentChange}
        />
      </div>

      <div>
        <div className={`flex justify-start`}>
          <div className='flex gap-6'>
            <ButtonField
              buttonName={text.cancel}
              type='reset'
              buttonextracls={`!px-6 !py-2 !text-gray-60 bg-gray-1 font-[Roboto-Medium] hover:bg-deepBlue hover:text-white`}
              onClick={props.handleTextEditor}
            />
            <ButtonField
              buttonName={text.save}
              type='submit'
              buttonextracls={`!px-6 !py-2 !text-white bg-skyblue-6 hover:bg-deepBlue`}
              onClick={() => {
                if (props.value === '0' && headingVal.trim().length > 0 && contentVal.trim().length > 0) {
                  props.createFaqApiCall(headingVal, '', contentVal, 'privacyPolicy')
                } else if (props.value === '1' && headingVal.trim().length > 0 && contentVal.trim().length > 0) {
                  props.createFaqApiCall('', headingVal, contentVal, 'faq')
                }
              }
              }
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PrivacyPolicyAndFaqForm