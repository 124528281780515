import React from 'react'
import defaulticon from '../../Images/vendors/defaultprofile.svg'
import uparrow from '../../Images/CommonImages/Rectangleuparrow.svg'
import { useSelector } from 'react-redux'
import NoContentPage from '../../Common/Layout/NoContentPage/NoContentPage'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
const NotificationShow = (props) => {
    const { t, i18n } = useTranslation()
    const allNotifications = useSelector((state) => state.notificationData.allNotification)

    // to conditionally call readAll notifications api 
    const readAllNotifications = ()=>{
        allNotifications?.map((notification) => {
            return (
                notification?.readStatus === false && props.readNotificationApi('readAll')
            )})
        
    }
    
    return (
        <div className='relative mr-8'>
            <div className={`absolute bottom-[96%] ${i18n.dir() === 'rtl' ? 'left-[38%]' : 'right-[45%]'} `}>
                <img src={uparrow} alt="" />
            </div>
            <div className={`bg-white w-[24rem] shadow-md border-gray-200 border rounded`}>
                <div className='flex justify-between p-5 border-b border-b-[#747774]/[.14]'>
                    <p className={`text-[#4E4F5A] font-[Roboto-Bold] text-base cursor-pointer`} onClick={props.showAllNotification} >{t("notification")}</p>
                    <p className={`text-[#434343] font-[Roboto-Regular] text-[13px] cursor-pointer`} onClick={readAllNotifications}>{t("markasallread")}</p>
                </div>
                {allNotifications && allNotifications?.length > 0 ?
                    <div className={`overflow-y-scroll h-[60vh]`}>
                        {allNotifications?.map((item, idx) => {
                            return (
                                <div key={idx} className={`flex justify-between items-center p-5 border-b border-b-[#747774]/[.14] cursor-pointer ${item?.readStatus === false ? 'bg-[#a9c4d4]' : null}`}>
                                    <div className={`flex items-center w-[80%]`}  onClick={() => item?.readStatus === false && props.readNotificationApi('read', item)}>
                                        <img src={defaulticon} alt="defaulticon" height={45} width={45} />
                                        <div className={`pl-2`}>
                                            <p className={`text-text-60 font-[Roboto-Regular] text-[15px]`}>{item?.message}</p>
                                        </div>
                                    </div>
                                    <p className={`text-[#333] font-[Roboto-Regular] text-[11px] w-[18%]`}>{moment(item?.createdAt).startOf('second').fromNow()}</p>
                                </div>
                            )
                        })}
                    </div>
                    :
                    <div className={`flex justify-center items-center h-[70vh]`}>
                        <NoContentPage
                            text2={`No Data Found`}
                        />
                    </div>
                }
            </div>
        </div>

    )
}

export default NotificationShow