import React, { useState } from 'react'
// import CheckBox from '../../../Common/FormFields/CheckBox';
import editIcon from '../../../Images/adminTable/editIcon.svg'
import deleteIcon from '../../../Images/adminTable/deleteIcon.svg'
import usericon from '../../../Images/adminTable/userAvatar.svg'
// import optionMenuIcon from '../../../Images/adminTable/optionMenu.svg'
// import ToggleSwitch from '../../../Common/FormFields/ToggleSwitch';
import './index.css'
// import TotalUsersHeader from '../TotalUsers/TotalUsersHeader';
import text from "../../../Common/Languages/en_US.json"
// import { useSelector } from 'react-redux';
// import ButtonField from '../../../Common/FormFields/ButtonField';
// import { ForAdmin } from '../../../Containers/Admin/Hooks';
import { CircularProgress, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import { Close } from '@mui/icons-material';
import ConfirmationModal from '../../../Common/Popups/ConfirmationModal'


/**
 * @component SubAdminTable
 * @description list of sub admins
 * @param {*} props 
 */

const SubAdminTable = (props) => {
    const [chipData, setChipData] = useState();
    const [openChipModal, setOpenChipmodal] = useState(false);
    const [Id, setId] = useState('')
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [itemToDelete, setItemToDelete] = useState(null);


    const handleDeleteClick = (id) => {
        setItemToDelete(id);
        setIsModalOpen(true);
    };

    const handleConfirmDelete = () => {
        if (itemToDelete) {
            props.deleteSubAdminApiCall(itemToDelete)
            setIsModalOpen(false);
        }
    };

    const handleCancelDelete = () => {
        setIsModalOpen(false);
        setItemToDelete(null)
    };

    //chipmodal handler function

    const handleChipClick = (data, id) => {
        setChipData(data);
        setOpenChipmodal(!openChipModal);
        setId(id);
    }

    // function to close chipmodal
    const handleCloseChipModal = () => {
        setChipData({});
        setOpenChipmodal(false);
    }

    return (
        <div className='w-full border-r-2 border-t-2 border-gray-1 shadow-sm h-[65vh] overflow-y-scroll'>
            {props.loading === true ?
                <div className='flex justify-center h-full items-center'>
                    <CircularProgress size={20} color='success' />
                    <p className='pl-3'>Loading...</p>
                </div>
                :
                <table className="table-auto w-full bg-white">

                    <thead className='sticky top-0 z-10 bg-white'>
                        <tr className='h-10'>
                            {/* <th className={`w-[3%] text-gray-3 text-left  text-base font-normal font-[SansRegular] px-[1%] `}>
                            <CheckBox size={'small'} sx={{ color: '#00000057' }} />
                        </th> */}
                            <th className={`w-[10%] text-deepgrey-3 text-left text-base font-normal font-[Roboto-Regular] truncate px-2`}>{text.subAdmin}</th>

                            <th className={`w-[10%] text-deepgrey-3 text-left text-base font-normal font-[Roboto-Regular] px-4 whitespace-nowrap`}>{text.phoneNumber}</th>
                            <th className={`w-[10%] text-deepgrey-3 text-left text-base font-normal font-[Roboto-Regular] `}>{text.emailId}</th>
                            <th className={`w-[15%] text-deepgrey-3 text-left text-base font-normal font-[Roboto-Regular] px-4 whitespace-nowrap`}>{text.assignedSection}</th>
                            {/* <th className={`w-[8%] text-deepgrey-3 text-left pr-8 text-base font-normal font-[Roboto-Regular] px-4 whitespace-nowrap `}>
                            {text.assignedRole}
                        </th> */}
                            <th className={`w-[10%] text-deepgrey-3 text-left text-base font-normal font-[Roboto-Regular]  pr-6`}>{text.action}</th>

                        </tr>
                    </thead>
                    <tbody>

                        {props.subAdminData?.map((user, idx) => {
                            return (
                                <tr
                                    key={idx}
                                    className={' drop-shadow-md border-t-2 border-b-2  border-[#EBEBEB]'}>
                                    {/* <td className={`text-left text-[14px] font-normal font-[SansRegular] px-[1%]`}>
                                    <CheckBox size={'small'} sx={{ color: '#00000057' }} />
                                </td> */}
                                    <div className='flex justify-start items-center px-2'>
                                        <img
                                            crossOrigin='anonymous'
                                            src={user?.picture?.key ? `${process.env.REACT_APP_DG_BASE_URL}/uploads/download/file?key=${user?.picture?.key}&height=40&width=40` : usericon}
                                            alt="usericon"
                                            className='rounded-full h-10 w-10'
                                        />
                                        <Tooltip title={user?.name}>
                                            <td className={`text-left text-deepgray-5 text-sm py-6 pl-2   font-[Roboto-Regular]  whitespace-nowrap truncate`}>{user?.name}</td>
                                        </Tooltip>
                                    </div>
                                    <td className={`text-left text-[14px]  font-[Roboto-Regular] text-deepgray-5 px-4 whitespace-nowrap`}>{user?.phoneNo}</td>
                                    <td className={`text-left  pr-8 text-blue-5 text-[14px]  font-[Roboto-Regular]`}>{user?.email}</td>
                                    <td className={`text-left text-[14px] text-deepgray-5  font-[Roboto-Regular] px-4 `}>
                                        {/* {user?.sections?.map((item, idx) =>
                                        <span key={idx} className={`text-xs bg-skyblue-0.5 px-2 py-0.5 rounded-full text-skyblue-7 m-1`}>{item?.name}</span>
                                    )} */}
                                        <div className='relative'>
                                            <div onClick={() => handleChipClick(user, user.id)} className={`cursor-pointer`}>
                                                {user?.sections[0]?.name ?
                                                    <span className={`text-sm bg-skyblue-0.5 px-2 py-0.5 w-[10em] rounded-full text-skyblue-7 cursor-pointer`}>
                                                        {user?.sections[0]?.name}
                                                    </span> : null}
                                                {
                                                    user?.sections?.length > 1 ? <span className={`pt-1`}>
                                                        {`  +`} {user?.sections?.length - 1}
                                                    </span> : null
                                                }
                                            </div>

                                            {user.id === Id && openChipModal && <div className={`absolute right-[2.5em] ${idx === (props.subAdminData?.length - 1) ? 'bottom-[2em]' : ''} z-50 bg-white border h-fit w-[15em] pt-1 px-2 pb-3`}>
                                                <div className={`w-full flex flex-col gap-1 h-full`}>
                                                    <div className={`flex w-full justify-end`}  >
                                                        <Close className={`w-5 h-5 cursor-pointer`} onClick={handleCloseChipModal} />
                                                    </div>
                                                    <div className={`flex flex-wrap items-center ${chipData?.sections?.length === 2 ? 'justify-between' : ''} gap-1 w-full `}>
                                                        {chipData?.sections?.map((data, idx) =>
                                                            <span key={idx}
                                                                className={`text-sm bg-skyblue-0.5 w-[6.5em] text-center rounded-full text-skyblue-7 cursor-pointer`}>
                                                                {data?.name}
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>


                                            </div>}
                                        </div>
                                    </td>
                                    {/* <td className={`text-left text-[14px] text-white  font-[Roboto-Regular] px-4`}>
                                    {user.subRole && <ButtonField
                                        buttonName={user?.subRole}
                                        buttonextracls={`!px-3 !py-1 !text-white bg-pink text-[12px] font-[Roboto-Regular] w-[6rem] rounded-full`}
                                    />
                                    }
                                </td> */}

                                    <td className={`text-left pr-6 text-[14px]  font-[Roboto-Regular] `}>
                                        <div className='flex items-center gap-8'>
                                            {(user?.id === parseInt(localStorage.getItem('userId')) || localStorage.getItem('role') === 'admin') &&
                                                <img onClick={() => props.editSubAdminForm(user)} src={editIcon} alt="edit icon" className='text-start cursor-pointer' />
                                            }
                                            <img onClick={() => handleDeleteClick(user?.id)} src={deleteIcon} alt="delete icon" className='text-start cursor-pointer' />
                                            {/* <img src={optionMenuIcon} alt="option icon" className='text-start cursor-pointer' /> */}
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            }
              <ConfirmationModal
                isOpen={isModalOpen}
                onConfirm={handleConfirmDelete}
                onCancel={handleCancelDelete}
                message="Are you sure you want to delete this?"
            />
        </div>
    )
}

SubAdminTable.propTypes = {
    subAdminUser: PropTypes.string,
}

export default SubAdminTable

