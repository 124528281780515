import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    transactionList: [],
    loading: false,
    error: false,
}

export const TransactionListSlice = createSlice({
    name: 'TransactionList',
    initialState,
    reducers: {
        getTransactionList: (state, action) => {
            state.transactionList = action.payload;
            state.loading = false;
            state.error = false;
        }
    }
})

export const { getTransactionList } = TransactionListSlice.actions;
export default TransactionListSlice.reducer;