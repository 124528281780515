import React, { useEffect, useState } from 'react'
import BidCard from '../../../Common/Layout/Card/BidCard'
import DefaultProfileicon from '../../../Images/Vendor/DefaultProfileicon.svg'
import moment from 'moment'
import TextFieldInput from '../../../Common/FormFields/TextFieldInput'
import { Controller, useForm } from 'react-hook-form'
import DeclinePopup from '../../../Common/FormFields/DeclinePopup'
import { CircularProgress } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import NoContentPage from '../../../Common/Layout/NoContentPage/NoContentPage'

const LiveBid = (props) => {

    const { t } = useTranslation()
    const [openDecline, setOpenDecline] = useState(false)
    const [reqId, setReqId] = useState()

    const handleclickForDeclineBid = (id) => {
        setOpenDecline(!openDecline)
        setReqId(id)
    }

    const onDeclineSubmit = () => {
        props.postDeclinBid(reqId)
        setOpenDecline(!openDecline)
    }

    useEffect(() => {
        props.getAllOfMyRequirements('live')
    }, [])

    const liveRequirementsData = useSelector((state) => state.vendor.myrequirements)

    return (
        <div className='overflow-y-scroll h-[78vh]'>
            {props.loading === true ?
                <div className='flex h-full justify-center items-center'>
                    <CircularProgress size={20} color='success' />
                    <p className='pl-3'>Loading...</p>
                </div>
                :
                <div>
                    {liveRequirementsData && liveRequirementsData?.length > 0 ?
                        <>
                            {liveRequirementsData?.map((allReq, idx) =>
                                <div key={idx}>
                                    {allReq?.status === 'live' ?
                                        allReq?.deniedbids?.some((item) => item?.createdBy === parseInt(localStorage.getItem('userId')) && item?.requirementsId === allReq?.id) ?
                                            null :
                                            <BidCard
                                                DefaultProfileicon={DefaultProfileicon}
                                                name={allReq?.user?.name}
                                                email={allReq?.user?.email}
                                                LiveBid={allReq?.status === 'live' && true}
                                                question={allReq?.title}
                                                content={allReq?.description}
                                                flex={true}
                                                showdate={true}
                                                date={moment(allReq?.startDate).format('LL')}
                                                numberofpeoplereplied={allReq?.numberofpeoplereplied}
                                                uploadDocument={false}
                                                expiredtime={true}
                                                attachment={allReq?.document?.length > 0 && true}
                                                time={moment(allReq?.endDate).format('LL')}
                                                onClick={() => props.goToAllReqrLiveDetails(allReq, true)}

                                                // For Interested bids 
                                                interestedbid={(props.singleVendorDetailsData?.userId === allReq?.createdBy) || (props.interestedBidsData?.some((item) => item?.requirements?.id === allReq?.id)) || (props.deniedBids?.some((item) => item?.requirements?.id === allReq?.id)) ? false : true}
                                                interestedbidDisabled={props.interestedBidsData?.some((item) => item?.requirements?.id === allReq?.id) ? true : false}
                                                doInterestedBidClick={() => props.postInterestedBidApiCall(allReq?.id)}

                                                // For Bids 
                                                // Bid={props.singleVendorDetailsData?.userId === allReq?.createdBy ? false : true}
                                                // bidDisabled={allReq?.bids?.some((item) => item?.createdBy === parseInt(userId)) ? true : false}
                                                // doBidClick={() => handleclickForBid(allReq?.id)}

                                                // For decline bids 
                                                doDeclineBidClick={() => handleclickForDeclineBid(allReq?.id)}
                                                declineBid={(props.singleVendorDetailsData?.userId === allReq?.createdBy) || (props.deniedBids?.some((item) => item?.requirements?.id === allReq?.id)) || (props.interestedBidsData?.some((item) => item?.requirements?.id === allReq?.id)) ? false : true}
                                                deniedbidDisabled={(props.deniedBids?.some((item) => item?.requirements?.id === allReq?.id)) ? true : false}
                                                downloadPdfAttachments={props.downloadPdfAttachments}
                                                item={allReq}
                                            />
                                        : null}
                                </div>
                            )}
                        </>
                        :
                        <NoContentPage
                            NoContentMainDiv={`h-[78vh]`}
                            text2={`There is no data`}
                        />
                    }
                </div>}
            {openDecline &&
                <DeclinePopup
                    dialogTitlecls={`!text-red-500`}
                    submitBtnCls={`!bg-red-600`}
                    open={openDecline}
                    title={`Decline`}
                    handleClose={handleclickForDeclineBid}
                    DilogContenttext={"Price"}
                    DialogContent={
                        <>
                            <p>{t('declineRequirementText')}</p>
                        </>
                    }
                    DeleteBtn={"Decline"}
                    onClick={onDeclineSubmit}
                />
            }
        </div>
    )
}

export default LiveBid