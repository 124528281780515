import React, { useEffect, useState } from 'react'
import BidCard from '../../../../Common/Layout/Card/BidCard'
import moment from 'moment'
import DefaultProfileicon from '../../../../Images/Vendor/DefaultProfileicon.svg'
import { ForAdmin } from '../../../../Containers/Admin/Hooks'
import SnackBarComponent from '../../../../Common/Layout/Snackbar/SnackBar'
import NoContentPage from '../../../../Common/Layout/NoContentPage/NoContentPage'
import { CircularProgress } from '@mui/material'

const PendingReqUser = (props) => {
    const { approveVendorRequirements, adminSnackbar, messageClose } = ForAdmin()

    const [reqActive, setReqActive] = useState({
        state: false,
        id: ''
    })

    const approveRequirements = (reqId, statusVal, userId, reason) => {
        approveVendorRequirements(reqId, statusVal, userId, reason)
        setReqActive({
            state: true,
            id: reqId
        })
    }

    return (
        <div className={`overflow-y-scroll h-[78vh] bg-white-5`}>
            {props.loadingUser === true ?
                <div className='flex h-full justify-center items-center'>
                    <CircularProgress size={20} color='success' />
                    <p className='pl-3'>Loading...</p>
                </div>
                :
                <div>

                    {props.liveMyRequirementsData && props.liveMyRequirementsData?.length > 0 ?
                        <>
                            {props.liveMyRequirementsData?.map((item, idx) =>
                                <div key={idx}>
                                    {item?.status === "pending" &&
                                        <>
                                            <BidCard
                                                DefaultProfileicon={DefaultProfileicon}
                                                name={item?.user?.name}
                                                email={item?.user?.email}
                                                question={item?.title}
                                                content={item?.description}
                                                flex={true}
                                                showdate={true}
                                                date={moment(item?.startDate).format('LL')}
                                                // peoplereplied={true}
                                                // numberofpeoplereplied={item?.numberofpeoplereplied}
                                                attachment={item?.document?.length > 0 ? true : false}
                                                validdate={false}
                                                time={moment(item?.endDate).format('LL')}
                                                onClick={() => props.goToUserMyReqrLiveDetails(item)}
                                                item={item}
                                                approveFunc={approveRequirements}
                                                useStateVar={reqActive}
                                                downloadPdfAttachments={props.downloadPdfAttachments}
                                            />
                                        </>
                                    }
                                </div>
                            )}
                        </>
                        :
                        <NoContentPage
                            NoContentMainDiv={`h-[78vh]`}
                            text2={`There is no data`}
                        />
                    }
                </div>
            }
            <SnackBarComponent
                messageOpen={adminSnackbar.open}
                messageClose={messageClose}
                notificationText={adminSnackbar.message}
                subText={adminSnackbar.subText}
                alertType={adminSnackbar.alertType}
                borderClass={adminSnackbar.borderClass}
            />
        </div>)
}

export default PendingReqUser