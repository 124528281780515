import { useState } from 'react';
import editIcon from '../../../Images/adminTable/editIcon.svg'
import deleteIcon from '../../../Images/adminTable/deleteIcon.svg'
import usericon from '../../../Images/adminTable/userAvatar.svg'
import optionMenuIcon from '../../../Images/adminTable/optionMenu.svg'
import ToggleSwitch from '../../../Common/FormFields/ToggleSwitch';
import TextFieldInput from '../../../Common/FormFields/TextFieldInput';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { ClickAwayListener } from '@mui/base'
import { useNavigate } from 'react-router-dom'
import DeclinePopup from '../../../Common/FormFields/DeclinePopup';
import { CircularProgress, Tooltip } from '@mui/material';
import NoContentPage from '../../../Common/Layout/NoContentPage/NoContentPage'
import ConfirmationModal from '../../../Common/Popups/ConfirmationModal';


/**
 * @component TotalUsersTable
 * @description Users table in details
 * @param {*} props 
 */

const TotalUsersTable = (props) => {

    const totalUsersData = useSelector((state) => state.totalUsers.totalUsers)
    const navigate = useNavigate()


    const [open, setOpen] = useState(false)
    const [openMenu, setOpenMenu] = useState(false)
    const [rowId, setRowId] = useState(false)
    const [userId, setUserId] = useState('')
    const [dataForDecline, setDataForDecline] = useState()

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [itemToDelete, setItemToDelete] = useState(null);


    const handleDeleteClick = (id) => {
        setItemToDelete(id);
        setIsModalOpen(true);
    };

    const handleConfirmDelete = () => {
        if (itemToDelete) {
            props.userEnableDisableApi(itemToDelete)
            setIsModalOpen(false);
        }
    };

    const handleCancelDelete = () => {
        setIsModalOpen(false);
        setItemToDelete(null)
    };


    const handleclickForDecline = (userId) => {
        setOpen(!open)
        // props.approveUserApi(userId, 'denied')
        setUserId(userId)
    }
    const threeDotOptions = (idx) => {
        // setOpenMenu(name, true)
        setOpenMenu(!openMenu)
        setRowId(idx)
    }

    const showUser = (userId) => {
        props.getTotalUserDetailsApi(userId)
        // navigate('user')
        navigate(`/admin/totalUsers/${userId}`)
    }

    const { handleSubmit, reset, control, formState: { errors } } = useForm()

    const onSubmit = (data) => {
        // console.log(data, 'formData');
        setDataForDecline(data)
        props.approveUserApi(userId, 'denied', data)
        setOpen(!open)
        reset(dataForDecline)
    }

    const toggleSwitchBtn = (userId, enableVal) => {
        let toggleVal = enableVal === 1 ? 2 : 1
        props.userEnableDisableApi(userId, toggleVal, 'disable')
    }

    return (
        <div className='w-full h-[70vh] border-[#EBEBEB]'>
            {props.loading === true ?
                <div className='flex h-full justify-center items-center'>
                    <CircularProgress size={20} color='success' />
                    <p className='pl-3'>Loading...</p>
                </div>
                :
                <>
                    {totalUsersData && totalUsersData?.length > 0 ?
                        <table className="table-auto w-full overflow-x-scroll bg-white">
                            <thead className='bg-white sticky top-0 z-10'>
                                <tr className='h-10 '>
                                    {/* <th className={`w-[5%] text-[#828282] text-left  text-base font-normal font-[SansRegular] px-[1%] `}>
                            <CheckBox />
                        </th> */}
                                    <th className={`w-[15%] text-[#585F71] text-left text-base font-normal font-[Roboto-Regular] pl-[2%]`}>User Name</th>

                                    <th className={`w-[10%] text-[#585F71] text-left text-base font-normal font-[Roboto-Regular] `}>Unique Id</th>

                                    <th className={`w-[11%] text-[#585F71] text-left text-base font-normal font-[Roboto-Regular]`}>Phone number</th>

                                    <th className={`w-[13%] text-[#585F71] text-left text-base font-normal font-[Roboto-Regular]`}>Email id</th>
                                    {/* <th className={`w-[10%] text-[#585F71] text-left text-base font-normal font-[Roboto-Regular]`}>Address</th> */}

                                    <th className={`w-[11%] text-[#585F71] text-left text-base font-normal font-[Roboto-Regular]`}>Location</th>
                                    {/* <th className={`w-[13%] text-[#585F71] text-left text-base font-normal font-[Roboto-Regular]  pr-6`}>
                            Place
                        </th> */}
                                    <th className={`w-[11%] text-[#585F71] text-left text-base font-normal font-[Roboto-Regular]`}>Created by</th>
                                    <th className={`w-[24%] text-[#585F71] text-left text-base font-normal font-[Roboto-Regular]`}>Active/Inactive</th>
                                </tr>
                            </thead>
                            <tbody>
                                {totalUsersData?.map((user, idx) => {
                                    return (
                                        <tr
                                            key={idx}
                                            className={`overflow-x-auto drop-shadow-md border-t-2 border-b-2 z-10 border-[#EBEBEB] cursor-pointer h-16 ${user?.tag === 'new' && 'bg-lime-200'}`}
                                        >
                                            {/* <td className={`text-left text-[14px] font-normal font-[SansRegular] px-[1%]`}>{<CheckBox />}</td> */}
                                            <td className={`flex h-16 items-center px-[7%]`} onClick={() => showUser(user?.id)}>
                                                {user?.tag === 'new' &&
                                                    <p className={`absolute top-1 left-1 text-sm font-[RobotoMedium] -rotate-45 text-seconderyColour-5`}>New</p>
                                                }
                                                <img crossOrigin='anonymous' src={user?.picture?.key ? `${process.env.REACT_APP_DG_BASE_URL}/uploads/download/file?key=${user?.picture?.key}&height=42&width=42` : usericon} alt="profileIcon" className={`${user?.picture?.key !== null && 'rounded-full border'}`} />
                                                <Tooltip title={user?.name}>
                                                    <p className={`text-left text-[#1F2533] text-sm pl-2 pr-2 font-medium  font-[Roboto-Regular] truncate`}>
                                                        {user?.name}
                                                    </p>
                                                </Tooltip>
                                            </td>

                                            <td className={`text-left text-[14px] font-normal font-[Roboto-Regular] `} onClick={() => showUser(user?.id)}>{user?.uniqueId}</td>

                                            <td className={`text-left text-[14px] font-normal font-[Roboto-Regular] `} onClick={() => showUser(user?.id)}>{user?.phoneNo}</td>

                                            <td className={`text-left  pr-4 text-[#1F2533] text-[14px] font-normal font-[Roboto-Regular`} onClick={() => showUser(user?.id)}>
                                                <Tooltip title={user?.email}>
                                                    <p className='w-[8em] truncate'>{user?.email}</p>
                                                </Tooltip>
                                            </td>

                                            {/* <Tooltip title={user?.address}>
                                    <td className={`text-left text-[14px] text-[#1F2533] font-normal font-[Roboto-Regular]`} onClick={() => showUser(user?.id)}>
                                        <p className='w-[50%] truncate'>{user?.address ? user?.address : "--"}
                                        </p>
                                    </td>
                                </Tooltip> */}

                                            <td className={`text-left text-sm text-[#1F2533] font-normal font-[Roboto-Regular] pr-3 `} onClick={() => showUser(user?.id)}>
                                                <Tooltip title={user?.location}>
                                                    <p className='w-[50%] truncate'>{user?.location ? user?.location : "--"}</p>
                                                </Tooltip>
                                            </td>
                                            <td className={`text-left  pr-4 text-[#1F2533] text-[14px] font-normal font-[Roboto-Regular`}>
                                                <Tooltip title={user?.creatorName !== null ? user?.creatorName : user?.creatorType}>
                                                    <p className='w-[50%] truncate capitalize'>
                                                        {user?.creatorName !== null ? user?.creatorName : user?.creatorType}&nbsp;
                                                        {user?.creatorType === "admin" ? <p>(Admin)</p> : user?.creatorType === "subAdmin" && <p>(Sub-admin)</p>}
                                                    </p>
                                                </Tooltip>
                                            </td>
                                            {/* <td className={`text-left pr-6 text-[14px] text-[#1F2533] font-normal font-[Roboto-Regular]`} onClick={() => showUser(user?.id)}>{user?.place ? user?.place : "--"}</td> */}
                                            <td className={`text-left pr-6 text-[14px] text-[#1F2533] font-normal font-[Roboto-Regular] relative`}>
                                                <div className='flex justify-between'>
                                                    <div className='flex items-center gap-2'>
                                                        <ToggleSwitch checked={user?.enabled === 1 ? true : false} onChange={() => toggleSwitchBtn(user?.id, user?.enabled)} />
                                                        {/* {user?.status === null ?
                                                            <> <ButtonField
                                                                buttonName={"Approve"}
                                                                loading={user?.id === rowId && props.buttonLoading}
                                                                buttonextracls={`!px-4 !py-2 w-9 h-7 capitalize !text-white bg-skyblue-6 text-xs hover:bg-deepBlue-2 hover:drop-shadow-xl`}
                                                                onClick={() => { props.approveUserApi(user?.id, 'approved'); setRowId(user?.id) }}
                                                            />
                                                                <ButtonField
                                                                    buttonName={"Decline"}
                                                                    buttonextracls={`!px-4 !py-2 w-9 h-7 mr-2 capitalize !text-white bg-red-500 text-xs hover:bg-red-800 hover:drop-shadow-xl`}
                                                                    onClick={() => handleclickForDecline(user?.id)}
                                                                />
                                                            </>
                                                            : 
                                                            <div className='w-full'></div>
                                                        } */}
                                                        <div className='w-20'></div>
                                                    </div>

                                                    <div className={`cursor-pointer p-1 w-[15px]`}
                                                        onClick={() => threeDotOptions(user?.id)}
                                                    >
                                                        <img src={optionMenuIcon} alt="option icon" className='text-start' width='4px' height='4px' />
                                                        {openMenu && rowId === user.id ?
                                                            <ClickAwayListener onClickAway={() => setOpenMenu(false)}>
                                                                <div className={`bg-white shadow-md  w-[10rem] h-[2.8rem] rounded absolute right-10 bottom-0 flex p-2 items-center z-50`}>
                                                                    <div className={`flex items-center justify-center gap-2 `}>
                                                                        <div className='flex gap-1' onClick={() => props.editUserForm(user)} >
                                                                            <img src={editIcon} alt="editIcon" width='12px' height='10px' />
                                                                            <span className={`text-xs text-gray-2 font-normal font-[Roboto-Regular]`}>Edit</span>
                                                                        </div>
                                                                        <div className={`border-l mx-4 border-solid border-gray-1 h-6`}></div>
                                                                        <div className='flex gap-1' onClick={() => handleDeleteClick(user?.id)}>
                                                                            <img src={deleteIcon} alt="deleteIcon" width='10px' height='10px' />
                                                                            <span className={`text-xs text-gray-2 font-normal font-[Roboto-Regular]`}>Delete</span>

                                                                        </div>
                                                                        <div className={`border-l mx-4 border-solid border-gray-1 h-6`}></div>
                                                                    </div>
                                                                </div>
                                                            </ClickAwayListener>
                                                            :
                                                            null
                                                        }
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })

                                }
                            </tbody>
                        </table>
                        :
                        <div className={`flex justify-center items-center h-[70vh]`}>
                            <NoContentPage
                                // text1={`Sorry`}
                                text2={`No Data Found`}
                            />
                        </div>
                    }
                </>
            }

            {open ?
                <form onSubmit={handleSubmit(onSubmit, reset)}>
                    <DeclinePopup open={open}
                        userId={userId}
                        handleClose={handleclickForDecline}
                        // handleChange={() => handleSubmit(onSubmit)}

                        DilogContenttext={
                            "Please enter comment to decline"
                        }
                        DialogContent={
                            <div>
                                <Controller
                                    name={"description"}
                                    control={control}
                                    rules={{
                                        pattern: /^[a-zA-Z][a-zA-Z ]*/i

                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <TextFieldInput
                                            textinputname={"description"}
                                            multiline={true}
                                            minRows={4}
                                            onlyValue
                                            textnewclass={`w-full text-sm`}
                                            placeholder='Description'
                                            value={(value)}
                                            onChange={onChange} />
                                    )}
                                />
                            </div>
                        }
                        DeleteBtn={"Yes, Decline"}
                        onClick={handleSubmit(onSubmit)}
                    />

                </form>
                :
                null
            }
            <ConfirmationModal
                isOpen={isModalOpen}
                onConfirm={handleConfirmDelete}
                onCancel={handleCancelDelete}
                message="Are you sure you want to delete this?"
            />
        </div>
    );
}
export default TotalUsersTable

