const ConfirmationModal = ({ isOpen, onConfirm, onCancel, message }) => {
    if (!isOpen) return null;
    return (
      <div className="fixed inset-0 z-50 bg-gray-500 bg-opacity-50 flex justify-center items-center">
        <div className="bg-white p-4 rounded shadow-lg w-1/3">
          <p className="text-lg mb-4">{message}</p>
          <div className="flex justify-end gap-4">
            <button 
              onClick={onCancel} 
              className="px-4 py-2 bg-gray-300 rounded"
            >
              Cancel
            </button>
            <button 
              onClick={onConfirm} 
              className="px-4 py-2 bg-indigo-600 text-white rounded"
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    );
  };
  export default ConfirmationModal