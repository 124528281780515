import { Modal, Box, IconButton } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CloseIcon from '@mui/icons-material/Close';


const ImagePreviewer = ({images, isModalOpen, setModalOpen, currentIndex, setCurrentIndex}) => {

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    };
  return (
    <Modal
    open={isModalOpen}
    onClose={handleCloseModal}
    sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }}
>
    <Box
        sx={{
            position: 'relative',
            width: '90%',
            maxWidth: '800px',
            maxHeight: '90vh', // Constrain modal to fit within the viewport
            overflow: 'auto', // Enable scrolling if content exceeds modal
            bgcolor: 'background.paper',
            boxShadow: 24,
            borderRadius: 2,
            p: 2,
        }}
    >
        {/* Close Button */}
        <IconButton
            sx={{
                position: 'absolute',
                top: 10,
                right: 10,
                zIndex: 1,
            }}
            onClick={handleCloseModal}
        >
            <CloseIcon />
        </IconButton>

        {/* Full Image */}
        {images && images?.length > 0 && images[currentIndex]?.key && (
            <img
                crossOrigin="anonymous"
                src={`${process.env.REACT_APP_DG_BASE_URL}/uploads/download/file?key=${images[currentIndex]?.key}`}
                alt="full-img"
                style={{
                    display: 'block',
                    maxWidth: '100%', // Scale down if width exceeds container
                    maxHeight: '60vh', // Constrain image height within viewport
                    objectFit: 'contain', // Maintain aspect ratio
                    margin: '0 auto', // Center image
                }}
            />
        )}

        {/* Navigation Buttons */}
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
                mt: 2,
            }}
        >
            <IconButton onClick={handlePrev}>
                <ArrowBackIosIcon />
            </IconButton>
            <IconButton onClick={handleNext}>
                <ArrowForwardIosIcon />
            </IconButton>
        </Box>
    </Box>
</Modal>
  )
}

export default ImagePreviewer