import React, { useState } from 'react';
import CheckBox from '../../../Common/FormFields/CheckBox';
import editIcon from '../../../Images/adminTable/editIcon.svg';
import deleteIcon from '../../../Images/adminTable/deleteIcon.svg';
import usericon from '../../../Images/adminTable/userAvatar.svg';
import optionMenuIcon from '../../../Images/adminTable/optionMenu.svg';
import ToggleSwitch from '../../../Common/FormFields/ToggleSwitch';
import { useSelector } from 'react-redux';
import ButtonField from '../../../Common/FormFields/ButtonField';
import { ClickAwayListener, CircularProgress, Tooltip, Modal } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import TextFieldInput from '../../../Common/FormFields/TextFieldInput';
import { Controller, useForm } from 'react-hook-form';
import DeclinePopup from '../../../Common/FormFields/DeclinePopup';
import NoContentPage from '../../../Common/Layout/NoContentPage/NoContentPage';
import Radio from '@mui/material/Radio';
import { IoClose } from "react-icons/io5";
import CorporatePlanPopup from '../../../Common/FormFields/CorporatePlanPopup';
import ConfirmationModal from '../../../Common/Popups/ConfirmationModal';

/**
 * @component TotalUsersTable
 * @description Users table in details
 * @param {*} props 
 */

const TotalVendorsTable = (props) => {

    const { handleSubmit, reset, control, formState: { errors } } = useForm();

    const [checked, setChecked] = useState(false);
    const onChange = () => setChecked(!checked);
    const totalVendorsData = useSelector((state) => state.totalVendorsInAdmin.totalVendors);
    const navigate = useNavigate();

    const [open, setOpen] = useState(false);
    const [openMenu, setOpenMenu] = useState(false);
    const [rowId, setRowId] = useState(false);
    const [declineData, setDeclineData] = useState();
    const [userID, setUserId] = useState("");

    // State for modal
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedVendor, setSelectedVendor] = useState(null);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [itemToDelete, setItemToDelete] = useState(null);

    const handleDeleteClick = (id) => {
        setItemToDelete(id);
        setIsModalOpen(true);
    };

    const handleConfirmDelete = () => {
        if (itemToDelete) {
            props.userEnableDisableApi(itemToDelete);
            setIsModalOpen(false);
        }
    };

    const handleCancelDelete = () => {
        setIsModalOpen(false);
        setItemToDelete(null)
    };

    const handleclickForDecline = (userID) => {
        setOpen(!open);
        setUserId(userID);
    }

    const threeDotOptionsForVendor = (idx) => {
        setOpenMenu(!openMenu);
        setRowId(idx);
    }

    const showVendorDetails = (userId, busId) => {
        navigate(`/admin/totalVendors/${userId}`);
    }

    const onSubmit = (data) => {
        setDeclineData(data);
        props.approveUserApi(userID, 'denied', data);
        setOpen(!open);
        reset(declineData);
    }

    const toggleSwitchBtn = (userId, enableVal) => {
        let toggleVal = enableVal === 1 ? 2 : 1;
        props.userEnableDisableApi(userId, toggleVal, 'disable');
    }

    const tableheade = `text-[#585F71] text-left text-base font-normal font-[Roboto-Regular]`;

    const handleApplyPlanClick = (vendor) => {
        setSelectedVendor(vendor);
        setModalOpen(true);
    }

    const handleModalClose = () => {
        setModalOpen(false);
        setSelectedVendor(null);
    }

    const handlePlanSubmit = (event) => {
        event.preventDefault();
        // Add logic to handle plan submission
        handleModalClose();
    }



    return (
        <div className='w-full h-[70vh] border-[#EBEBEB]'>
            {props.loading === true ?
                <div className='flex h-full justify-center items-center'>
                    <CircularProgress size={20} color='success' />
                    <p className='pl-3'>Loading...</p>
                </div>
                :
                <>
                    {totalVendorsData && totalVendorsData?.length > 0 ?
                        <div className="overflow-x-auto">
                            <table className="table-auto w-[110vw]">
                                <thead className='bg-white sticky top-0 z-10'>
                                    <tr className='h-10'>
                                        <th className={`${tableheade} w-[10%] pl-5`}>User Name</th>
                                        <th className={`${tableheade} w-[15%]`}>Unique Id</th>
                                        <th className={`${tableheade} w-[10%] pr-2`}>Phone No.</th>
                                        <th className={`${tableheade} w-[10%] `}>Email id</th>
                                        <th className={`${tableheade} w-[10%] pr-6`}>Address</th>
                                        <th className={`${tableheade} w-[10%] pr-6 `}>Governorate</th>
                                        <th className={`${tableheade} w-[10%] pr-8 `}>Place</th>
                                        <th className={`${tableheade} w-[5%] `}>Created by</th>
                                        <th className={`${tableheade} w-[10%] `}>Corporate Plan</th>
                                        {/* <th className={`${tableheade} w-[10%] pl-9`}>Expiry Date</th> */}
                                        <th className={`${tableheade} w-[10%] pl-8`}>Active/Inactive</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {totalVendorsData?.map((vendor, idx) => {
                                        return (
                                            <tr
                                                key={idx}
                                                className={`drop-shadow-md border-t-2 border-b-2  border-[#EBEBEB] cursor-pointer h-16 ${vendor?.tag === 'new' && 'bg-lime-200'}`}
                                            >
                                                <td className={`flex items-center pl-5 h-16 ${vendor?.tag === 'new' && 'relative'} `} onClick={() => showVendorDetails(vendor?.id, vendor?.business[0]?.id)}>
                                                    {vendor?.tag === 'new' &&
                                                        <p className={`absolute top-1 left-1 text-sm font-[RobotoMedium] -rotate-45 text-seconderyColour-5`}>New</p>
                                                    }
                                                    <img
                                                        crossOrigin='anonymous'
                                                        src={(vendor?.business && vendor?.business[0]?.logo && vendor?.business[0]?.logo?.key) ? `${process.env.REACT_APP_DG_BASE_URL}/uploads/download/file?key=${vendor?.business[0]?.logo?.key}&height=42&width=42` : usericon}
                                                        alt="vendoricon"
                                                        height={42}
                                                        width={42}
                                                        className={`${vendor?.business[0]?.logo?.key && 'rounded-full border'}`}
                                                    />
                                                    <Tooltip title={vendor?.name}>
                                                        <p className={`text-left text-[#1F2533] text-sm pl-2 font-medium font-[Roboto-Regular] truncate`}>
                                                            {vendor?.name ? vendor?.name : "--"}
                                                        </p>
                                                    </Tooltip>
                                                </td>

                                                <td className={`text-left text-[14px] font-normal font-[Roboto-Regular]`} onClick={() => showVendorDetails(vendor?.id, vendor?.business[0]?.id)}>{vendor?.uniqueId}</td>

                                                <td className={`text-left text-[14px] font-normal font-[Roboto-Regular]`} onClick={() => showVendorDetails(vendor?.id, vendor?.business[0]?.id)}>
                                                    <Tooltip placement='bottom-start' title={vendor?.phoneNo}>
                                                        <p className='w-[8em] truncate'>{vendor?.phoneNo}</p>
                                                    </Tooltip>
                                                </td>

                                                <td className={`text-left text-[#1F2533] text-[14px] font-normal font-[Roboto-Regular] pr-2`} onClick={() => showVendorDetails(vendor?.id, vendor?.business[0]?.id)}>
                                                    <Tooltip title={vendor?.email}>
                                                        <p className='w-[8em] truncate'>{vendor?.email}</p>
                                                    </Tooltip>
                                                </td>

                                                <td className={`text-left text-[14px] text-[#1F2533] font-normal font-[Roboto-Regular] pr-4`} onClick={() => showVendorDetails(vendor?.id, vendor?.business[0]?.id)}>
                                                    <Tooltip placement='bottom-start' title={vendor?.business[0]?.address}>
                                                        <p className='w-[10em] truncate'>
                                                            {vendor?.business[0]?.address ? vendor?.business[0]?.address : '--'}
                                                        </p>
                                                    </Tooltip>
                                                </td>

                                                <td className={` text-left text-sm text-[#1F2533] font-normal font-[Roboto-Regular]`} onClick={() => showVendorDetails(vendor?.id, vendor?.business[0]?.id)}>
                                                    <Tooltip title={vendor?.business[0]?.governorate}>
                                                        <p className='w-[50%] truncate'>{vendor?.business[0]?.governorate ? vendor?.business[0]?.governorate : '--'}</p>
                                                    </Tooltip>
                                                </td>

                                                <td className={`text-left  text-[14px] text-[#1F2533] font-normal font-[Roboto-Regular]`} onClick={() => showVendorDetails(vendor?.id, vendor?.business[0]?.id)}>
                                                    <Tooltip placement='bottom-start' title={vendor?.business[0]?.places}>
                                                        <p className='w-[8em] truncate'>{vendor?.business[0]?.places ? vendor?.business[0]?.places : '--'}</p>
                                                    </Tooltip>
                                                </td>

                                                <td className={`text-left  pr-4 text-[#1F2533] text-[14px] font-normal font-[Roboto-Regular`} onClick={() => showVendorDetails(vendor?.id, vendor?.business[0]?.id)}>
                                                    <Tooltip placement='bottom-start' title={vendor?.creatorType === "admin" ? "Admin" : vendor?.creatorType === "subAdmin" ? "Sub-admin" : vendor?.creatorName}>
                                                        <p className={`text-[14px] truncate capitalize`}>
                                                            {vendor?.creatorName ? vendor?.creatorName : vendor?.creatorType}&nbsp;
                                                            {vendor?.creatorType === "admin" ? <p>(Admin)</p> : vendor?.creatorType === "subAdmin" && <p>(Sub-admin)</p>}
                                                        </p>
                                                    </Tooltip>
                                                </td>

                                                <td className={`text-left text-[14px] text-[#1F2533] font-normal font-[Roboto-Regular] `}>
                                                    {vendor?.subscription?.plans ?
                                                        <Tooltip placement='bottom-start' title={vendor?.subscription?.plans?.name}>
                                                            <p className='w-[8em] truncate'>{vendor?.subscription?.plans?.name}</p>
                                                        </Tooltip>
                                                        :
                                                        <ButtonField
                                                            buttonName={"Add Plan"}
                                                            buttonextracls={`!px-1 !py-4 w-full h-7 capitalize !text-white bg-green-500 text-xs hover:bg-green-700 hover:drop-shadow-xl`}
                                                            onClick={() => handleApplyPlanClick(vendor)}
                                                        />
                                                    }

                                                </td>

                                                {/* <td className={`text-left text-[14px] pl-9 text-[#1F2533] font-normal font-[Roboto-Regular] `}>
                                                    <p>Date</p>
                                                </td> */}

                                                <td className={`text-left pr-6 text-[14px] text-[#1F2533] font-normal font-[Roboto-Regular] pl-8`}>
                                                    <div className='flex justify-between'>
                                                        <div className='flex items-center gap-2'>
                                                            <ToggleSwitch onClick={onChange} checked={vendor?.enabled === 1 ? true : false} onChange={() => toggleSwitchBtn(vendor?.id, vendor?.enabled)} />
                                                            {vendor?.isVerified === 0 ?
                                                                <div className='flex'>
                                                                    <ButtonField
                                                                        buttonName={"Approve"}
                                                                        loading={vendor?.id === rowId && props.buttonLoading}
                                                                        buttonextracls={`!px-4 !py-2 w-9 h-7 capitalize !text-white bg-skyblue-6 text-xs hover:bg-deepBlue-2 hover:drop-shadow-xl mr-2`}
                                                                        onClick={() => { props.approveUserApi(vendor?.id, 'approved'); setRowId(vendor?.id) }}
                                                                    />
                                                                    <ButtonField
                                                                        buttonName={"Decline"}
                                                                        buttonextracls={`!px-4 !py-2 w-9 h-7 mr-2 capitalize !text-white bg-red-500 text-xs hover:bg-red-800 hover:drop-shadow-xl`}
                                                                        onClick={() => handleclickForDecline(vendor?.id)}
                                                                    />
                                                                </div>
                                                                : <div className='w-full'></div>}
                                                        </div>
                                                        <div className={`cursor-pointer p-1 w-[15px]`}
                                                            onClick={() => threeDotOptionsForVendor(vendor?.id)}
                                                        >
                                                            <img src={optionMenuIcon} alt="option icon" className='text-start' />
                                                            {openMenu && rowId === vendor.id ?
                                                                <ClickAwayListener onClickAway={() => setOpenMenu(false)}>
                                                                    <div className={`bg-white shadow-md  w-[10rem] h-[2.8rem] rounded absolute right-10 bottom-0 flex p-2 items-center z-50`}>
                                                                        <div className={`flex items-center justify-center gap-2 `}>
                                                                            <div className='flex gap-1' onClick={() => props.handleeditclick(vendor)} >
                                                                                <img src={editIcon} alt="editIcon" width='12px' height='10px' />
                                                                                <span className={`text-xs text-gray-2 font-normal font-[Roboto-Regular]`}>Edit</span>
                                                                            </div>
                                                                            <div className={`border-l mx-4 border-solid border-gray-1 h-6`}></div>
                                                                            <div className='flex gap-1' onClick={() => handleDeleteClick(vendor?.id)}>
                                                                                <img src={deleteIcon} alt="deleteIcon" width='10px' height='10px' />
                                                                                <span className={`text-xs text-gray-2 font-normal font-[Roboto-Regular]`}>Delete</span>
                                                                            </div>
                                                                            <div className={`border-l mx-4 border-solid border-gray-1 h-6`}></div>
                                                                        </div>
                                                                    </div>
                                                                </ClickAwayListener>
                                                                :
                                                                null
                                                            }

                                                        </div>

                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                        :
                        <div className={`flex justify-center items-center h-[70vh]`}>
                            <NoContentPage
                                text2={`No Data Found`}
                            />
                        </div>
                    }
                </>
            }

            {
                open ?
                    <form onSubmit={handleSubmit(onSubmit, reset)}>
                        <DeclinePopup open={open}
                            userID={userID}
                            handleClose={handleclickForDecline}
                            DilogContenttext={"Please enter comment to decline"}
                            DialogContent={
                                <div>
                                    <Controller
                                        name={"description"}
                                        control={control}
                                        rules={{
                                            pattern: /^[a-zA-Z][a-zA-Z ]*/i
                                        }}
                                        render={({ field: { onChange, value } }) => (
                                            <TextFieldInput
                                                textinputname={"description"}
                                                multiline={true}
                                                minRows={4}
                                                onlyValue
                                                textnewclass={`w-full text-sm`}
                                                placeholder='Description'
                                                value={(value)}
                                                onChange={onChange} />
                                        )}
                                    />
                                </div>
                            }
                            DeleteBtn={"Yes, Decline"}
                            onClick={handleSubmit(onSubmit)}
                        />
                    </form>
                    :
                    null
            }

            {/* Modal for Apply Plan */}
            {modalOpen && (
                <CorporatePlanPopup
                    open={modalOpen}
                    onClose={handleModalClose}
                    planSubmit={handlePlanSubmit}
                    vendor={selectedVendor}
                    selectPlan={selectedPlan}
                    setPlan={setSelectedPlan}
                    createCorporateSubscription={props.createCorporateSubscription} />
            )}
   <ConfirmationModal
                isOpen={isModalOpen}
                onConfirm={handleConfirmDelete}
                onCancel={handleCancelDelete}
                message="Are you sure you want to delete this?"
            />
        </div>
    )
}

export default TotalVendorsTable;