import React, { useState } from 'react'
import PropTypes from 'prop-types';
import UploadDocument from '../../UiComponents/UploadDocument';
import { useTranslation } from 'react-i18next';
import ImagePreviewer from '../../UiComponents/ImagePreviewer';
// import { ForVendor } from '../../../Containers/VendorDetailPage/Hooks';

const ProfileUserImagesCard = (props) => {
  // const { getThumbnailMedia } = ForVendor()
  const [isModalOpen, setModalOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleOpenModal = (index) => {
    setCurrentIndex(index);
    setModalOpen(true);
  };


  const { t } = useTranslation()

  return (
    <div className={`!bg-white p-5 border flex-col justify-between gap-4`}>

      {/* Images section including its title */}
      <div>
        <p className={`font-[Roboto-Medium] text-sm text-deepgray-4 mb-4`}>{t("images")}</p>
        <div className={`grid grid-rows-1 grid-flow-col gap-2`}>
          {
            props.imgArr && props.imgArr?.length > 0 && props.imgArr?.slice(0, 4).map((item, id) => {
              return (
                <div className='cursor-pointer' key={id} onClick={() => handleOpenModal(id)} >
                  {item?.key &&
                    // <img crossorigin="anonymous" src={()=>getThumbnailMedia(item?.key, 50, 50)} alt="img" /> 
                    <img  crossOrigin="anonymous" src={`${process.env.REACT_APP_DG_BASE_URL}/uploads/download/file?key=${item?.key}&height=60&width=60`} alt="img" />
                  }
                </div>
              )
            })
          }
        </div>
      </div>

      {/* pdf section including its title  */}
      <div>
        <p className={`font-[Roboto-Medium] text-sm text-deepgray-4 my-4`}>{t("civilid")}</p>
        <UploadDocument
          uploadbutton={true}
          extracls={`!justify-between`}
          pdfname={props.dataObject?.civilDocument?.name ? props.dataObject?.civilDocument?.name : `No document`}
          view={true}
          viewid={"View ID"}
          kb={props.dataObject?.civilDocument?.size ? `${(props.dataObject?.civilDocument?.size / 1024).toFixed(2)} kb` : "0kb"}
          onDownloadClick={() =>  props?.downloadPdfAttachments(props.dataObject?.civilDocument)}
        />
      </div>
      <ImagePreviewer
        isModalOpen={isModalOpen}
        setModalOpen={setModalOpen}
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
        images={props.imgArr}
      />
    </div>
  )
}

ProfileUserImagesCard.propTypes = {
  imgArr: PropTypes.array,

}

export default ProfileUserImagesCard