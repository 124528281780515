import React, { useEffect } from 'react'
import CommonCard from '../../Common/Layout/Card/CommonCard'
import img1 from '../../Images/Vendor/offerimg1.png'
import img2 from '../../Images/Vendor/offerimg2.png'
import img3 from '../../Images/Vendor/offerimg3.png'
import contactvendorimg from '../../Images/Vendor/contactvendorimage.svg'
import trackicon from '../../Images/Vendor/trackicon.svg'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import profileUserDetailsIcon from '../../Images/profileUserCardImages/profileUserDetailsIcon.png'
import { useNavigate } from 'react-router-dom'
import { ForLanding } from '../../Containers/LandingPage/Hooks'
import SnackBarComponent from '../../Common/Layout/Snackbar/SnackBar'

const VendorNearYou = (props) => {
    const vendorDetails = useSelector((state)=> state.vendorAccount.businessDetails)
    const { t, i18n } = useTranslation()
    const { createFavVendorApiCall, getFavVendorApiCall, notification, messageClose } = ForLanding()
    const userId = localStorage.getItem('userId')
    const token = localStorage.getItem('token')
    const role = localStorage.getItem('role')
    const navigate = useNavigate()

    const favVendorData = useSelector((state) => state.landingData.favVendors)
    const VendorDataDetails = vendorDetails.slice(0,4)

    const itemContents = [
        {
          image: img1
        },
        {
          image: img2
        },
        {
          image: img3
        },
        {
          image: img3
        }
      ]

      useEffect(() => {
        if (token && userId) {
          getFavVendorApiCall(userId)
        }
      }, [userId])

      
    return (
        <div className={`p-[4%] bg-white-5`}>
        <div className={`flex  flex-row justify-between items-center pb-[3%]`}>
                <p className={`text-left text-3xl font-[Roboto-Regular] font-normal text-deepgray-5 pb-2 lg:pb-0`}>{t("vendorsnearyou")}</p>
                <p className={`text-left text-base font-[Roboto-Regular] font-normal text-blue-5 cursor-pointer`} onClick={props.handleClickForBusinessSeeAll} >{t("seeall")} {'>'} </p>
            </div>
        <div className={`grid gap-2 md:gap-5 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 grid-rows-1`}>
                {VendorDataDetails.map((item, idx) =>
                    <div key={idx} className={`w-full`}>
                        <CommonCard
                            carousel={item?.thumbnail && item?.thumbnail?.length > 0 ? true : false}
                            noContentImg={item?.thumbnail && item?.thumbnail?.length === 0 && true}
                            carouselimg={item?.thumbnail && item?.thumbnail?.length > 0 ? item?.thumbnail : itemContents}
                            itemname={item.user?.name ? item.user?.name : "--"}
                            onHandelOpeModal={() => props.onHandelOpeModal(item?.user?.id)}
                            Shareicon={true}
                            height={false}
                            rating={false}
                            profile={true}
                            profileicon={item?.logo?.key !== null && item?.logo?.key}
                            itememail={true}
                            email={item?.user?.email}
                            phone={true}
                            phonetext={item.user?.phoneNo ? item.user?.phoneNo : "--"}
                            location={true}
                            locationtext={(item?.lat !== null && item?.long !== null) && item.location ? item.location : "--"}
                            distance={(item?.lat === null && item?.long === null) ? false : true}
                            // distancetext={item.distance ? item.distance : "--"}
                            item={item}
                            carouselHeight="150px"
                            noContentCls={`h-[150px]`}
                            extracls={`min-h-[30rem]`}
                            itemtext={true}
                            text1={item?.name}
                            hoverbutton={item?.userId !== parseInt(localStorage.getItem('userId')) ? true : false}
                            hoverbuttonTrack={(item?.lat === null && item?.long === null) ? false : true}
                            showLikeIcon={item?.userId !== parseInt(userId) && true}
                            favValue={favVendorData?.some((fav) => fav?.business?.user?.id === item?.userId) && 1}
                            buttonName2={"Contact Vendor"}
                            buttonimg={contactvendorimg}
                            buttonName3={"Track"}
                            buttonimg2={trackicon}
                            description={item?.description}
                            onChange={() => {
                              (token === null || role === 'admin') ? navigate('/login') : createFavVendorApiCall(item?.id, parseInt(localStorage.getItem('userId')))
                            }}
                        />
                    </div>
                )}

            </div>
            <SnackBarComponent
              messageOpen={notification.open}
              messageClose={messageClose}
              notificationText={notification.message}
              subText={notification.subText}
              alertType={notification.alertType}
              borderClass={notification.borderClass}
            />  

        </div>
    )
}

export default VendorNearYou