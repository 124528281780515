import React from 'react';
import moment from 'moment';
import ButtonField from '../../../Common/FormFields/ButtonField';

function SubscriptionDetail({ data }) {
    const { status, serviceOffered, requirements, offers, createdAt, updatedAt, expireIn, user, userId, payment, plans, addOns } = data;

    let addOnStr = 'N/A';


    if(addOns?.length){
        const tempArr = []
        addOns.forEach(element => {
            tempArr.push(`Name: ${element?.plans?.name} Price: ${element?.plans?.price} ${element?.plans?.currency}`  )
        });
        addOnStr = tempArr.join(', ')
    }

    return (
        <div className='w-full flex flex-col gap-2'>

            <div className='p-2 border rounded'>
                <h2 className='font-bold text-lg'>Status</h2>
                <div className='w-full flex gap-4 flex-wrap pt-1'>
                    <ButtonField
                        buttonName={status}
                        buttonextracls={status === 'expire' ? `!px-3 !py-1 !text-white bg-[#FF6685] text-[12px] font-[Roboto-Regular] w-[6rem] rounded-full` : status === 'Expiring' ? `!px-3 !py-1 !text-white bg-[#FBCB00]  text-[12px] font-[Roboto-Regular] w-[6rem] rounded-full` : status === 'active' ? `!px-3 !py-1 !text-white bg-[#1DBF73]  text-[12px] font-[Roboto-Regular] w-[6rem] rounded-full` : null}
                    />
                    <p> <span className='font-semibold' >Expiry Date : </span> {moment(expireIn).format("YYYY-MM-DD")}</p>
                    <p> <span className='font-semibold' >Requirements: </span>{requirements}</p>
                    <p> <span className='font-semibold' >Services Offered: </span> {serviceOffered}</p>
                    <p> <span className='font-semibold' >Offers: </span> {offers}</p>
                    <p> <span className='font-semibold' >Created At: </span> {moment(createdAt).format("YYYY-MM-DD")}</p>
                </div>
            </div>

            <div className='p-2 border rounded'>
                <h2 className='font-bold text-lg'>User Details</h2>
                <div className='w-full flex justify-between flex-wrap pt-1'>
                    <p>Name: {user[0]?.name || 'N/A'}</p>
                    <p>Email: {user[0]?.email || 'N/A'}</p>
                    <p>Phone: {user[0]?.phoneNo || 'N/A'}</p>
                    <p>Role: {user[0]?.role || 'N/A'}</p>
                    <p>Type: {user[0]?.type || 'N/A'}</p>
                    <p>UniqueId: {user[0]?.uniqueId || 'N/A'}</p>
                </div>
            </div>
            <div className='p-2 border rounded'>
                <h2 className='font-bold text-lg'>Payment Details</h2>
                <div className='w-full flex gap-4 flex-wrap pt-1'>
                    <p> <span className='font-semibold' > Amount: </span> {payment?.amount} {payment?.currency}</p>
                    <p> <span className='font-semibold' > Payment Id: </span> {payment?.paymentId || 'N/A'}</p>
                    <p> <span className='font-semibold' > Transaction Id:</span>  {payment?.transactionId || 'N/A'}</p>
                    <p> <span className='font-semibold' > Track Id:</span>  {payment?.trackId || 'N/A'}</p>
                    <p> <span className='font-semibold' > Status: </span> {payment?.status || 'N/A'}</p>
                    <p> <span className='font-semibold' > Type:</span>  {payment?.type || 'N/A'}</p>
                    <p> <span className='font-semibold' > Result: </span> {payment?.resultCode || 'N/A'}</p>
                    <p> <span className='font-semibold' > Payment Time:</span>  {moment(payment.createdAt).format("YYYY-MM-DD")}</p>
                </div>
            </div>

            <div className='p-2 border rounded'>
                <h2 className='font-bold text-lg'>Plan Details</h2>
                <div className='w-full flex gap-4 flex-wrap pt-1'>
                    <p> <span className='font-semibold' > Name: </span> {plans?.name}</p>
                    <p> <span className='font-semibold' > Phone Limit: </span> {plans?.photoLimit|| 'N/A'}</p>
                    <p> <span className='font-semibold' > Monthly Post Count:</span>  {plans?.monthlyPostRequirements?.count || 'N/A'}</p>
                    <p> <span className='font-semibold' > Monthly Post Change Limit:</span>  {plans?.monthlyPostRequirements?.monthlyChangeLimit || 'N/A'}</p>
                    <p> <span className='font-semibold' > Monthly Photo Change Limit:</span>  {plans?.monthlyPostRequirements?.photoLimit || 'N/A'}</p>
                    <p> <span className='font-semibold' > Video Duration:</span>  {plans?.videoDuration}</p>
                    <p> <span className='font-semibold' > Video Limit:</span>  {plans?.videoLimit}</p>
                    <p> <span className='font-semibold' > Service Duration:</span>  {plans?.durationInDays|| 'N/A'}</p>
                    <p> <span className='font-semibold' > Free Duration:</span>  {plans?.freeDuration}</p>
                    <p> <span className='font-semibold' > Type:</span>  {plans?.planType || 'N/A'}</p>
                    <p> <span className='font-semibold' > Price:</span>  {plans?.price || 'N/A'} {plans?.currency}</p>
                    <p> <span className='font-semibold' > AddOns:</span> {addOnStr} </p>
                    
                </div>
            </div>


        </div>
    )
}

export default SubscriptionDetail