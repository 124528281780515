import React, { useState } from 'react'
import ButtonField from '../../FormFields/ButtonField'
import text from '../../Languages/en_US.json'
import { Link } from 'react-router-dom'
import { CircularProgress, Tooltip } from '@mui/material'
import { AiOutlineDelete } from 'react-icons/ai';
import DOMPurify from 'dompurify';
const CommonPrivacyAndPolicy = (props) => {

    const role = localStorage.getItem('role')

    return (
        <>
            <div className={`px-[2%] py-[2%] bg-white`}>
                <div className={`flex justify-between`}>
                    <p className={`text-deepgray-4 font-[Roboto-Bold] text-3xl pb-[3%]`}>{text.privacypolicy}</p>
                    {(role === 'admin' || role === 'subAdmin') &&
                        <ButtonField
                            buttonName={`Add New`}
                            buttonextracls={`!px-4 !py-2 !text-white bg-skyblue-6 text-sm hover:bg-deepBlue-2 hover:drop-shadow-xl`}
                            onClick={props.handleTextEditor}
                        />
                    }
                </div>
                {props.loading === true ?
                    <div className='flex h-[60vh] justify-center items-center'>
                        <CircularProgress size={20} color='success' />
                        <p className='pl-3'>Loading...</p>
                    </div>
                    :
                    <div className={`flex justify-between `}>
                        <div className={`w-[65%] h-[60vh] overflow-y-scroll ${props.privPolContentCls}`}>
                            {(props.privacyDetails && Object.keys(props.privacyDetails)?.length > 0) ?
                                <div>
                                    <p className='text-deepgray-4 font-[Roboto-Bold] text-xl'>{props.privacyDetails.heading}</p>
                                    <p className='text-deepgrey-3' >{props.privacyDetails.answers}</p>
                                </div> :
                                <div>
                                    {
                                        props.allPrivPol.map((item, i) => (
                                            <div key={i} className='mb-4'>
                                                <p className='text-deepgray-4 font-[Roboto-Bold] text-xl'
                                                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item?.heading) }}></p>
                                                <p className='text-deepgrey-3'
                                                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item?.answers) }}></p>
                                            </div>
                                        ))
                                    }
                                </div>
                            }


                        </div>
                        <div className={`border border-gray-3 rounded-md px-[2%] pt-[2%] pb-[4%] w-[25%] max-h-[50vh] overflow-y-scroll`}>
                            <p className={`text-deepgrey-3 font-[Roboto-Medium] text-lg pb-[5%]`}>{`Table of Contents:`}</p>
                            {props.allPrivPol?.map((item, index) => (
                                <div key={index} className="flex items-center justify-between my-4">
                                    <Link to={''} className="flex-1">
                                        <p
                                            className={`text-skyblue-6 font-[Roboto-Regular] text-[15px]`}
                                            onClick={() => props.getFaqDetailsApi(item?.id)}
                                        >
                                            {item?.heading}
                                        </p>
                                    </Link>
                                    {(role === 'admin' || role === 'subAdmin') && (<Tooltip title="Delete">
                                        <AiOutlineDelete
                                            className="text-red-500 cursor-pointer ml-2"
                                            onClick={() => props.deletePrivacy(item?.id)}
                                        />
                                    </Tooltip>)}
                                </div>
                            ))}
                        </div>
                    </div>
                }
            </div>
            {/* : */}


        </>
    )
}

export default CommonPrivacyAndPolicy