import React from 'react'
import MainHeader from '../MainHheader/MainHeader'
import LandingHeader from '../LandingHeader/LandingHeader'
import BabyCareShowImg from '../BabyCare/BabyCareShowImg'
import { useParams } from 'react-router-dom'
import ServiceDetailsStyles from './WriteAReview.module.css'
import { useEffect } from 'react'
import { ForAdmin } from '../../../Containers/Admin/Hooks'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { Chip } from '@mui/material'
import { priceAfterDiscount } from '../../../Utils/CommonFunc'
import { useTranslation } from 'react-i18next'

const ServiceDetails = (props) => {

    const { getServicesbyServiceIdApiCall, } = ForAdmin();
    const {t} = useTranslation()
    const userId = useParams()
    const serviceData = useSelector((state) => state.totalVendorsInAdmin.servicesByServiceId)

    useEffect(() => {
        getServicesbyServiceIdApiCall(userId.id);
    }, [])

   


    return (
        <div className={``}>
            <MainHeader />
            <LandingHeader
                searchBar={true}
                goTomessage={true}
                goToNotification={true}
                border={true}
                profile={true}
            />


            {/* This is the main div of this component  */}
            <div className={` pl-[4.6%] pr-[5%] `}>
                <div className={`w-full flex justify-between items-center`}>
                    <p className={`py-8 text-deepgray-5 text-2xl font-[Roboto-Bold]`}>{serviceData?.name}
                        {/* <span className={`font-[Roboto-Regular]`}>- 500 Items</span> */}
                    </p>
                </div>
                <div className={`${ServiceDetailsStyles.containerResponsive}`}>
                    <div className={`${ServiceDetailsStyles.BabyCareShowImg}`}>
                        <div className={`flex flex-col gap-5`}>
                            {serviceData?.thumbnail?.length> 0 ? <BabyCareShowImg
                                imgArr={serviceData?.thumbnail}
                                // likeIcon={false}
                                smallImgsDivCls={`flex flex-col gap-5`}
                                bigImgDivCls={`relative flex justify-center w-[65%] min-h-[300px]`}
                            /> : <div className={`w-full h-[15em] flex justify-center items-center border rounded border-slate-600`}>
                                <p className={`text-[2em]`}>
                                    No Thumbnails Available
                                </p>
                            </div>}
                            <div className={`border border-slate-500`}/>
                            <div className={`flex flex-col gap-5`}>
                                <p className={` text-black text-lg font-[Roboto-Regular]`}>{`Offers:`}</p>
                                {serviceData.offer?.length > 0 ? <div className={`flex flex-col gap-3`}>
                                    <div className={`flex gap-5`}>
                                        <p className={`w-1/3 text-black text-sm font-[Roboto-Regular]`}>{`Offer Type:`}</p>
                                        <p className={`w-2/3 text-black text-sm font-[Roboto-Regular] capitalize`}>{`${serviceData?.offer?.[0].type ? serviceData?.offer?.[0].type : 'Data not Found'}`}</p>
                                    </div>
                                    <div className={`flex gap-5`}>
                                        <p className={`w-1/3 text-black text-sm font-[Roboto-Regular]`}>{`${serviceData?.offer?.[0]?.percentage ? 'Percentage' : serviceData?.offer?.[0]?.price ? 'Price' : ''}`}</p>
                                        <p className={`w-2/3 text-black text-sm font-[Roboto-Regular]`}>{`${serviceData?.offer?.[0]?.percentage ? `${serviceData?.offer?.[0]?.percentage} %` : serviceData?.offer?.[0]?.price ? `$${serviceData?.offer?.[0]?.price}` : `Data not found`}`}</p>
                                    </div>
                                    <div className={`flex gap-5`}>
                                        <p className={`w-1/3 text-black text-sm font-[Roboto-Regular]`}>{`Offer Details:`}</p>
                                        <p className={`w-2/3 text-black text-sm font-[Roboto-Regular]`}>{`${serviceData?.offer?.[0]?.detail ? serviceData?.offer?.[0]?.detail : 'Data Not Found'}`}</p>
                                    </div>
                                    <div className={`flex gap-5`}>
                                        <p className={`w-1/3 text-black text-sm font-[Roboto-Regular]`}>{`Offer Timing:`}</p>
                                        <p className={`w-2/3 text-black text-sm font-[Roboto-Regular]`}>{`${serviceData?.offer?.[0]?.offerTiming? moment(serviceData?.offer?.[0]?.offerTiming).format('LLLL') : 'Data not Found'}`}</p>
                                    </div>
                                    <div className={`flex gap-5`}>
                                        <p className={`w-1/3 text-black text-sm font-[Roboto-Regular]`}>{`Open:`}</p>
                                        <p className={`w-2/3 text-black text-sm font-[Roboto-Regular]`}>{`${serviceData?.offer?.[0]?.startTime ? moment(serviceData?.offer?.[0]?.startTime).format('LLLL') : 'Date not found'}`}</p>
                                    </div>
                                    <div className={`flex gap-5`}>
                                        <p className={`w-1/3 text-black text-sm font-[Roboto-Regular]`}>{`Close:`}</p>
                                        <p className={`w-2/3 text-black text-sm font-[Roboto-Regular]`}>{`${serviceData?.offer?.[0]?.endTime ? moment(serviceData?.offer?.[0]?.endTime).format('LLLL') : 'Date not found'}`}</p>
                                    </div>
                                </div> :
                                    <div>
                                        No offers Available
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className={`${ServiceDetailsStyles.businessDetails}`}>

                        {/* This below div is entirely for the heading along with sharing the details below the heading  */}
                            <div className={`flex flex-col gap-8`}>
                                <div className={`flex gap-5`}>
                                    <p className={`w-1/3 text-black text-sm font-[Roboto-Regular]`}>{`Service Name:`}</p>
                                <p className={`w-2/3 text-grey-600 text-sm`}>{serviceData?.name ? serviceData?.name : 'Data not found'}</p>
                                </div>
                                <div className={`flex gap-5`}>
                                    <p className={`w-1/3 text-black text-sm font-[Roboto-Regular]`}>{`Description:`}</p>
                                <p className={`w-2/3 text-grey-600 text-sm`}>{serviceData?.description ? serviceData?.description : 'Data not found'}</p>
                                </div>
                                <div className={`flex gap-5`}>
                                    <p className={`w-1/3 text-black text-sm font-[Roboto-Regular]`}>{`TimeFrame:`}</p>
                                <p className={`w-2/3 text-Active100 text-sm`}>{serviceData?.timeFrame ? serviceData?.timeFrame : 'Data not found'}</p>
                                </div>
                                <div className={`flex gap-5`}>
                                    <p className={`w-1/3 text-black text-sm font-[Roboto-Regular]`}>{`Service Amount:`}</p>
                                    <div className={`w-2/3 flex gap-2`}>
                                    <p className={`text-grey-600 text-base font-[Roboto-Medium] line-through`}>{serviceData?.amount ? serviceData?.amount : 'Data not found'}&nbsp;{t(`KD`)}</p>
                                    {serviceData?.offer?.[0]?.percentage ?
                                        <p className={`font-[Roboto-Medium]`}>{priceAfterDiscount(serviceData.amount, null, serviceData?.offer?.[0]?.percentage)}&nbsp;{t(`KD`)}</p>
                                        :
                                        serviceData?.offer?.[0]?.price &&
                                        <p className={`font-[Roboto-Medium]`}>{priceAfterDiscount(serviceData.amount, serviceData?.offer?.[0]?.price, null)}&nbsp;{t(`KD`)}</p>
                                    }
                                    </div>
                                </div>
                                
                                <div className={`flex flex-col gap-5`}>
                                    <p className={`w-1/3 text-black text-sm font-[Roboto-Regular]`}>{`Category:`}</p>
                                    <div className={`flex gap-2 flex-wrap`}>
                                    {
                                    serviceData?.category?.length > 0 ? serviceData.category?.map((item, idx) => <div key={idx}>
                                            <Chip label={`${item?.name}`} color={'primary'} />
                                        </div>)
                                            : 'Data not found'
                                    }
                                    </div>
                                </div>
                            <div className={`flex flex-col gap-5`}>
                                <p className={`w-1/3 text-black text-sm font-[Roboto-Regular]`}>{`Sub Category:`}</p>
                                <div className={`flex gap-2 flex-wrap`}>
                                {
                                    serviceData?.subCategory?.length > 0 ? serviceData.subCategory?.map((item, idx) => <div key={idx}>
                                            <Chip label={`${item?.name}`} color={'primary'} />
                                    </div>) :
                                            'Data not found'
                                }
                                </div>
                            </div>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServiceDetails