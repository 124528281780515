import React, { useEffect, useState } from 'react'
import Accordionbutton from '../../../Common/UiComponents/Accordionbutton'
import ButtonField from '../../../Common/FormFields/ButtonField'
import moment from 'moment'
import UploadButton from '../../../Common/UiComponents/UploadButton'
import TextFieldInput from '../../../Common/FormFields/TextFieldInput'
import emojisection from '../../../Images/profileDashboardIcons/emojisection.svg'
import attachmentsection from '../../../Images/profileDashboardIcons/attachment.svg'
import ratingicon from '../../../Images/Vendor/ratingicon.svg'
import lastseenicon from '../../../Images/profileDashboardIcons/lastseen.svg'
import sendicon from "../../../Images/profileDashboardIcons/sendicon.svg"
import { useLocation } from 'react-router-dom'
import defaultserviceIcon from '../../../Images/vendors/defaultprofile.svg'
import { useRef } from "react";
import UploadMultipleImage from '../../../Common/Layout/UploadMultipleImage/UploadMultipleImage'
import { ForUploadMediaHooks } from '../../../Containers/UploadMedia/Hooks'
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from 'react-redux'
import { setUploadMultipleImg, setUploadedData, setUploadedLogo } from '../../../Containers/UploadMedia/reducer/UploadMediaReducer'
import pdf from '../../../Images/CommonImages/pdficon.svg'
import download from '../../../Images/CommonImages/uploadicon.svg'
import Modal from '../../../Common/UiComponents/Modal'
import { Dialog, DialogContent } from '@mui/material'
import defaultMedia from '../../../Images/media.svg'

const ChatWindow = (props) => {
    const location = useLocation()
    const dispatch = useDispatch()
    const { multipleMediaApiCall, multipleUpload } = ForUploadMediaHooks()
    const messageEl = useRef(null);
    const [accordionClick, setAccordionClick] = useState(false);

    // useEffect(() => {
    //     if (messageEl) {
    //         messageEl.current?.addEventListener('DOMNodeInserted', event => {
    //             const { currentTarget: target } = event;
    //         console.log(target, "target")
    //             target.scroll({ top: target.scrollHeight, behavior: 'smooth' });
    //         });
    //     }
    // }, [])


    useEffect(() => {
        if (messageEl.current) {
          messageEl.current.scrollTop = messageEl.current.scrollHeight;
        }
      }, [props.chatArr]);



    const removeParticularThumbnail = (idx) => {
        if (idx > -1) {
            let arr;
            arr = [...multipleUpload]
            arr.splice(idx, 1);
            dispatch(setUploadMultipleImg(arr))
        }
    }


    const [imageData, setImageData] = useState('')
    const [imageKeyData, setImageKeyData] = useState('')
    const [openModal, setOpenModal] = useState(false)
    const [userId, setUserId] = useState()

    const ImageClick = (savedId, savedKey, userId) => {
        setImageData(savedId);
        setImageKeyData(savedKey)
        setUserId(userId)
        setOpenModal(!openModal)
    }

    useEffect(() => {
        const chatData = props.chatArr[0]?.participants.filter(participant => participant.id === props?.saveAccData?.userId)
        if (props.chatArr && props.chatArr[0]?.id && chatData && chatData?.[0]?.id) {
            props.getChatbyChatIdApiCAll && props.getChatbyChatIdApiCAll(props.chatArr[0]?.id)
            props.chatMessageReadApi && props.chatMessageReadApi(props.chatArr[0]?.id)
        }
    }, [props.chatArr?.[0]?.unRead])

    return (
        <div className={`relative`}>
            <Accordionbutton
                sx={{position: "absolute"}}
                AccordionSummary={
                    <div className={`flex justify-between px-3 py-1 items-start text-sm font-[Roboto-Regular]`}>
                        <div className={`flex`}>
                            <img crossOrigin='anonymous' src={props.saveAccData && props.saveAccData?.logo?.key ? `${process.env.REACT_APP_DG_BASE_URL}/uploads/download/file?key=${props.saveAccData.logo?.key}&height=50&width=50` : defaultserviceIcon} alt="profileicon" className={`rounded-full`} height={50} width={50} />
                            <div className={`flex flex-col pl-2 pr-5`}>
                                <p className={`text-sm text-skyblue-6 font-[Roboto-Bold]`}>{props.saveAccData?.name}</p>
                                <p className={`text-xs text-[#434343]/90 font-[Roboto-regular]`}>{props.saveAccData?.email}</p>
                                <div className={`flex items-center mr-4`}>
                                    {/* <img src={ratingicon} alt='rating icon' height={15} width={15} />  */}
                                </div>
                            </div>
                        </div>
                        <div className={`flex`}>
                            {/* <img src={lastseenicon} alt="" /> */}
                        </div>
                    </div>
                }
                AccordionDetails={
                    <div className={`mt-5`}>
                        <div className={`flex justify-between`}>
                            <div>
                                <p className={`text-deepgray-5 text-xs font-[Roboto-Medium] pb-1`}>Company Name</p>
                                <p className={`text-deepgrey-3 text-xs font-[Roboto-Regular]`}>{props.saveAccData?.name}</p>
                            </div>
                            <div>
                                <p className={`text-deepgray-5 text-xs font-[Roboto-Medium] pb-1`}>Email</p>
                                <p className={`text-deepgrey-3 text-xs font-[Roboto-Regular]`}>{props.saveAccData?.email}</p>
                            </div>
                            <div>
                                <p className={`text-deepgray-5 text-xs font-[Roboto-Medium] pb-1`}>Phone</p>
                                <p className={`text-deepgrey-3 text-xs font-[Roboto-Regular]`}>{props.saveAccData?.phoneNo}</p>
                            </div>
                        </div>
                        <div className={`py-[5%]`}>
                            <p className={`text-deepgray-5 text-xs font-[Roboto-Medium] pb-1`}>Service Requirement</p>
                            <div className={`flex`}>
                                <p className={`text-deepgrey-3 text-xs font-[Roboto-Regular]`}>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.<span className={`text-black uppercase text-xs cursor-pointer`}>View more</span></p>
                            </div>
                        </div>
                        <div>
                            <ButtonField
                                buttonextracls={`bg-skyblue-6 text-white px-3 py-2`}
                                buttonName={"Ask for review"}
                                buttonnamecls={`pl-2  text-xs font-[Roboto-Medium]`}
                            />
                        </div>
                    </div>
                }
                panelItem={props.saveAccData?.userId}
                setExpanded={setAccordionClick}
                expanded={accordionClick}
            />

            <div className={`flex flex-col w-full px-[6%] py-[4%] overflow-y-auto h-[68vh] border-l-2 border-Grey-100`} ref={messageEl}>
                <div className='flex flex-col justify-between h-full'>
                    <div>
                        {/*Conversation data of a particular chat which is being get by the API call getChatbyChatIdApiCAll(chatId)  */}
                        {props.singleChatDetail?.slice(0)?.reverse()?.map((chatDet, idx) =>
                            <div key={idx}>

                                {/* Receiver's chat  */}
                                {chatDet?.senderId !== parseInt(localStorage.getItem('userId')) &&
                                    <div className={`flex flex-col items-start mb-[2%]`}>
                                        {chatDet?.file?.length < 1 ?
                                            <p className="leftbordercls">
                                                {chatDet?.message}
                                            </p>
                                            :
                                            <div className={``}>
                                                {chatDet?.file && chatDet?.file?.length > 0 && chatDet?.file[0]?.mimetype !== null && chatDet?.file[0]?.mimetype?.includes('application') ?
                                                    <div className={`flex justify-between  py-3 px-4 bg-gray-200 rounded-md`}>
                                                        <div className={`flex w-[12rem]`}>
                                                            <img src={pdf} alt="pdf" height={30} width={30} className='cursor-pointer mr-3' />
                                                            <div className={`flex flex-col`}>
                                                                <p className={`text-xs text-gray-600 font-[Roboto-Regular] truncate w-20`}>{chatDet?.file[0]?.name}</p>
                                                                <p className={`text-xs text-gray-600 font-[Roboto-Regular]`}>{(chatDet?.file[0]?.size / 1024).toFixed(2)}&nbsp;kb</p>
                                                            </div>
                                                        </div>
                                                        <img
                                                            src={download}
                                                            alt="download"
                                                            onClick={() => props.downloadPdfAttachments(chatDet?.file[0])}
                                                            className='cursor-pointer'
                                                        />
                                                    </div>
                                                    :
                                                    chatDet?.file && chatDet?.file?.length > 0 && chatDet?.file[0]?.mimetype !== null && (chatDet?.file[0]?.mimetype?.includes('image')) &&
                                                    <img
                                                        crossOrigin='anonymous'
                                                        src={chatDet?.file[0]?.key ? `${process.env.REACT_APP_DG_BASE_URL}/uploads/download/file?key=${chatDet?.file[0]?.key}&height=300&width=300` : defaultserviceIcon}
                                                        alt="profileicon"
                                                        className={`rounded`}
                                                        height={200}
                                                        width={200}
                                                        // onClick={() => props.downloadImage(chatDet?.file[0]?.key)}
                                                        onClick={() => ImageClick(chatDet?.file[0]?.id, chatDet?.file[0]?.key, chatDet?.senderId)}
                                                    />
                                                }
                                                {chatDet?.message ??
                                                    <p className="leftbordercls">{chatDet?.message}</p>
                                                }
                                            </div >
                                        }
                                        <p className={`font-[Roboto-Regular] text-text-60 text-xs text-left mt-1`}>
                                            {moment(chatDet?.sentAt).startOf('second').fromNow()}
                                        </p>
                                    </div>
                                }

                                {/* Sender's chat  */}
                                {chatDet?.senderId === parseInt(localStorage.getItem('userId')) &&
                                    <div className={`flex flex-col items-end mb-[2%]`}>
                                        {chatDet?.file?.length < 1 ?
                                            <p className={`rightbordercls`}>
                                                {chatDet?.message}
                                            </p>
                                            :
                                            <div className={``}>
                                                {chatDet?.file && chatDet?.file?.length > 0 && chatDet?.file[0]?.mimetype?.includes('application') ?
                                                    <div className={`flex justify-between w-full py-3 px-4 bg-gray-200 rounded-md`}>
                                                        <div className={`flex w-[12rem]`}>
                                                            <img src={pdf} alt="pdf" height={30} width={30} className='cursor-pointer mr-3' />
                                                            <div className={`flex flex-col`}>
                                                                <p className={`text-xs text-gray-600 font-[Roboto-Regular] truncate w-20`}>{chatDet?.file[0]?.name}</p>
                                                                <p className={`text-xs text-gray-600 font-[Roboto-Regular]`}>{(chatDet?.file[0]?.size / 1024).toFixed(2)}&nbsp;kb</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    chatDet?.file && chatDet?.file?.length > 0 && (chatDet?.file[0]?.mimetype?.includes('image')) &&
                                                    <img
                                                        crossOrigin='anonymous'
                                                        src={chatDet?.file[0]?.key ? `${process.env.REACT_APP_DG_BASE_URL}/uploads/download/file?key=${chatDet?.file[0]?.key}&height=300&width=300` : defaultserviceIcon}
                                                        alt="profileicon"
                                                        className={`rounded cursor-pointer`}
                                                        height={200}
                                                        width={200}
                                                        onClick={() => ImageClick(chatDet?.file[0]?.id, chatDet?.file[0]?.key, chatDet?.senderId)}
                                                    />
                                                }
                                                {chatDet?.message ??
                                                    <p className="rightbordercls">{chatDet?.message}</p>
                                                }
                                            </div >
                                        }
                                        <p className={`font-[Roboto-Regular] text-text-60 text-xs text-right mt-1`}>
                                            {moment(chatDet?.sentAt).startOf('second').fromNow()}
                                        </p>
                                    </div>
                                }
                            </div>

                        )}
                    </div>
                </div>

            </div>


            {/* text field section  */}
            <div className={`flex items-center bg-gray-200 mx-5 rounded-full justify-between px-4 textfieldredesign mb-4`}>

                <div className="flex w-[3%] items-center">
                    {/* <img src={emojisection} alt="emojisection" className={`cursor-pointer mr-2`} /> */}
                    <UploadButton
                        extracls='flex justify-center items-center rounded-md '
                        fileUploadCss={`!gap-0`}
                        sendData={(data) => {
                            multipleMediaApiCall(data, false);
                        }}
                        multiple={false}
                        type='button'
                        fileUploadIcon={attachmentsection}
                        height={25}
                        width={25}
                        disabled={(multipleUpload && multipleUpload?.length === 1) ? true : false}
                    />
                </div>

                {/* if file uploaded then uploaded file will be there otherwise textField  */}
                {multipleUpload && multipleUpload?.length > 0 ?
                    <div className='h-[3.2rem]'>
                        <div className={`flex justify-center items-center absolute bottom-3 left-[10%] w-[40%]`}>
                            {multipleUpload?.map((multipleImg, idx) =>
                                <div key={idx} className={`w-[100%] flex items-center border-1 border-[#333] gap-3 px-5 relative cursor-pointer`}>
                                    {multipleImg?.mimetype === 'application/pdf' ? <img src={pdf} alt='pdf' /> :
                                        <img
                                            // crossOrigin="anonymous"
                                            // src={multipleImg?.key && `${process.env.REACT_APP_DG_BASE_URL}/uploads/download/file?key=${multipleImg?.key}&height=250&width=300`}
                                            // alt='thumbnail'
                                            // className={`mt-2`}
                                            src={defaultMedia} alt='image_icon'
                                            className='w-[20px] h-[20px]'
                                        />
                                    }
                                    <div className={`flex gap-2`}>
                                        <p className={` w-[90%] truncate font-[Roboto-Regular]`}>{multipleImg?.name}</p>
                                        <p className='text-[#777]'>{(multipleImg?.size / 1024).toFixed(2)}&nbsp;kb</p>
                                    </div>
                                    <CloseIcon
                                        onClick={() => removeParticularThumbnail(idx)}
                                        sx={{ fontSize: "18px", border: '1px solid red', borderRadius: '9999px', cursor: 'pointer' }}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                    :
                    <div className={`w-[88%]`}>
                        <TextFieldInput
                            onlyValue
                            textnewclass={`w-full text-sm  textFieldHeight`}
                            placeholder='Type a message'
                            onChange={props.handleInput}
                            value={props.inputValue}
                            onKeyPress={() => {
                                if (props.inputValue !== '' || multipleUpload?.length === 1) {
                                    props.handleInputSubmit(props.saveAccData?.userId)
                                }
                            }}
                            multiline={true}
                            maxRows={3}
                        />
                    </div>}
                <div className={`w-[6%]`}>
                    <ButtonField
                        buttonextracls={`hover:bg-transparent`}
                        img={sendicon}
                        type='submit'
                        onClick={() => {
                            if (props.inputValue !== '' || multipleUpload?.length === 1) {
                                props.handleInputSubmit(props.saveAccData?.userId)
                            }
                        }}
                        loading={props.loading}
                        disabled={props.loading === true && true}
                    />
                </div>

            </div>


            {/* </form> */}

            <Dialog
                open={openModal}
            >
                <DialogContent className={`px-5 py-3 text-sm w-[30rem] h-[25rem]`}>
                    <>
                        <div className='flex justify-end mb-5'>
                            {userId !== parseInt(localStorage.getItem('userId')) &&
                                <img
                                    src={download}
                                    alt="download"
                                    onClick={() => props.downloadImage(imageKeyData)}
                                    className='cursor-pointer pr-4'
                                />
                            }
                            <CloseIcon
                                onClick={() => setOpenModal(false)}
                                sx={{ fontSize: "25px", cursor: 'pointer' }}
                            />
                        </div>
                        <img
                            crossOrigin='anonymous'
                            src={imageKeyData && `${process.env.REACT_APP_DG_BASE_URL}/uploads/download/file?key=${imageKeyData}&height=200&width=auto`}
                            alt="profileicon"
                            className={`rounded`}
                        // height={300}
                        // width={300}
                        />
                    </>
                </DialogContent>

            </Dialog>

        </div >
    )
}

export default ChatWindow
