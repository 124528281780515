import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import text from '../Languages/en_US.json';

const DeclinePopup = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [declineReason, setDeclineReason] = useState("");

  const handleReasonChange = (event) => {
    setDeclineReason(event.target.value);
  };

  return (
    <div className={``}>
      <Dialog
        fullScreen={fullScreen}
        open={props.open}
        userID={props.userID}
        onClose={props?.handleClose}
        aria-labelledby="responsive-dialog-title"
        className={`!max-w-full !min-w-max h-auto m-0 ${"dilogBox_main"} ${props.dialogboxMainCls}`}
        onClick={props.handleClickOpen}
      >
        <DialogTitle
          id="responsive-dialog-title"
          className={`flex justify-between items-center border-b border-slate-200 px-3 py-3 text-red-500 ${props.dialogTitlecls}`}
        >
          {props.existMediaData?.length > 0 || props.title ? props.title : "User Decline"}
          <CloseIcon
            onClick={props?.handleClose}
            className={`cursor-pointer text-slate-500`}
          />
        </DialogTitle>
        <DialogContent className={`px-3 py-3 w-[30rem] ${props.dialogContentCls}`}>
          {/* Multiple delete warning modal */}
          {props.existMediaData && props.existMediaData?.length > 0 ? (
            <div className={`pb-4`}>
              <DialogContentText className={`mb-2`}>
                {props.existMediaData?.length} {text.deleteMediaTxt}
              </DialogContentText>
              <ul className={`overflow-y-auto max-h-[160px]`}>
                {props.existMediaData &&
                  props.existMediaData.map((item) => (
                    <li className={`text-xs leading-[0] mb-2 text-[#8d8d8d] flex items-center`} key={item.mediaFile?.key}>
                      <span className={`mr-2`}>
                        <img
                          className={`rounded-[4px]`}
                          height={30}
                          width={30}
                          alt="img"
                        />
                      </span>
                      <p className={`truncate w-[30em] leading-[14px]`}>{item?.mediaName}</p>
                    </li>
                  ))}
              </ul>
            </div>
          ) : (
            <DialogContentText className={`text-red-500 ${props.DilogContentTextCls}`}>
              {props.DilogContentText}
            </DialogContentText>
          )}
          {props.DialogContent}

          {/* Text Area for Decline Reason */}
         {props?.needDeclineReason && <TextField
            label="Reason for Declining"
            multiline
            rows={4}
            variant="outlined"
            fullWidth
            value={declineReason}
            onChange={handleReasonChange}
            className={`mt-4 ${props.textAreaCls}`}
          />}
        </DialogContent>
        <DialogActions className={`px-3 py-3`}>
          <Button
            autoFocus
            onClick={props.handleCancelButton ? props.handleCancelButton : props.handleClose}
            className={`capitalize font-normal px-3 py-1.5 ${props.cancelTextCls}`}
          >
            {props.notCancelText ? props.notCancelText : `Cancel`}
          </Button>
          <Button
            onClick={() => props.onClick(declineReason)} // Pass the reason to the parent component
            autoFocus
            className={`capitalize font-normal border-solid border px-3 py-1.5 border-wv-buttonred text-white bg-red-500 rounded ${props.submitBtnCls}`}
          >
            {props.loading ? (
              <CircularProgress className={`h-5 text-white w-5 `} />
            ) : (
              <p>{props.DeleteBtn}</p>
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeclinePopup;
