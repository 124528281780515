import React, { useEffect } from 'react'
import PayElectricityBillimg from "../../Images/Vendor/pay_electricity_bill.svg"
import TrafficViolation from "../../Images/Vendor/TrafficViolation.svg"
import CivilIDRenewal from "../../Images/Vendor/CivilIDRenewal.svg"
import TravelBan from "../../Images/Vendor/TravelBan.svg"
import LandPhoneBill from "../../Images/Vendor/LandPhoneBill.svg"
import text from "../../Common/Languages/en_US.json"
import { useSelector } from 'react-redux'
import { ForAdmin } from '../../Containers/Admin/Hooks'
import HyperlinkIcon from '../../Images/Setting/HyperlinkIcon.svg'
import './LandingPage.css'
import { useTranslation } from 'react-i18next'
import { Tooltip } from '@mui/material'

const RechargeandPaybills = (props) => {
    const { t, i18n } = useTranslation()
    const allLinks = useSelector((state) => state.setting.hyperlinks)

    const goToLink = (myLink) => {
        window.open(myLink, '_blank', 'noreferrer')
    }

    const rechargedetails = [
        {
            image: PayElectricityBillimg,
            name: "PayElectricityBillimg",
            text: "Pay Electricity Bill"
        },
        {
            image: TrafficViolation,
            name: "TrafficViolation",
            text: "Traffic Violation"
        },
        {
            image: CivilIDRenewal,
            name: "CivilIDRenewal",
            text: "Civil ID Renewal"
        },
        {
            image: TravelBan,
            name: "TravelBan",
            text: "Travel Ban"
        },
        {
            image: LandPhoneBill,
            name: "LandPhoneBill",
            text: "Land Phone Bill"
        },
    ]

    return (
        <div className={`p-[4%]`}>
            <div className={`flex  flex-row justify-between items-center pb-[3%]`}>
                <p className={`text-left text-3xl font-[Roboto-Regular] font-normal text-deepgray-5 pb-2 lg:pb-0`}>{t("rechargeandpaybills")}</p>
                {allLinks && allLinks?.length > 5 && <p className={`text-left text-base font-[Roboto-Regular] font-normal text-blue-5 cursor-pointer`} onClick={props.handleClickForAllHyperlink}>{text.viewall}</p>}
            </div>

            {allLinks && allLinks?.length > 0 ?
                <div className={` p-[2%] items-center md:flex-row justify-center bg-white-5  grid gap-1 md:gap-3 grid-cols-3 md:grid-cols-4 lg:grid-cols-6 grid-rows-1`}>
                    {allLinks && allLinks?.slice(0, 6).map((item, idx) =>
                    <Tooltip key={idx} title={item?.description}>
                        <div  className={`flex flex-col items-center justify-center px-[2px] py-[12%] border-2 rounded-md drop-shadow-sm border-gray-0.5 cursor-pointer bg-white`} onClick={() => goToLink(item?.url)}>
                            <img
                                src={item?.picture?.key ? `${process.env.REACT_APP_DG_BASE_URL}/uploads/download/file?key=${item?.picture?.key}&height=20&width=auto` : HyperlinkIcon}
                                alt={item.name}
                                className={`h-36 md:h-20 w-36 md:w-20 rounded-full`}
                                crossOrigin='anonymous'
                            />
                            
                                <p className={`font-[Roboto-Regular] font-normal text-deepgray-5 text-2xl md:text-[17px] pt-[10%] md:pt-[18%] w-[70%] truncate text-center`}>{item?.name}</p>
                           
                        </div>
                        </Tooltip>
                    )}
                </div>
                :
                <div className={`flex flex-col md:flex-row justify-center md:justify-evenly flex-wrap mt-[3%]`}>No Data Found</div>
            }
        </div>
    )
}

export default RechargeandPaybills