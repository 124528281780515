import React, { useState } from 'react'
import ButtonField from '../../../Common/FormFields/ButtonField'
import text from '../../../Common/Languages/en_US.json'
import PropTypes from 'prop-types';
import buttonArrow from '../../../Images/adminContent/buttonArrow.svg'
import DropDownField from '../../../Common/FormFields/DropDownField';
import eventIcon from '../../../Images/adminContent/eventIcon.svg'
import ReactDateRange from '../../../Common/FormFields/DateRangePicker';
import { Controller, useForm } from 'react-hook-form';
import { InputAdornment } from '@mui/material';
import DeclinePopup from '../../../Common/FormFields/DeclinePopup';
import TextFieldInput from '../../../Common/FormFields/TextFieldInput';
import search from '../../../Images/profileDashboardIcons/searchicon.svg'
import moment from 'moment';
import { governorateArray } from '../../../Utils/governorateArray'

const TotalUsersHeader = (props) => {
    const [dropdownName, setDropdownName] = useState('')
    const [open, setOpen] = useState(false)
    const [searchGovernorate, setSearchGovernorate] = useState()
    const [searchplaces, setSearchPlaces] = useState();
    const [govOrPlace, setGovOrPlace] = useState()

    const governorateArr = governorateArray.map(data => {
        return { name: data.name, value: data.value }
    })

    const placesArray = governorateArray.flatMap(governorate => governorate.location);


    const handleClickForSearch = (filterVal) => {
        setOpen(true)
        setGovOrPlace(filterVal)
    }
    const handleCloseForSearch = (userId) => {
        setOpen(false)
        setDropdownName('')
    }

    const { handleSubmit, reset, control } = useForm()

    const onSubmit = (data) => {
        props.setGovFieldData(data)
        govOrPlace === 'governorate' ? props.getTotalVendorsInAdmin('governorate', searchGovernorate) : props.getTotalVendorsInAdmin('places', searchplaces)
        handleCloseForSearch()
        setOpen(false)
    }

    const selectArrVendor = [
        { name: "Name A-Z", value: "name-az" },
        { name: "Name Z-A", value: "name-za" },
        { name: "New-Old", value: "new-old" },
        { name: "Old-New", value: "old-new" },
        { name: "Governorate", value: "governorate" },
        { name: "Place", value: "places" }
    ]

    const selectArrUser = [
        { name: "Name A-Z", value: "name-az" },
        { name: "Name Z-A", value: "name-za" },
        { name: "New-Old", value: "new-old" },
        { name: "Old-New", value: "old-new" }
    ]
    const [dateOpen, setDateOpen] = useState(false)
    const [date, setDate] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection'
        }
    ]);

    const handleOpenDate = () => {
        setDateOpen(!dateOpen)
    }

    // const onChange = (e) => {
    //     e.preventDefault();
    //     setSearchInput(e.target.value.toLowerCase());
    // };

    return (
        <div className={`flex justify-between items-center px-[2%] py-[1.4%] border-b border-slate-200 bg-white drop-shadow `}>
            <p className={`text-deepgray-4 font-[Roboto-Medium] text-xl ${props.nameCls}`}>{props.userName}</p>
            <div className={``}>
                {props.search === true &&
                    <TextFieldInput
                        onlyValue
                        textnewclass={`flex-1 w-full text-sm tableSearchBar`}
                        placeholder={`Search`}
                        id="outlined-basic"
                        variant="outlined"
                        startAdornment={
                            <InputAdornment position={"start"}>
                                <img src={search} alt='search' />
                            </InputAdornment>}
                        onChange={props.onChange}
                    />
                }
            </div>
            <div className={`flex items-center justify-between gap-6 `}>
                {props.csvBtn ?
                    <ButtonField
                        buttonName={text.downloadExcel}
                        startIcon={<img src={buttonArrow} alt='img' className='w-[14px] h-[17px]' />}
                        buttonextracls={` !px-6 !py-2 !text-white bg-skyblue-6`}
                        onClick={() => props.downloadExcelReport(props.userName === "Total Users" ? 'user' : 'vendor')}
                    /> : null}
                {props.dropDown ?
                    <DropDownField
                        selectOption={props.forComponent === 'totalUser' ? selectArrUser : selectArrVendor}
                        dropDownRootCls={`h-10 pl-[25px] text-sm text-deepgrey-3 font-[Roboto-Medium]`}
                        filterImg={true}
                        handleChange={(e) => {
                            e.target.value === 'places' || e.target.value === 'governorate' ?
                                (e.target.value === 'places' || e.target.value === 'governorate') && handleClickForSearch(e.target.value) :
                                props.forComponent === 'totalUser' ? props.getAllUsers(e.target.value) : props.getTotalVendorsInAdmin(e.target.value)
                            setDropdownName(e.target.value);
                        }}
                        placeholder="Filter"
                        // name={selectArr}
                        option={dropdownName}
                    /> : null}

                {props.addBtn ?
                    <ButtonField
                        buttonName={text.addNew}
                        buttonextracls={`${props.btnColor ? `!px-4 !py-2 !text-white bg-skyblue-6 text-sm hover:bg-deepBlue-2 hover:drop-shadow-xl` : `!px-4 !py-2 !text-white bg-seconderyColour-5 text-sm hover:bg-deepBlue-2 hover:drop-shadow-xl`}`}
                        onClick={props.toggleSubAdmin}
                    /> : null}

                {props.showDateTimePicker &&
                    <div className={`flex justify-between items-center border border-[#DEDEDE] w-[15rem] px-3 py-2 rounded-md cursor-pointer relative bg-white h-12`} onClick={() => props.setDateOpenViews(!props.dateOpenViews)}>
                        <div className='flex items-center text-[#7B7B7B] gap-1'>
                            <img src={eventIcon} alt='event icon' />
                            <p className='text-[13px] font-[Roboto-Regular] text-gray-6'>{props.dateForViews[0]?.startDate !== null ? moment(props.dateForViews[0]?.startDate).format('DD-MM-YYYY') : text.startDate}</p>
                        </div>
                        <div className=' text-deepgray-4 font-[Roboto-Regular] text-[14px]'>{text.to}</div>
                        <div className='flex items-center basis-3/7 text-[#7B7B7B] gap-1'>
                            <img src={eventIcon} alt='event icon' />
                            <p className='text-[13px] font-[Roboto-Regular] text-gray-6'>{props.dateForViews[0]?.endDate !== null ? moment(props.dateForViews[0]?.endDate).format('DD-MM-YYYY') : text.endDate}</p>
                        </div>
                        {props.dateOpenViews === true ?
                            (<ReactDateRange
                                onChange={(item) => props.setDateForViews([item.selection])}
                                showSelectionPreview={true}
                                moveRangeOnFirstSelection={false}
                                ranges={props.dateForViews}
                                months={1}
                                direction="horizontal"
                                customStyles={{ position: 'absolute', top: '40px', right: '0px', zIndex: '10', }}
                            />)
                            : null
                        }
                    </div>
                }
                {open &&
                    <form onSubmit={handleSubmit(onSubmit, reset)}>
                        <DeclinePopup
                            open={open}
                            handleClose={handleCloseForSearch}
                            title={'Filter'}
                            onClick={handleSubmit(onSubmit)}
                            DialogContent={
                                <div className={` my-3  flex flex-col`}>
                                    {dropdownName === 'governorate' ?
                                        <Controller
                                            name={"governorate"}
                                            control={control}
                                            rules={{
                                                required: true,
                                            }}
                                            render={({ field: { onChange, value } }) => (
                                                <DropDownField
                                                    name='governorate'
                                                    id='governorate'
                                                    dropDownRootCls={`governorateCls`}
                                                    size="small"
                                                    selectOption={governorateArr}
                                                    placeholder={`Filter by Governorate`}
                                                    option={value ? value : searchGovernorate}
                                                    handleChange={(e) => { setSearchGovernorate(e.target.value); onChange(e.target.value) }}
                                                />
                                            )}
                                        />
                                        :
                                        dropdownName === 'places' &&
                                        <Controller
                                            name={"places"}
                                            control={control}
                                            rules={{
                                                required: true,
                                            }}
                                            render={({ field: { onChange, value } }) => (
                                                <DropDownField
                                                    name='governorate'
                                                    id='governorate'
                                                    dropDownRootCls={`governorateCls`}
                                                    size="small"
                                                    selectOption={placesArray}
                                                    placeholder={`Filter by places`}
                                                    option={value ? value : searchplaces}
                                                    handleChange={(e) => { setSearchPlaces(e.target.value); onChange(e.target.value) }}
                                                />
                                            )}
                                        />
                                    }

                                </div>
                            }
                            DeleteBtn={"Filter"}
                        />
                    </form>
                }
            </div>
        </div>
    )
}

TotalUsersHeader.propTypes = {
    userName: PropTypes.string,
    selectOption: PropTypes.array,
    dropDownRootCls: PropTypes.string,
    filterImg: PropTypes.bool,
    placeholder: PropTypes.string,
    buttonName: PropTypes.string,
    buttonextracls: PropTypes.string,
    startIcon: PropTypes.node,
    csvBtn: PropTypes.bool,
    showDateTimePicker: PropTypes.bool,
    nameCls: PropTypes.string,
}

export default TotalUsersHeader