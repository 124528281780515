import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger'
import settingReducer from '../Containers/Admin/reducer/SettingReducer';
import TotalUsersReducer from '../Containers/Admin/reducer/TotalUsersReducer';
import mediaUploadReducer from '../Containers/UploadMedia/reducer/UploadMediaReducer'
import VendorReducer from '../Containers/VendorDetailPage/reducer/VendorReducer';
import TotalVendorsInAdminReducer from '../Containers/Admin/reducer/TotalVendorsReducer';
import CategoryReducer from '../Containers/Admin/reducer/CategoryReducer';
import LandingReducer from '../Containers/LandingPage/LandingReducer'
import VendorAccountReducer from '../Containers/Authentication/Signup/reducer/VendorAccountReducer';
import notificationReducer from '../Containers/Admin/reducer/NotificationReducer';
import UsersReducer from '../Containers/User/reducer/UserReducer';
import NotificationReducer from '../Containers/Notification/NotificationReducer';
import DashboardReducer from '../Containers/Admin/reducer/DashboardReducer';
// import TotalUserDetailsReducer from '../Containers/Admin/reducer/TotalUserDetailsReducer';
import LoggedInAcc from '../Containers/Authentication/Login/reducer/LoginReducer'
import CMSReducer from '../Containers/Admin/reducer/CMSReducer';
import SubscriptionPlanReducer from '../Containers/Admin/reducer/SubscriptionPlanReducer';
import getSubscriptionDetails from '../Containers/Authentication/Login/reducer/subscriptionReducer';
import SubscriptionList from '../Containers/Admin/reducer/SubscriptionList';
import paymentHistory from "../Containers/PaymentHistory/PaymentHistoryReducer";
import TransactionReducer from '../Containers/Admin/reducer/TransactionReducer';

export default configureStore({
  reducer: {
    setting: settingReducer,
    vendor: VendorReducer,
    totalUsers: TotalUsersReducer,
    totalVendorsInAdmin: TotalVendorsInAdminReducer,
    upload: mediaUploadReducer,
    totalCategoryInAdmin: CategoryReducer,
    landingData: LandingReducer,
    vendorAccount: VendorAccountReducer,
    notificationData: NotificationReducer,
    notification: notificationReducer,
    // selectedUserDetails: TotalUsersReducer
    users: UsersReducer,
    dashboard: DashboardReducer,
    loggedIn: LoggedInAcc,
    cmsPage: CMSReducer,
    subscription: SubscriptionPlanReducer,
    subscriptionDetails: getSubscriptionDetails,
    subscriptionList: SubscriptionList,
    paymentHistory: paymentHistory,
    transactionsData:TransactionReducer 
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
});