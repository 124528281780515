import { InputAdornment, TextField } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next';

const InputField = (props) => {
  // const emailStartSvg = React.useState(EmailStartIcon);

  /**
   * clickEnter for enter button
   * @param e
   */
  const clickEnter = (e) => {
    if (e.key === "Enter" && !props.multiline) {
      e.preventDefault();
      // Do code here
      props.clickEnter();
    }
  };

  const { t } = useTranslation()

  const onChange = (event) => {
    props.onChange(event);
  };

  const floatingLabel = [
    <span className={(props.floatingError ? 'errorLabelCls' : '') + `${' flotinglabelclss'}`}>
      {t(props.floatingLabel)}
      {props.optionaltxt && <span className='optionaltxt'>{t('OptionalTxt')}</span>}
    </span>
  ]

  return (
    <div className={` flex flex-col ${props.extracls}`}>
      <label className={`${props.lableCls}`}>{props.label}</label>
      <TextField
        autoFocus={props.autoFocus}
        onChange={onChange}
        disabled={props.disabled}
        id={props.id}
        variant={props.variant}
        // label={props.inputLabel}
        defaultValue={props.defaultValue}
        className={`${props.textfieldclass} ${props.textnewclass ? props.textnewclass : ""
          }`}
        type={props.typeNumber ? 'number' : props.typePassword ? 'password' : props.typeText ? 'text' : props.type}
        autoComplete="off"
        name={props.textinputname}
        multiline={props.multiline}
        rows={props.rows}
        fullWidth={props.fullwidthState}
        placeholder={t(props.placeholder)}
        error={props.error}
        value={props.value}
        onKeyPress={clickEnter}
        required={props.required}
        helperText={props.helperText}
        data-cy={props.dataCy}
        label={props.floatingLabel ? floatingLabel : ''}
        focused={props.focused}
        InputProps={
          props.startAdornment === true ? {
            startAdornment:
              <InputAdornment position='start'>
                {props.inputStartAdornment}
              </InputAdornment>
          }
            :
            props.endAdornment === true ? {
              endAdornment: <InputAdornment position='end'>
                {props.inputEndAdornment}
              </InputAdornment>
            }
              : null
        }
      />
    </div>

  )
}

export default InputField